var vtkColorMaps = [
	{
		Name: "KAAMS",
		IndexedColors: [
			1,
			1,
			1,
			1,
			0,
			0,
			0,
			1,
			0,
			0,
			0,
			1,
			1,
			1,
			0,
			1,
			0,
			1,
			0,
			1,
			1,
			0.63,
			0.63,
			1,
			0.67,
			0.5,
			0.33,
			1,
			0.5,
			0.75,
			0.53,
			0.35,
			0.7,
			1,
			0.75,
			0.5
		],
		Annotations: [
			0,
			0,
			1,
			1,
			2,
			2,
			3,
			3,
			4,
			4,
			5,
			5,
			6,
			6,
			7,
			7,
			8,
			8,
			9,
			9,
			10,
			10,
			11,
			11
		]
	},
	{
		ColorSpace: "Diverging",
		Name: "Cool to Warm",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0.23137254902,
			0.298039215686,
			0.752941176471,
			0.5,
			0.865,
			0.865,
			0.865,
			1,
			0.705882352941,
			0.0156862745098,
			0.149019607843
		]
	},
	{
		ColorSpace: "Lab",
		Creator: "Francesca Samsel",
		Name: "Cool to Warm (Extended)",
		NanColor: [
			0.25,
			0,
			0
		],
		RGBPoints: [
			0,
			0,
			0,
			0.34902,
			0.03125,
			0.039216,
			0.062745,
			0.380392,
			0.0625,
			0.062745,
			0.117647,
			0.411765,
			0.09375,
			0.090196,
			0.184314,
			0.45098,
			0.125,
			0.12549,
			0.262745,
			0.501961,
			0.15625,
			0.160784,
			0.337255,
			0.541176,
			0.1875,
			0.2,
			0.396078,
			0.568627,
			0.21875,
			0.239216,
			0.454902,
			0.6,
			0.25,
			0.286275,
			0.521569,
			0.65098,
			0.28125,
			0.337255,
			0.592157,
			0.701961,
			0.3125,
			0.388235,
			0.654902,
			0.74902,
			0.34375,
			0.466667,
			0.737255,
			0.819608,
			0.375,
			0.572549,
			0.819608,
			0.878431,
			0.40625,
			0.654902,
			0.866667,
			0.909804,
			0.4375,
			0.752941,
			0.917647,
			0.941176,
			0.46875,
			0.823529,
			0.956863,
			0.968627,
			0.5,
			0.988235,
			0.960784,
			0.901961,
			0.5,
			0.941176,
			0.984314,
			0.988235,
			0.52,
			0.988235,
			0.945098,
			0.85098,
			0.54,
			0.980392,
			0.898039,
			0.784314,
			0.5625,
			0.968627,
			0.835294,
			0.698039,
			0.59375,
			0.94902,
			0.733333,
			0.588235,
			0.625,
			0.929412,
			0.65098,
			0.509804,
			0.65625,
			0.909804,
			0.564706,
			0.435294,
			0.6875,
			0.878431,
			0.458824,
			0.352941,
			0.71875,
			0.839216,
			0.388235,
			0.286275,
			0.75,
			0.760784,
			0.294118,
			0.211765,
			0.78125,
			0.701961,
			0.211765,
			0.168627,
			0.8125,
			0.65098,
			0.156863,
			0.129412,
			0.84375,
			0.6,
			0.094118,
			0.094118,
			0.875,
			0.54902,
			0.066667,
			0.098039,
			0.90625,
			0.501961,
			0.05098,
			0.12549,
			0.9375,
			0.45098,
			0.054902,
			0.172549,
			0.96875,
			0.4,
			0.054902,
			0.192157,
			1,
			0.34902,
			0.070588,
			0.211765
		]
	},
	{
		ColorSpace: "Diverging",
		Name: "Warm to Cool",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0.705882352941,
			0.0156862745098,
			0.149019607843,
			0.5,
			0.865,
			0.865,
			0.865,
			1,
			0.23137254902,
			0.298039215686,
			0.752941176471
		]
	},
	{
		ColorSpace: "Lab",
		Creator: "Francesca Samsel",
		Name: "Warm to Cool (Extended)",
		NanColor: [
			0.250004,
			0,
			0
		],
		RGBPoints: [
			0,
			0.34902,
			0,
			0.129412,
			0.025,
			0.4,
			0.00392157,
			0.101961,
			0.05,
			0.470588,
			0.0156863,
			0.0901961,
			0.075,
			0.54902,
			0.027451,
			0.0705882,
			0.1,
			0.619608,
			0.0627451,
			0.0431373,
			0.125,
			0.690196,
			0.12549,
			0.0627451,
			0.15,
			0.741176,
			0.184314,
			0.0745098,
			0.175,
			0.788235,
			0.266667,
			0.0941176,
			0.2,
			0.811765,
			0.345098,
			0.113725,
			0.225,
			0.831373,
			0.411765,
			0.133333,
			0.25,
			0.85098,
			0.47451,
			0.145098,
			0.275,
			0.870588,
			0.54902,
			0.156863,
			0.3,
			0.878431,
			0.619608,
			0.168627,
			0.325,
			0.890196,
			0.658824,
			0.196078,
			0.35,
			0.909804,
			0.717647,
			0.235294,
			0.375,
			0.929412,
			0.776471,
			0.278431,
			0.395522,
			0.94902,
			0.823529,
			0.321569,
			0.418905,
			0.968627,
			0.87451,
			0.407843,
			0.444278,
			0.980392,
			0.917647,
			0.509804,
			0.470149,
			0.988235,
			0.956863,
			0.643137,
			0.483582,
			0.992157,
			0.964706,
			0.713725,
			0.499,
			0.988235,
			0.980392,
			0.870588,
			0.5,
			1,
			1,
			1,
			0.501,
			0.913725,
			0.988235,
			0.937255,
			0.516418,
			0.827451,
			0.980392,
			0.886275,
			0.531343,
			0.764706,
			0.980392,
			0.866667,
			0.546766,
			0.658824,
			0.980392,
			0.843137,
			0.564179,
			0.572549,
			0.964706,
			0.835294,
			0.587562,
			0.423529,
			0.941176,
			0.87451,
			0.60597,
			0.262745,
			0.901961,
			0.862745,
			0.629851,
			0.0705882,
			0.854902,
			0.870588,
			0.651741,
			0.0509804,
			0.8,
			0.85098,
			0.681592,
			0.0235294,
			0.709804,
			0.831373,
			0.712935,
			0.0313725,
			0.615686,
			0.811765,
			0.75,
			0.0313725,
			0.537255,
			0.788235,
			0.775,
			0.0392157,
			0.466667,
			0.768627,
			0.8,
			0.0509804,
			0.396078,
			0.741176,
			0.825,
			0.054902,
			0.317647,
			0.709804,
			0.85,
			0.054902,
			0.243137,
			0.678431,
			0.875,
			0.0431373,
			0.164706,
			0.639216,
			0.9,
			0.0313725,
			0.0980392,
			0.6,
			0.925,
			0.0392157,
			0.0392157,
			0.560784,
			0.95,
			0.105882,
			0.0509804,
			0.509804,
			0.975,
			0.113725,
			0.0235294,
			0.45098,
			1,
			0.12549,
			0,
			0.380392
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Rainbow Desaturated",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0.278431372549,
			0.278431372549,
			0.858823529412,
			0.143,
			0,
			0,
			0.360784313725,
			0.285,
			0,
			1,
			1,
			0.429,
			0,
			0.501960784314,
			0,
			0.571,
			1,
			1,
			0,
			0.714,
			1,
			0.380392156863,
			0,
			0.857,
			0.419607843137,
			0,
			0,
			1,
			0.878431372549,
			0.301960784314,
			0.301960784314
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Cold and Hot",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0,
			1,
			1,
			0.45,
			0,
			0,
			1,
			0.5,
			0,
			0,
			0.501960784314,
			0.55,
			1,
			0,
			0,
			1,
			1,
			1,
			0
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Black-Body Radiation",
		NanColor: [
			0,
			0.498039215686,
			1
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			0.4,
			0.901960784314,
			0,
			0,
			0.8,
			0.901960784314,
			0.901960784314,
			0,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "X Ray",
		NanColor: [
			1,
			0,
			0
		],
		RGBPoints: [
			0,
			1,
			1,
			1,
			1,
			0,
			0,
			0
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Grayscale",
		NanColor: [
			1,
			0,
			0
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "BkRd",
		NanColor: [
			0,
			1,
			1
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			1,
			1,
			0,
			0
		]
	},
	{
		ColorSpace: "RGB",
		Name: "BkGn",
		NanColor: [
			1,
			0,
			1
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			1,
			0,
			1,
			0
		]
	},
	{
		ColorSpace: "RGB",
		Name: "BkBu",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			1,
			0,
			0,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "BkMa",
		NanColor: [
			0,
			1,
			0
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			1,
			1,
			0,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "BkCy",
		NanColor: [
			0,
			1,
			1
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			1,
			0,
			1,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Black, Blue and White",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			0.333,
			0,
			0,
			0.501960784314,
			0.666,
			0,
			0.501960784314,
			1,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Black, Orange and White",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			0.333,
			0.501960784314,
			0,
			0,
			0.666,
			1,
			0.501960784314,
			0,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Creator: "Francesca Samsel",
		Name: "Linear YGB 1211g",
		NanColor: [
			0.25,
			0,
			0
		],
		RGBPoints: [
			0,
			1,
			0.988235,
			0.968627,
			0.02,
			1,
			0.952941,
			0.878431,
			0.05,
			0.968627,
			0.905882,
			0.776471,
			0.1,
			0.94902,
			0.898039,
			0.647059,
			0.15,
			0.901961,
			0.878431,
			0.556863,
			0.2,
			0.847059,
			0.858824,
			0.482353,
			0.25,
			0.690196,
			0.819608,
			0.435294,
			0.3,
			0.513725,
			0.768627,
			0.384314,
			0.35,
			0.337255,
			0.721569,
			0.337255,
			0.4,
			0.278431,
			0.658824,
			0.392157,
			0.45,
			0.231373,
			0.639216,
			0.435294,
			0.5,
			0.203922,
			0.6,
			0.486275,
			0.55,
			0.172549,
			0.568627,
			0.537255,
			0.6,
			0.141176,
			0.517647,
			0.54902,
			0.65,
			0.133333,
			0.458824,
			0.541176,
			0.7,
			0.12549,
			0.396078,
			0.529412,
			0.75,
			0.117647,
			0.321569,
			0.521569,
			0.8,
			0.121569,
			0.258824,
			0.509804,
			0.85,
			0.133333,
			0.227451,
			0.501961,
			0.9,
			0.145098,
			0.192157,
			0.490196,
			0.95,
			0.188235,
			0.164706,
			0.470588,
			1,
			0.258824,
			0.196078,
			0.439216
		]
	},
	{
		ColorSpace: "CIELAB",
		Creator: "Francesca Samsel",
		Name: "Linear Green (Gr4L)",
		NanColor: [
			0.25,
			0,
			0
		],
		RGBPoints: [
			0,
			0.054902,
			0.109804,
			0.121569,
			0.05,
			0.07451,
			0.172549,
			0.180392,
			0.1,
			0.086275,
			0.231373,
			0.219608,
			0.15,
			0.094118,
			0.278431,
			0.25098,
			0.2,
			0.109804,
			0.34902,
			0.278431,
			0.25,
			0.113725,
			0.4,
			0.278431,
			0.3,
			0.117647,
			0.45098,
			0.270588,
			0.35,
			0.117647,
			0.490196,
			0.243137,
			0.4,
			0.113725,
			0.521569,
			0.203922,
			0.45,
			0.109804,
			0.54902,
			0.152941,
			0.5,
			0.082353,
			0.588235,
			0.082353,
			0.55,
			0.109804,
			0.631373,
			0.05098,
			0.6,
			0.211765,
			0.678431,
			0.082353,
			0.65,
			0.317647,
			0.721569,
			0.113725,
			0.7,
			0.431373,
			0.760784,
			0.160784,
			0.75,
			0.556863,
			0.8,
			0.239216,
			0.8,
			0.666667,
			0.839216,
			0.294118,
			0.85,
			0.784314,
			0.878431,
			0.396078,
			0.9,
			0.886275,
			0.921569,
			0.533333,
			0.95,
			0.960784,
			0.94902,
			0.670588,
			1,
			1,
			0.984314,
			0.901961
		]
	},
	{
		ColorSpace: "Lab",
		Creator: "Francesca Samsel",
		Name: "Linear Blue (8_31f)",
		NanColor: [
			0.25,
			0,
			0
		],
		RGBPoints: [
			0,
			0.960784,
			1,
			0.980392,
			0.05,
			0.815686,
			0.960784,
			0.913725,
			0.1,
			0.670588,
			0.929412,
			0.870588,
			0.15,
			0.556863,
			0.901961,
			0.843137,
			0.2,
			0.478431,
			0.870588,
			0.823529,
			0.25,
			0.439216,
			0.831373,
			0.803922,
			0.3,
			0.4,
			0.8,
			0.788235,
			0.35,
			0.376471,
			0.768627,
			0.768627,
			0.4,
			0.34902,
			0.709804,
			0.729412,
			0.45,
			0.32549,
			0.654902,
			0.690196,
			0.5,
			0.301961,
			0.607843,
			0.658824,
			0.55,
			0.247059,
			0.545098,
			0.619608,
			0.6,
			0.239216,
			0.494118,
			0.580392,
			0.65,
			0.227451,
			0.439216,
			0.541176,
			0.7,
			0.227451,
			0.403922,
			0.521569,
			0.75,
			0.231373,
			0.368627,
			0.501961,
			0.8,
			0.227451,
			0.321569,
			0.470588,
			0.85,
			0.219608,
			0.282353,
			0.439216,
			0.9,
			0.192157,
			0.235294,
			0.4,
			0.95,
			0.160784,
			0.184314,
			0.34902,
			1,
			0.133333,
			0.12549,
			0.301961
		]
	},
	{
		ColorSpace: "HSV",
		Name: "Blue to Red Rainbow",
		NanColor: [
			0.498039215686,
			0.498039215686,
			0.498039215686
		],
		RGBPoints: [
			0,
			0,
			0,
			1,
			1,
			1,
			0,
			0
		]
	},
	{
		ColorSpace: "HSV",
		Name: "Red to Blue Rainbow",
		NanColor: [
			0.498039215686,
			0.498039215686,
			0.498039215686
		],
		RGBPoints: [
			0,
			1,
			0,
			0,
			1,
			0,
			0,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Rainbow Blended White",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			1,
			1,
			1,
			0.17,
			0,
			0,
			1,
			0.34,
			0,
			1,
			1,
			0.5,
			0,
			1,
			0,
			0.67,
			1,
			1,
			0,
			0.84,
			1,
			0,
			0,
			1,
			0.878431372549,
			0,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Rainbow Blended Grey",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0.317647058824,
			0.341176470588,
			0.43137254902,
			0.17,
			0,
			0,
			1,
			0.34,
			0,
			1,
			1,
			0.5,
			0,
			1,
			0,
			0.67,
			1,
			1,
			0,
			0.84,
			1,
			0,
			0,
			1,
			0.878431372549,
			0,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Rainbow Blended Black",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0,
			0,
			0,
			0.17,
			0,
			0,
			1,
			0.34,
			0,
			1,
			1,
			0.5,
			0,
			1,
			0,
			0.67,
			1,
			1,
			0,
			0.84,
			1,
			0,
			0,
			1,
			0.878431372549,
			0,
			1
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Blue to Yellow",
		NanColor: [
			1,
			0,
			0
		],
		RGBPoints: [
			0,
			0.0392156862745,
			0.0392156862745,
			0.949019607843,
			1,
			0.949019607843,
			0.949019607843,
			0.0392156862745
		]
	},
	{
		ColorSpace: "HSV",
		Name: "blot",
		RGBPoints: [
			0,
			0,
			0,
			1,
			0.166,
			0,
			0,
			1,
			0.167,
			1,
			0,
			1,
			0.332,
			1,
			0,
			1,
			0.333,
			0,
			1,
			1,
			0.5,
			0,
			1,
			1,
			0.501,
			0,
			1,
			0,
			0.666,
			0,
			1,
			0,
			0.667,
			1,
			1,
			0,
			0.832,
			1,
			1,
			0,
			0.833,
			1,
			0,
			0,
			1,
			1,
			0,
			0
		]
	},
	{
		ColorSpace: "Lab",
		Name: "CIELab Blue to Red",
		NanColor: [
			1,
			1,
			0
		],
		RGBPoints: [
			0,
			0,
			0.6,
			0.749019607843,
			1,
			0.76862745098,
			0.466666666667,
			0.341176470588
		]
	},
	{
		ColorSpace: "RGB",
		Name: "jet",
		RGBPoints: [
			-1,
			0,
			0,
			0.5625,
			-0.777778,
			0,
			0,
			1,
			-0.269841,
			0,
			1,
			1,
			-0.015873,
			0.5,
			1,
			0.5,
			0.238095,
			1,
			1,
			0,
			0.746032,
			1,
			0,
			0,
			1,
			0.5,
			0,
			0
		]
	},
	{
		ColorSpace: "RGB",
		Name: "rainbow",
		RGBPoints: [
			-1,
			0,
			0,
			1,
			-0.5,
			0,
			1,
			1,
			0,
			0,
			1,
			0,
			0.5,
			1,
			1,
			0,
			1,
			1,
			0,
			0
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_rainbow_bright",
		RGBPoints: [
			-1,
			0.32549,
			0.14902,
			0.960784,
			-0.866221,
			0.297047,
			0.375586,
			0.963836,
			-0.732441,
			0.180302,
			0.536818,
			0.964627,
			-0.598662,
			0.1302,
			0.649207,
			0.929647,
			-0.464883,
			0.0445143,
			0.749654,
			0.855998,
			-0.331104,
			0.0271325,
			0.830713,
			0.721527,
			-0.197324,
			0.259504,
			0.866145,
			0.543555,
			-0.0635452,
			0.428364,
			0.890725,
			0.329819,
			0.0702341,
			0.568503,
			0.898508,
			0.187623,
			0.204013,
			0.738259,
			0.890317,
			0.0825461,
			0.337793,
			0.84546,
			0.86136,
			0.0147555,
			0.471572,
			0.912191,
			0.808018,
			0,
			0.605351,
			0.962848,
			0.710445,
			0,
			0.73913,
			0.999469,
			0.600258,
			0.0176284,
			0.87291,
			0.994156,
			0.445975,
			0.193912,
			1,
			0.980407,
			0.247105,
			0.262699
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_rainbow_dark",
		RGBPoints: [
			-1,
			0,
			0,
			0.423499,
			-0.866221,
			0,
			0.119346,
			0.529237,
			-0.732441,
			0,
			0.238691,
			0.634976,
			-0.598662,
			0,
			0.346852,
			0.68788,
			-0.464883,
			0,
			0.45022,
			0.718141,
			-0.331104,
			0,
			0.553554,
			0.664839,
			-0.197324,
			0,
			0.651082,
			0.519303,
			-0.0635452,
			0.115841,
			0.72479,
			0.352857,
			0.0702341,
			0.326771,
			0.781195,
			0.140187,
			0.204013,
			0.522765,
			0.798524,
			0.0284624,
			0.337793,
			0.703162,
			0.788685,
			0.00885756,
			0.471572,
			0.845118,
			0.751133,
			0,
			0.605351,
			0.955734,
			0.690825,
			0,
			0.73913,
			0.995402,
			0.567916,
			0.0618524,
			0.87291,
			0.987712,
			0.403398,
			0.164851,
			1,
			0.980407,
			0.247105,
			0.262699
		]
	},
	{
		ColorSpace: "Lab",
		Name: "nic_CubicL",
		RGBPoints: [
			-1,
			0.479965,
			0.0118108,
			0.5307,
			-0.87451,
			0.522213,
			0.0551282,
			0.706919,
			-0.74902,
			0.50839,
			0.237278,
			0.867764,
			-0.623529,
			0.451617,
			0.373834,
			0.987255,
			-0.498039,
			0.39365,
			0.497255,
			0.97506,
			-0.372549,
			0.328631,
			0.599639,
			0.891843,
			-0.247059,
			0.250043,
			0.690286,
			0.778553,
			-0.121569,
			0.249656,
			0.764905,
			0.645857,
			0.00392157,
			0.297954,
			0.821466,
			0.50449,
			0.129412,
			0.337509,
			0.872595,
			0.358447,
			0.254902,
			0.430011,
			0.913789,
			0.297079,
			0.380392,
			0.587191,
			0.931381,
			0.333353,
			0.505882,
			0.727937,
			0.93591,
			0.353742,
			0.631373,
			0.826403,
			0.921081,
			0.365066,
			0.756863,
			0.893201,
			0.846317,
			0.372662,
			0.882353,
			0.965347,
			0.73884,
			0.378506,
			1,
			0.983235,
			0.597451,
			0.366856
		]
	},
	{
		ColorSpace: "Lab",
		Name: "nic_CubicYF",
		RGBPoints: [
			-1,
			0.5151,
			0.0482,
			0.6697,
			-0.87451,
			0.520711,
			0.168955,
			0.800574,
			-0.74902,
			0.493694,
			0.278596,
			0.911824,
			-0.623529,
			0.440026,
			0.369475,
			0.984978,
			-0.498039,
			0.398932,
			0.457593,
			0.987053,
			-0.372549,
			0.350651,
			0.540644,
			0.929608,
			-0.247059,
			0.298827,
			0.615625,
			0.857729,
			-0.121569,
			0.239928,
			0.685061,
			0.769531,
			0.00392157,
			0.228832,
			0.739349,
			0.673287,
			0.129412,
			0.263297,
			0.78608,
			0.569988,
			0.254902,
			0.298107,
			0.828337,
			0.460214,
			0.380392,
			0.33092,
			0.864071,
			0.352674,
			0.505882,
			0.38306,
			0.898169,
			0.287309,
			0.631373,
			0.49023,
			0.917481,
			0.307961,
			0.756863,
			0.62372,
			0.926026,
			0.332309,
			0.882353,
			0.717458,
			0.92527,
			0.342476,
			1,
			0.8,
			0.9255,
			0.3529
		]
	},
	{
		ColorSpace: "Lab",
		Name: "gist_earth",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.239216,
			0.027451,
			0.415686,
			-0.74902,
			0.0901961,
			0.254902,
			0.556863,
			-0.623529,
			0.0941176,
			0.352941,
			0.54902,
			-0.498039,
			0.105882,
			0.435294,
			0.533333,
			-0.372549,
			0.12549,
			0.52549,
			0.501961,
			-0.247059,
			0.156863,
			0.596078,
			0.443137,
			-0.121569,
			0.196078,
			0.65098,
			0.380392,
			0.00392157,
			0.282353,
			0.717647,
			0.301961,
			0.129412,
			0.466667,
			0.772549,
			0.27451,
			0.254902,
			0.678431,
			0.784314,
			0.309804,
			0.380392,
			0.901961,
			0.756863,
			0.376471,
			0.505882,
			0.992157,
			0.705882,
			0.521569,
			0.631373,
			1,
			0.721569,
			0.701961,
			0.756863,
			1,
			0.784314,
			0.784314,
			0.882353,
			1,
			0.866667,
			0.866667,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "2hot",
		RGBPoints: [
			-1,
			0.0416667,
			0,
			0,
			-0.873016,
			0.208333,
			0,
			0,
			-0.746032,
			0.375,
			0,
			0,
			-0.619048,
			0.541667,
			0,
			0,
			-0.492063,
			0.708333,
			0,
			0,
			-0.365079,
			0.854137,
			0,
			0,
			-0.238095,
			0.937488,
			0.039062,
			0,
			-0.111111,
			1,
			0.208333,
			0,
			0.015873,
			1,
			0.375,
			0,
			0.142857,
			1,
			0.541667,
			0,
			0.269841,
			1,
			0.708333,
			0,
			0.396825,
			1,
			0.858805,
			0.03125,
			0.52381,
			1,
			0.947392,
			0.15625,
			0.650794,
			1,
			1,
			0.3125,
			0.777778,
			1,
			1,
			0.5625,
			0.904762,
			1,
			1,
			0.8125,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_red2yellow_BW",
		RGBPoints: [
			-1,
			7.54296e-7,
			0,
			0.0000109827,
			-0.87451,
			0.18285,
			0.0264094,
			0,
			-0.74902,
			0.3066,
			0,
			0,
			-0.623529,
			0.422841,
			0,
			0,
			-0.498039,
			0.522945,
			0,
			0,
			-0.372549,
			0.605721,
			0,
			0,
			-0.247059,
			0.672502,
			0.14168,
			0,
			-0.121569,
			0.728167,
			0.244025,
			0,
			0.00392157,
			0.781215,
			0.333454,
			0,
			0.129412,
			0.825,
			0.423586,
			0,
			0.254902,
			0.855893,
			0.516793,
			0,
			0.380392,
			0.880491,
			0.608846,
			0,
			0.505882,
			0.910305,
			0.695505,
			0,
			0.631373,
			0.94109,
			0.779067,
			0.223528,
			0.756863,
			0.967873,
			0.858572,
			0.473521,
			0.882353,
			0.986815,
			0.933211,
			0.751583,
			1,
			1,
			1,
			0.999997
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_marine2gold_BW",
		RGBPoints: [
			-1,
			1.11641e-7,
			0,
			0.00000162551,
			-0.87451,
			0.0413146,
			0.0619808,
			0.209857,
			-0.74902,
			0.0185557,
			0.101341,
			0.350684,
			-0.623529,
			0.00486405,
			0.149847,
			0.461054,
			-0.498039,
			0.0836345,
			0.210845,
			0.517906,
			-0.372549,
			0.173222,
			0.276134,
			0.541793,
			-0.247059,
			0.259857,
			0.343877,
			0.535869,
			-0.121569,
			0.362299,
			0.408124,
			0.504293,
			0.00392157,
			0.468266,
			0.468276,
			0.468257,
			0.129412,
			0.582781,
			0.527545,
			0.374914,
			0.254902,
			0.691591,
			0.585251,
			0.274266,
			0.380392,
			0.784454,
			0.645091,
			0.247332,
			0.505882,
			0.862299,
			0.710383,
			0.27518,
			0.631373,
			0.920863,
			0.782923,
			0.351563,
			0.756863,
			0.955792,
			0.859699,
			0.533541,
			0.882353,
			0.976162,
			0.93433,
			0.780671,
			1,
			1,
			1,
			0.999983
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_blue2gold_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.0742735,
			0.0440331,
			0.230013,
			-0.74902,
			0.125276,
			0.0258685,
			0.415826,
			-0.623529,
			0.143879,
			0.0163031,
			0.591346,
			-0.498039,
			0.212261,
			0.0627855,
			0.705239,
			-0.372549,
			0.306048,
			0.141178,
			0.763636,
			-0.247059,
			0.391537,
			0.232286,
			0.773263,
			-0.121569,
			0.461734,
			0.336633,
			0.708321,
			0.00392157,
			0.54209,
			0.427581,
			0.590007,
			0.129412,
			0.61704,
			0.508623,
			0.460978,
			0.254902,
			0.702703,
			0.579586,
			0.309117,
			0.380392,
			0.790336,
			0.644811,
			0.170397,
			0.505882,
			0.870173,
			0.710733,
			0.117134,
			0.631373,
			0.93656,
			0.781991,
			0.157144,
			0.756863,
			0.965672,
			0.862068,
			0.409836,
			0.882353,
			0.985751,
			0.936296,
			0.714162,
			1,
			1,
			1,
			0.999999
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_sapphire2gold_BW",
		RGBPoints: [
			-1,
			0.107704,
			0.107708,
			0.107694,
			-0.87451,
			0.1851,
			0.112354,
			0.308554,
			-0.74902,
			0.236782,
			0.114233,
			0.48788,
			-0.623529,
			0.28296,
			0.126187,
			0.639464,
			-0.498039,
			0.344787,
			0.171643,
			0.739713,
			-0.372549,
			0.413325,
			0.242371,
			0.76913,
			-0.247059,
			0.481863,
			0.3131,
			0.719841,
			-0.121569,
			0.550402,
			0.383829,
			0.612222,
			0.00392157,
			0.61894,
			0.454558,
			0.51126,
			0.129412,
			0.687478,
			0.525287,
			0.39993,
			0.254902,
			0.756017,
			0.596016,
			0.289923,
			0.380392,
			0.824555,
			0.666745,
			0.255498,
			0.505882,
			0.892979,
			0.736822,
			0.27696,
			0.631373,
			0.938851,
			0.804966,
			0.351734,
			0.756863,
			0.966491,
			0.874853,
			0.53572,
			0.882353,
			0.982105,
			0.94153,
			0.782579,
			1,
			1,
			1,
			0.999986
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_red2purple_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.167793,
			0.0166271,
			0.0431278,
			-0.74902,
			0.262608,
			0.0107595,
			0.0791181,
			-0.623529,
			0.351902,
			0.0101858,
			0.100926,
			-0.498039,
			0.441257,
			0.0160835,
			0.131919,
			-0.372549,
			0.5221,
			0.0555972,
			0.195625,
			-0.247059,
			0.593852,
			0.104294,
			0.310234,
			-0.121569,
			0.654628,
			0.158115,
			0.448486,
			0.00392157,
			0.707443,
			0.220914,
			0.570253,
			0.129412,
			0.749504,
			0.293268,
			0.67897,
			0.254902,
			0.781587,
			0.370517,
			0.779269,
			0.380392,
			0.809951,
			0.451099,
			0.855831,
			0.505882,
			0.84424,
			0.531462,
			0.900451,
			0.631373,
			0.865174,
			0.620901,
			0.91606,
			0.756863,
			0.875041,
			0.714054,
			0.910284,
			0.882353,
			0.880764,
			0.80554,
			0.896276,
			1,
			0.887572,
			0.887591,
			0.887556
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_purple2pink_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.117562,
			0.0291202,
			0.175876,
			-0.74902,
			0.178368,
			0.0458476,
			0.285454,
			-0.623529,
			0.237731,
			0.0680173,
			0.387717,
			-0.498039,
			0.300877,
			0.0956291,
			0.484802,
			-0.372549,
			0.370929,
			0.136858,
			0.554985,
			-0.247059,
			0.449033,
			0.189273,
			0.58863,
			-0.121569,
			0.529971,
			0.245796,
			0.598587,
			0.00392157,
			0.609914,
			0.300643,
			0.610244,
			0.129412,
			0.697079,
			0.351286,
			0.616371,
			0.254902,
			0.785858,
			0.401991,
			0.617376,
			0.380392,
			0.862517,
			0.45745,
			0.64463,
			0.505882,
			0.91359,
			0.525462,
			0.705336,
			0.631373,
			0.932583,
			0.61064,
			0.767412,
			0.756863,
			0.922478,
			0.706966,
			0.817522,
			0.882353,
			0.901302,
			0.803071,
			0.856311,
			1,
			0.887571,
			0.887591,
			0.887549
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_pbj_lin",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.091821,
			0.0611476,
			0.10617,
			-0.74902,
			0.160311,
			0.0900022,
			0.192713,
			-0.623529,
			0.22484,
			0.12126,
			0.272128,
			-0.498039,
			0.291263,
			0.157469,
			0.340828,
			-0.372549,
			0.360015,
			0.200388,
			0.388903,
			-0.247059,
			0.437497,
			0.250058,
			0.387201,
			-0.121569,
			0.512636,
			0.304969,
			0.355955,
			0.00392157,
			0.582603,
			0.360874,
			0.33488,
			0.129412,
			0.655126,
			0.416374,
			0.306351,
			0.254902,
			0.725889,
			0.473329,
			0.279051,
			0.380392,
			0.778125,
			0.537928,
			0.302697,
			0.505882,
			0.815894,
			0.606931,
			0.382431,
			0.631373,
			0.839159,
			0.679308,
			0.497608,
			0.756863,
			0.854748,
			0.751666,
			0.631792,
			0.882353,
			0.869483,
			0.822508,
			0.768592,
			1,
			0.887572,
			0.887589,
			0.887565
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_blue2green_muted",
		RGBPoints: [
			-1,
			0.107704,
			0.107708,
			0.107695,
			-0.87451,
			0.141522,
			0.13066,
			0.270741,
			-0.74902,
			0.180123,
			0.146119,
			0.42308,
			-0.623529,
			0.210161,
			0.169674,
			0.551795,
			-0.498039,
			0.239701,
			0.212939,
			0.634969,
			-0.372549,
			0.253916,
			0.282947,
			0.653641,
			-0.247059,
			0.242791,
			0.366933,
			0.608521,
			-0.121569,
			0.226302,
			0.446776,
			0.52693,
			0.00392157,
			0.236237,
			0.514689,
			0.458798,
			0.129412,
			0.274641,
			0.577589,
			0.376069,
			0.254902,
			0.349625,
			0.633993,
			0.288131,
			0.380392,
			0.4437,
			0.683677,
			0.260497,
			0.505882,
			0.536247,
			0.731214,
			0.285424,
			0.631373,
			0.628472,
			0.777128,
			0.349151,
			0.756863,
			0.718259,
			0.819287,
			0.496825,
			0.882353,
			0.804768,
			0.856164,
			0.703299,
			1,
			0.887571,
			0.887591,
			0.887548
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_blue2green_BW",
		RGBPoints: [
			-1,
			3.63578e-7,
			0,
			0.00000529374,
			-0.87451,
			0.0539915,
			0.0577948,
			0.212806,
			-0.74902,
			0.0620393,
			0.0758942,
			0.388959,
			-0.623529,
			0.0697499,
			0.102032,
			0.54177,
			-0.498039,
			0.113295,
			0.156156,
			0.64334,
			-0.372549,
			0.152047,
			0.243196,
			0.670283,
			-0.247059,
			0.158096,
			0.344084,
			0.622864,
			-0.121569,
			0.151142,
			0.43922,
			0.532767,
			0.00392157,
			0.17155,
			0.521588,
			0.457719,
			0.129412,
			0.225861,
			0.599141,
			0.363997,
			0.254902,
			0.32328,
			0.67007,
			0.259083,
			0.380392,
			0.442344,
			0.733697,
			0.223754,
			0.505882,
			0.558409,
			0.794941,
			0.257411,
			0.631373,
			0.673875,
			0.854344,
			0.340822,
			0.756863,
			0.787244,
			0.909326,
			0.524717,
			0.882353,
			0.896483,
			0.958063,
			0.775914,
			1,
			1,
			1,
			0.999982
		]
	},
	{
		ColorSpace: "Lab",
		Name: "GREEN-WHITE_LINEAR",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0,
			0.062745,
			0,
			-0.74902,
			0,
			0.12549,
			0,
			-0.623529,
			0,
			0.188235,
			0,
			-0.498039,
			0,
			0.25098,
			0,
			-0.372549,
			0,
			0.313725,
			0,
			-0.247059,
			0,
			0.376471,
			0,
			-0.121569,
			0.094118,
			0.439216,
			0,
			0.00392157,
			0.196078,
			0.501961,
			0,
			0.129412,
			0.294118,
			0.564706,
			0,
			0.254902,
			0.396078,
			0.627451,
			0,
			0.380392,
			0.498039,
			0.690196,
			0,
			0.505882,
			0.6,
			0.752941,
			0.145098,
			0.631373,
			0.701961,
			0.815686,
			0.364706,
			0.756863,
			0.8,
			0.878431,
			0.580392,
			0.882353,
			0.901961,
			0.941176,
			0.796078,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_green2yellow_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0,
			0.105542,
			0.0603919,
			-0.74902,
			0,
			0.159454,
			0.104148,
			-0.623529,
			0,
			0.219502,
			0.15542,
			-0.498039,
			0,
			0.282276,
			0.203811,
			-0.372549,
			0,
			0.346331,
			0.235652,
			-0.247059,
			0,
			0.411765,
			0.235428,
			-0.121569,
			0,
			0.477177,
			0.217977,
			0.00392157,
			0.0593644,
			0.541635,
			0.21361,
			0.129412,
			0.233081,
			0.604722,
			0.210591,
			0.254902,
			0.369803,
			0.664942,
			0.226536,
			0.380392,
			0.498446,
			0.722367,
			0.288237,
			0.505882,
			0.601929,
			0.782244,
			0.380815,
			0.631373,
			0.703207,
			0.840497,
			0.512134,
			0.756863,
			0.803186,
			0.896433,
			0.674462,
			0.882353,
			0.903834,
			0.950266,
			0.846715,
			1,
			1,
			1,
			0.999981
		]
	},
	{
		ColorSpace: "Lab",
		Name: "blue2cyan",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0,
			0.152941,
			0.364706,
			-0.74902,
			0,
			0.254902,
			0.470588,
			-0.623529,
			0,
			0.34902,
			0.572549,
			-0.498039,
			0,
			0.443137,
			0.670588,
			-0.372549,
			0,
			0.537255,
			0.772549,
			-0.247059,
			0,
			0.627451,
			0.870588,
			-0.121569,
			0,
			0.717647,
			0.964706,
			0.00392157,
			0.0784314,
			0.772549,
			1,
			0.129412,
			0.207843,
			0.858824,
			1,
			0.254902,
			0.32549,
			0.941176,
			1,
			0.380392,
			0.45098,
			1,
			1,
			0.505882,
			0.560784,
			1,
			1,
			0.631373,
			0.662745,
			1,
			1,
			0.756863,
			0.760784,
			1,
			1,
			0.882353,
			0.870588,
			1,
			1,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_blue2cyan_BW",
		RGBPoints: [
			-1,
			4.05298e-7,
			0,
			0.0000059012,
			-0.87451,
			0.0207526,
			0.0740933,
			0.18093,
			-0.74902,
			0,
			0.121033,
			0.30343,
			-0.623529,
			0,
			0.166892,
			0.416095,
			-0.498039,
			0,
			0.216768,
			0.524796,
			-0.372549,
			0.0164769,
			0.275471,
			0.608585,
			-0.247059,
			0.0544527,
			0.344824,
			0.659267,
			-0.121569,
			0.0880643,
			0.419118,
			0.688675,
			0.00392157,
			0.127938,
			0.492556,
			0.720256,
			0.129412,
			0.149476,
			0.566946,
			0.756918,
			0.254902,
			0.188961,
			0.641333,
			0.792122,
			0.380392,
			0.245482,
			0.715336,
			0.827609,
			0.505882,
			0.329216,
			0.786235,
			0.874761,
			0.631373,
			0.453558,
			0.852803,
			0.918466,
			0.756863,
			0.626281,
			0.910493,
			0.954,
			0.882353,
			0.82257,
			0.958709,
			0.980146,
			1,
			1,
			1,
			0.999989
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_blue_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.0425591,
			0.0763529,
			0.150682,
			-0.74902,
			0.0569472,
			0.119154,
			0.275403,
			-0.623529,
			0.0635978,
			0.164772,
			0.395427,
			-0.498039,
			0.0774342,
			0.213851,
			0.510014,
			-0.372549,
			0.106815,
			0.267034,
			0.615102,
			-0.247059,
			0.122093,
			0.324649,
			0.720068,
			-0.121569,
			0.160851,
			0.387068,
			0.806956,
			0.00392157,
			0.213754,
			0.453516,
			0.878012,
			0.129412,
			0.26722,
			0.524656,
			0.932436,
			0.254902,
			0.326844,
			0.599279,
			0.968038,
			0.380392,
			0.403403,
			0.674712,
			0.984784,
			0.505882,
			0.499703,
			0.745519,
			1,
			0.631373,
			0.615055,
			0.813983,
			1,
			0.756863,
			0.74405,
			0.879228,
			1,
			0.882353,
			0.877909,
			0.941913,
			1,
			1,
			1,
			1,
			0.999996
		]
	},
	{
		ColorSpace: "Lab",
		Name: "BLUE-WHITE",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0,
			0,
			0.082353,
			-0.74902,
			0,
			0,
			0.168627,
			-0.623529,
			0,
			0,
			0.254902,
			-0.498039,
			0,
			0,
			0.337255,
			-0.372549,
			0,
			0,
			0.423529,
			-0.247059,
			0,
			0,
			0.509804,
			-0.121569,
			0,
			0.101961,
			0.592157,
			0.00392157,
			0,
			0.203922,
			0.678431,
			0.129412,
			0,
			0.301961,
			0.764706,
			0.254902,
			0,
			0.403922,
			0.85098,
			0.380392,
			0,
			0.505882,
			0.933333,
			0.505882,
			0,
			0.603922,
			1,
			0.631373,
			0.254902,
			0.705882,
			1,
			0.756863,
			0.509804,
			0.807843,
			1,
			0.882353,
			0.764706,
			0.905882,
			1,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_purple_BW",
		RGBPoints: [
			-1,
			4.264e-8,
			0,
			6.20844e-7,
			-0.87451,
			0.100579,
			0.0593111,
			0.145666,
			-0.74902,
			0.167794,
			0.0889224,
			0.254953,
			-0.623529,
			0.231446,
			0.123339,
			0.360511,
			-0.498039,
			0.296699,
			0.163027,
			0.461278,
			-0.372549,
			0.363211,
			0.209286,
			0.55306,
			-0.247059,
			0.431136,
			0.260776,
			0.637195,
			-0.121569,
			0.498202,
			0.320012,
			0.705799,
			0.00392157,
			0.567456,
			0.380459,
			0.778091,
			0.129412,
			0.629381,
			0.445284,
			0.8448,
			0.254902,
			0.688373,
			0.517374,
			0.895694,
			0.380392,
			0.74891,
			0.590906,
			0.93976,
			0.505882,
			0.805017,
			0.667956,
			0.977626,
			0.631373,
			0.850914,
			0.752618,
			0.992396,
			0.756863,
			0.89724,
			0.838454,
			0.994093,
			0.882353,
			0.948461,
			0.922603,
			0.994449,
			1,
			1,
			1,
			0.999967
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_magenta_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0.0000254023,
			-0.87451,
			0.128696,
			0.0456782,
			0.11635,
			-0.74902,
			0.228133,
			0.0476299,
			0.201452,
			-0.623529,
			0.327273,
			0.0374065,
			0.282107,
			-0.498039,
			0.420953,
			0.0408166,
			0.35709,
			-0.372549,
			0.511562,
			0.0642203,
			0.430511,
			-0.247059,
			0.599552,
			0.102686,
			0.504257,
			-0.121569,
			0.684646,
			0.150536,
			0.579429,
			0.00392157,
			0.765817,
			0.205978,
			0.656062,
			0.129412,
			0.839176,
			0.27229,
			0.731807,
			0.254902,
			0.89536,
			0.357594,
			0.797309,
			0.380392,
			0.930238,
			0.457825,
			0.846984,
			0.505882,
			0.945921,
			0.564536,
			0.880571,
			0.631373,
			0.948995,
			0.670753,
			0.902279,
			0.756863,
			0.947124,
			0.772819,
			0.918171,
			0.882353,
			0.947265,
			0.869424,
			0.934352,
			1,
			0.954719,
			0.95475,
			0.954726
		]
	},
	{
		ColorSpace: "Lab",
		Name: "magenta",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.364706,
			0,
			0.152941,
			-0.74902,
			0.470588,
			0,
			0.254902,
			-0.623529,
			0.572549,
			0,
			0.34902,
			-0.498039,
			0.670588,
			0,
			0.443137,
			-0.372549,
			0.772549,
			0,
			0.537255,
			-0.247059,
			0.870588,
			0,
			0.627451,
			-0.121569,
			0.964706,
			0,
			0.717647,
			0.00392157,
			1,
			0.0784314,
			0.772549,
			0.129412,
			1,
			0.207843,
			0.858824,
			0.254902,
			1,
			0.32549,
			0.941176,
			0.380392,
			1,
			0.45098,
			1,
			0.505882,
			1,
			0.560784,
			1,
			0.631373,
			1,
			0.662745,
			1,
			0.756863,
			1,
			0.760784,
			1,
			0.882353,
			1,
			0.870588,
			1,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "RED-PURPLE",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.188235,
			0,
			0.007843,
			-0.74902,
			0.345098,
			0,
			0.035294,
			-0.623529,
			0.439216,
			0,
			0.098039,
			-0.498039,
			0.533333,
			0,
			0.152941,
			-0.372549,
			0.627451,
			0.015686,
			0.211765,
			-0.247059,
			0.721569,
			0.031373,
			0.266667,
			-0.121569,
			0.8,
			0.047059,
			0.329412,
			0.00392157,
			0.862745,
			0.047059,
			0.403922,
			0.129412,
			0.941176,
			0.062745,
			0.466667,
			0.254902,
			0.988235,
			0.078431,
			0.54902,
			0.380392,
			0.988235,
			0.141176,
			0.643137,
			0.505882,
			0.988235,
			0.25098,
			0.729412,
			0.631373,
			0.988235,
			0.376471,
			0.811765,
			0.756863,
			0.988235,
			0.54902,
			0.886275,
			0.882353,
			0.988235,
			0.752941,
			0.952941,
			1,
			0.996078,
			0.996078,
			0.996078
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_red_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.147204,
			0.0480135,
			0.0401815,
			-0.74902,
			0.253411,
			0.0617478,
			0.0301333,
			-0.623529,
			0.356059,
			0.0746331,
			0.0446897,
			-0.498039,
			0.457731,
			0.0934935,
			0.0636931,
			-0.372549,
			0.557199,
			0.122714,
			0.0860013,
			-0.247059,
			0.665179,
			0.144238,
			0.105585,
			-0.121569,
			0.763833,
			0.187056,
			0.138326,
			0.00392157,
			0.847035,
			0.254558,
			0.189407,
			0.129412,
			0.905663,
			0.345937,
			0.258215,
			0.254902,
			0.941431,
			0.447111,
			0.346277,
			0.380392,
			0.962608,
			0.546927,
			0.457571,
			0.505882,
			0.987833,
			0.637276,
			0.569944,
			0.631373,
			0.994202,
			0.732176,
			0.687958,
			0.756863,
			0.993304,
			0.826268,
			0.800567,
			0.882353,
			0.994413,
			0.917205,
			0.906393,
			1,
			1,
			1,
			0.999979
		]
	},
	{
		ColorSpace: "Lab",
		Name: "RED_TEMPERATURE",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.090196,
			0,
			0,
			-0.74902,
			0.180392,
			0,
			0,
			-0.623529,
			0.270588,
			0,
			0,
			-0.498039,
			0.360784,
			0,
			0,
			-0.372549,
			0.45098,
			0,
			0,
			-0.247059,
			0.545098,
			0,
			0,
			-0.121569,
			0.635294,
			0,
			0,
			0.00392157,
			0.72549,
			0.058824,
			0,
			0.129412,
			0.815686,
			0.176471,
			0,
			0.254902,
			0.905882,
			0.294118,
			0,
			0.380392,
			1,
			0.411765,
			0,
			0.505882,
			1,
			0.533333,
			0.027451,
			0.631373,
			1,
			0.65098,
			0.27451,
			0.756863,
			1,
			0.768627,
			0.521569,
			0.882353,
			1,
			0.886275,
			0.768627,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_orange_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0.0000253806,
			-0.87451,
			0.135871,
			0.0593824,
			0,
			-0.74902,
			0.224328,
			0.0907216,
			0,
			-0.623529,
			0.318083,
			0.119647,
			0,
			-0.498039,
			0.414443,
			0.150246,
			0,
			-0.372549,
			0.511077,
			0.184884,
			0,
			-0.247059,
			0.605501,
			0.226033,
			0,
			-0.121569,
			0.695274,
			0.275491,
			0,
			0.00392157,
			0.777826,
			0.334445,
			0,
			0.129412,
			0.851498,
			0.402441,
			0,
			0.254902,
			0.915899,
			0.47759,
			0.000602975,
			0.380392,
			0.971984,
			0.557882,
			0.0361443,
			0.505882,
			1,
			0.641287,
			0.135967,
			0.631373,
			1,
			0.725198,
			0.27997,
			0.756863,
			1,
			0.808205,
			0.438135,
			0.882353,
			1,
			0.89306,
			0.587036,
			1,
			1,
			0.977928,
			0.721599
		]
	},
	{
		ColorSpace: "Lab",
		Name: "heated_object",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.34902,
			0.0862745,
			0,
			-0.74902,
			0.45098,
			0.172549,
			0,
			-0.623529,
			0.52549,
			0.231373,
			0,
			-0.498039,
			0.580392,
			0.278431,
			0,
			-0.372549,
			0.623529,
			0.313725,
			0,
			-0.247059,
			0.670588,
			0.352941,
			0,
			-0.121569,
			0.717647,
			0.392157,
			0,
			0.00392157,
			0.772549,
			0.439216,
			0,
			0.129412,
			0.839216,
			0.494118,
			0,
			0.254902,
			0.901961,
			0.541176,
			0,
			0.380392,
			0.968627,
			0.6,
			0,
			0.505882,
			1,
			0.658824,
			0,
			0.631373,
			1,
			0.721569,
			0,
			0.756863,
			1,
			0.827451,
			0.298039,
			0.882353,
			1,
			0.976471,
			0.72549,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_gold_BW",
		RGBPoints: [
			-1,
			0,
			0,
			0.0000190933,
			-0.87451,
			0.128363,
			0.0636265,
			0,
			-0.74902,
			0.193795,
			0.111057,
			0,
			-0.623529,
			0.25976,
			0.15987,
			0,
			-0.498039,
			0.328546,
			0.210589,
			0,
			-0.372549,
			0.399726,
			0.26332,
			0,
			-0.247059,
			0.472969,
			0.318261,
			0,
			-0.121569,
			0.546245,
			0.375827,
			0,
			0.00392157,
			0.61745,
			0.436719,
			0,
			0.129412,
			0.685545,
			0.501113,
			0,
			0.254902,
			0.749578,
			0.568799,
			0,
			0.380392,
			0.80962,
			0.6394,
			0,
			0.505882,
			0.865572,
			0.712699,
			0.10257,
			0.631373,
			0.917709,
			0.787569,
			0.233665,
			0.756863,
			0.966914,
			0.863138,
			0.369608,
			0.882353,
			1,
			0.939405,
			0.496104,
			1,
			0.999225,
			1,
			0.612275
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_brown_BW",
		RGBPoints: [
			-1,
			3.3216e-7,
			0,
			0.00000483629,
			-0.87451,
			0.14693,
			0.0518172,
			0,
			-0.74902,
			0.225806,
			0.0814996,
			0,
			-0.623529,
			0.301681,
			0.111452,
			0,
			-0.498039,
			0.370487,
			0.150664,
			0,
			-0.372549,
			0.43108,
			0.199477,
			0,
			-0.247059,
			0.4849,
			0.255107,
			0,
			-0.121569,
			0.536798,
			0.313486,
			0,
			0.00392157,
			0.59286,
			0.371167,
			0,
			0.129412,
			0.653119,
			0.428135,
			0,
			0.254902,
			0.714589,
			0.485917,
			0.0379541,
			0.380392,
			0.774667,
			0.54565,
			0.116634,
			0.505882,
			0.831222,
			0.608047,
			0.183895,
			0.631373,
			0.880305,
			0.674199,
			0.260298,
			0.756863,
			0.922314,
			0.742472,
			0.367086,
			0.882353,
			0.959408,
			0.811222,
			0.497258,
			1,
			0.993548,
			0.875183,
			0.622093
		]
	},
	{
		ColorSpace: "Lab",
		Name: "copper_Matlab",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.0784314,
			0.0501961,
			0.0313725,
			-0.74902,
			0.156863,
			0.100392,
			0.0627451,
			-0.623529,
			0.235294,
			0.150588,
			0.0941176,
			-0.498039,
			0.313725,
			0.200784,
			0.12549,
			-0.372549,
			0.392157,
			0.25098,
			0.156863,
			-0.247059,
			0.470588,
			0.301176,
			0.188235,
			-0.121569,
			0.54902,
			0.351373,
			0.219608,
			0.00392157,
			0.627451,
			0.401569,
			0.25098,
			0.129412,
			0.705882,
			0.451765,
			0.282353,
			0.254902,
			0.784314,
			0.501961,
			0.313725,
			0.380392,
			0.862745,
			0.552157,
			0.345098,
			0.505882,
			0.941176,
			0.602353,
			0.376471,
			0.631373,
			1,
			0.652549,
			0.407843,
			0.756863,
			1,
			0.702745,
			0.439216,
			0.882353,
			1,
			0.752941,
			0.470588,
			1,
			1,
			0.8,
			0.5
		]
	},
	{
		ColorSpace: "Lab",
		Name: "pink_Matlab",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.312416,
			0.204524,
			0.204524,
			-0.74902,
			0.441822,
			0.289241,
			0.289241,
			-0.623529,
			0.54112,
			0.354246,
			0.354246,
			-0.498039,
			0.624831,
			0.409048,
			0.409048,
			-0.372549,
			0.698582,
			0.45733,
			0.45733,
			-0.247059,
			0.764404,
			0.502282,
			0.500979,
			-0.121569,
			0.791292,
			0.591516,
			0.54112,
			0.00392157,
			0.817297,
			0.66895,
			0.578481,
			0.129412,
			0.842499,
			0.738308,
			0.613572,
			0.254902,
			0.866968,
			0.801687,
			0.646762,
			0.380392,
			0.890766,
			0.86041,
			0.678329,
			0.505882,
			0.913944,
			0.913944,
			0.711254,
			0.631373,
			0.936549,
			0.936549,
			0.79459,
			0.756863,
			0.958621,
			0.958621,
			0.869979,
			0.882353,
			0.980196,
			0.980196,
			0.939336,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "bone_Matlab",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.054902,
			0.054902,
			0.075817,
			-0.74902,
			0.109804,
			0.109804,
			0.151634,
			-0.623529,
			0.164706,
			0.164706,
			0.227451,
			-0.498039,
			0.219608,
			0.219608,
			0.303268,
			-0.372549,
			0.27451,
			0.27451,
			0.379085,
			-0.247059,
			0.329412,
			0.329902,
			0.454412,
			-0.121569,
			0.384314,
			0.405719,
			0.509314,
			0.00392157,
			0.439216,
			0.481536,
			0.564216,
			0.129412,
			0.494118,
			0.557353,
			0.619118,
			0.254902,
			0.54902,
			0.63317,
			0.67402,
			0.380392,
			0.603922,
			0.708987,
			0.728922,
			0.505882,
			0.660294,
			0.783824,
			0.783824,
			0.631373,
			0.746569,
			0.838725,
			0.838725,
			0.756863,
			0.832843,
			0.893627,
			0.893627,
			0.882353,
			0.919118,
			0.948529,
			0.948529,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "gray_Matlab",
		RGBPoints: [
			-1,
			0,
			0,
			0,
			-0.87451,
			0.0627451,
			0.0627451,
			0.0627451,
			-0.74902,
			0.12549,
			0.12549,
			0.12549,
			-0.623529,
			0.188235,
			0.188235,
			0.188235,
			-0.498039,
			0.25098,
			0.25098,
			0.25098,
			-0.372549,
			0.313725,
			0.313725,
			0.313725,
			-0.247059,
			0.376471,
			0.376471,
			0.376471,
			-0.121569,
			0.439216,
			0.439216,
			0.439216,
			0.00392157,
			0.501961,
			0.501961,
			0.501961,
			0.129412,
			0.564706,
			0.564706,
			0.564706,
			0.254902,
			0.627451,
			0.627451,
			0.627451,
			0.380392,
			0.690196,
			0.690196,
			0.690196,
			0.505882,
			0.752941,
			0.752941,
			0.752941,
			0.631373,
			0.815686,
			0.815686,
			0.815686,
			0.756863,
			0.878431,
			0.878431,
			0.878431,
			0.882353,
			0.941176,
			0.941176,
			0.941176,
			1,
			1,
			1,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Purples",
		RGBPoints: [
			-1,
			0.247059,
			0,
			0.490196,
			-0.87451,
			0.288397,
			0.07677,
			0.525629,
			-0.74902,
			0.32975,
			0.153587,
			0.561092,
			-0.623529,
			0.373057,
			0.236263,
			0.600461,
			-0.498039,
			0.416363,
			0.319,
			0.639923,
			-0.372549,
			0.459669,
			0.405613,
			0.685198,
			-0.247059,
			0.503345,
			0.491534,
			0.730058,
			-0.121569,
			0.562399,
			0.54862,
			0.757616,
			0.00392157,
			0.621453,
			0.606075,
			0.785544,
			0.129412,
			0.680508,
			0.674971,
			0.824914,
			0.254902,
			0.739562,
			0.743406,
			0.863899,
			0.380392,
			0.798616,
			0.800492,
			0.893426,
			0.505882,
			0.85684,
			0.856655,
			0.922491,
			0.631373,
			0.898178,
			0.894056,
			0.942176,
			0.756863,
			0.938654,
			0.930919,
			0.961646,
			0.882353,
			0.964245,
			0.958478,
			0.977393,
			1,
			0.988235,
			0.984314,
			0.992157
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Blues",
		RGBPoints: [
			-1,
			0.031373,
			0.188235,
			0.419608,
			-0.87451,
			0.031373,
			0.253195,
			0.516063,
			-0.74902,
			0.031757,
			0.318139,
			0.612149,
			-0.623529,
			0.080969,
			0.38113,
			0.661361,
			-0.498039,
			0.130427,
			0.444152,
			0.710327,
			-0.372549,
			0.195386,
			0.509112,
			0.743791,
			-0.247059,
			0.260715,
			0.573841,
			0.777209,
			-0.121569,
			0.341423,
			0.628958,
			0.808704,
			0.00392157,
			0.422745,
			0.684075,
			0.839892,
			0.129412,
			0.523137,
			0.739193,
			0.861546,
			0.254902,
			0.622684,
			0.793464,
			0.883429,
			0.380392,
			0.701423,
			0.826928,
			0.910988,
			0.505882,
			0.778685,
			0.8603,
			0.937993,
			0.631373,
			0.825928,
			0.891795,
			0.953741,
			0.756863,
			0.87328,
			0.923291,
			0.969489,
			0.882353,
			0.922491,
			0.954787,
			0.985236,
			1,
			0.968627,
			0.984314,
			1
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Greens",
		RGBPoints: [
			-1,
			0,
			0.266667,
			0.105882,
			-0.87451,
			0,
			0.347374,
			0.139346,
			-0.74902,
			0.000538,
			0.427912,
			0.172933,
			-0.623529,
			0.069435,
			0.486967,
			0.222145,
			-0.498039,
			0.138178,
			0.546082,
			0.271326,
			-0.372549,
			0.197232,
			0.609073,
			0.31857,
			-0.247059,
			0.257255,
			0.671742,
			0.365859,
			-0.121569,
			0.357647,
			0.720953,
			0.415071,
			0.00392157,
			0.45767,
			0.769919,
			0.465021,
			0.129412,
			0.546251,
			0.811257,
			0.537855,
			0.254902,
			0.634295,
			0.852211,
			0.610688,
			0.380392,
			0.709097,
			0.883706,
			0.683522,
			0.505882,
			0.78316,
			0.914833,
			0.755894,
			0.631373,
			0.842215,
			0.938454,
			0.818885,
			0.756863,
			0.899977,
			0.961538,
			0.880692,
			0.882353,
			0.935409,
			0.975317,
			0.92203,
			1,
			0.968627,
			0.988235,
			0.960784
		]
	},
	{
		ColorSpace: "Lab",
		Name: "PuBu",
		RGBPoints: [
			-1,
			0.301961,
			0,
			0.294118,
			-0.87451,
			0.404321,
			0.029527,
			0.390573,
			-0.74902,
			0.50599,
			0.059592,
			0.486782,
			-0.623529,
			0.519769,
			0.158016,
			0.551742,
			-0.498039,
			0.533456,
			0.256194,
			0.616301,
			-0.372549,
			0.54133,
			0.33887,
			0.655671,
			-0.247059,
			0.54902,
			0.421592,
			0.695087,
			-0.121569,
			0.54902,
			0.506236,
			0.736424,
			0.00392157,
			0.550127,
			0.590573,
			0.777701,
			0.129412,
			0.585559,
			0.665375,
			0.81707,
			0.254902,
			0.622145,
			0.739023,
			0.855825,
			0.380392,
			0.687105,
			0.784298,
			0.879446,
			0.505882,
			0.752065,
			0.829758,
			0.903253,
			0.631373,
			0.817024,
			0.87897,
			0.930811,
			0.756863,
			0.880907,
			0.927213,
			0.957832,
			0.882353,
			0.926182,
			0.958708,
			0.975548,
			1,
			0.968627,
			0.988235,
			0.992157
		]
	},
	{
		ColorSpace: "Lab",
		Name: "BuPu",
		RGBPoints: [
			-1,
			0.007843,
			0.219608,
			0.345098,
			-0.87451,
			0.01178,
			0.286536,
			0.449427,
			-0.74902,
			0.015702,
			0.35328,
			0.553479,
			-0.623529,
			0.01767,
			0.396586,
			0.622376,
			-0.498039,
			0.021115,
			0.4402,
			0.690688,
			-0.372549,
			0.11757,
			0.503191,
			0.722184,
			-0.247059,
			0.214625,
			0.565859,
			0.753633,
			-0.121569,
			0.336671,
			0.615071,
			0.78316,
			0.00392157,
			0.457978,
			0.663975,
			0.812503,
			0.129412,
			0.556401,
			0.703345,
			0.836125,
			0.254902,
			0.65421,
			0.742714,
			0.859669,
			0.380392,
			0.736886,
			0.782084,
			0.881323,
			0.505882,
			0.81827,
			0.821638,
			0.903068,
			0.631373,
			0.873387,
			0.864944,
			0.92669,
			0.756863,
			0.927536,
			0.907605,
			0.949988,
			0.882353,
			0.964937,
			0.9391,
			0.967705,
			1,
			1,
			0.968627,
			0.984314
		]
	},
	{
		ColorSpace: "Lab",
		Name: "BuGn",
		RGBPoints: [
			-1,
			0.031373,
			0.25098,
			0.505882,
			-0.87451,
			0.031373,
			0.329719,
			0.590527,
			-0.74902,
			0.031911,
			0.408397,
			0.674787,
			-0.623529,
			0.100807,
			0.479262,
			0.710219,
			-0.498039,
			0.169704,
			0.550219,
			0.745744,
			-0.372549,
			0.238601,
			0.62699,
			0.787082,
			-0.247059,
			0.307958,
			0.703114,
			0.826759,
			-0.121569,
			0.39654,
			0.752326,
			0.797232,
			0.00392157,
			0.485121,
			0.801046,
			0.767705,
			0.129412,
			0.573702,
			0.83451,
			0.738178,
			0.254902,
			0.661592,
			0.867743,
			0.711034,
			0.380392,
			0.732457,
			0.895302,
			0.74253,
			0.505882,
			0.801845,
			0.922307,
			0.774579,
			0.631373,
			0.841215,
			0.938055,
			0.817885,
			0.756863,
			0.880907,
			0.95391,
			0.861084,
			0.882353,
			0.926182,
			0.971626,
			0.902422,
			1,
			0.968627,
			0.988235,
			0.941176
		]
	},
	{
		ColorSpace: "Lab",
		Name: "GnBu",
		RGBPoints: [
			-1,
			0,
			0.266667,
			0.105882,
			-0.87451,
			0,
			0.347374,
			0.139346,
			-0.74902,
			0.000538,
			0.427912,
			0.172933,
			-0.623529,
			0.069435,
			0.486967,
			0.222145,
			-0.498039,
			0.138178,
			0.546175,
			0.272095,
			-0.372549,
			0.197232,
			0.615071,
			0.368551,
			-0.247059,
			0.256609,
			0.683276,
			0.464867,
			-0.121569,
			0.329443,
			0.722645,
			0.555417,
			0.00392157,
			0.403137,
			0.762138,
			0.645413,
			0.129412,
			0.503529,
			0.805444,
			0.718247,
			0.254902,
			0.603922,
			0.848597,
			0.790465,
			0.380392,
			0.704314,
			0.887966,
			0.847551,
			0.505882,
			0.802307,
			0.926321,
			0.903714,
			0.631373,
			0.851519,
			0.944037,
			0.941115,
			0.756863,
			0.899977,
			0.961538,
			0.976901,
			0.882353,
			0.935409,
			0.975317,
			0.984775,
			1,
			0.968627,
			0.988235,
			0.992157
		]
	},
	{
		ColorSpace: "Lab",
		Name: "GnBuPu",
		RGBPoints: [
			-1,
			0.003922,
			0.27451,
			0.211765,
			-0.87451,
			0.003922,
			0.349312,
			0.280661,
			-0.74902,
			0.003937,
			0.423852,
			0.349773,
			-0.623529,
			0.005905,
			0.46519,
			0.446228,
			-0.498039,
			0.009443,
			0.506344,
			0.542837,
			-0.372549,
			0.111803,
			0.535871,
			0.649135,
			-0.247059,
			0.214025,
			0.565859,
			0.753633,
			-0.121569,
			0.310481,
			0.615071,
			0.78316,
			0.00392157,
			0.407797,
			0.663975,
			0.812503,
			0.129412,
			0.531811,
			0.703345,
			0.836125,
			0.254902,
			0.65421,
			0.742714,
			0.859669,
			0.380392,
			0.736886,
			0.782084,
			0.881323,
			0.505882,
			0.81827,
			0.821176,
			0.902884,
			0.631373,
			0.873387,
			0.854641,
			0.922568,
			0.756863,
			0.927536,
			0.888535,
			0.942361,
			0.882353,
			0.964937,
			0.929873,
			0.964014,
			1,
			1,
			0.968627,
			0.984314
		]
	},
	{
		ColorSpace: "Lab",
		Name: "BuGnYl",
		RGBPoints: [
			-1,
			0.031373,
			0.113725,
			0.345098,
			-0.87451,
			0.088458,
			0.159,
			0.463206,
			-0.74902,
			0.145052,
			0.204567,
			0.5807,
			-0.623529,
			0.139146,
			0.287243,
			0.620069,
			-0.498039,
			0.13318,
			0.370196,
			0.659562,
			-0.372549,
			0.123337,
			0.470588,
			0.706805,
			-0.247059,
			0.115386,
			0.570335,
			0.753126,
			-0.121569,
			0.186251,
			0.643168,
			0.761,
			0.00392157,
			0.258716,
			0.71514,
			0.768074,
			0.129412,
			0.380761,
			0.760415,
			0.750358,
			0.254902,
			0.503576,
			0.806075,
			0.732795,
			0.380392,
			0.645306,
			0.861192,
			0.719016,
			0.505882,
			0.783899,
			0.91511,
			0.705606,
			0.631373,
			0.858701,
			0.944637,
			0.6997,
			0.756863,
			0.931349,
			0.973303,
			0.698424,
			0.882353,
			0.966782,
			0.987082,
			0.777163,
			1,
			1,
			1,
			0.85098
		]
	},
	{
		ColorSpace: "Lab",
		Name: "PuRd",
		RGBPoints: [
			-1,
			0.286275,
			0,
			0.415686,
			-0.87451,
			0.38273,
			0.001968,
			0.441276,
			-0.74902,
			0.479231,
			0.003922,
			0.466774,
			-0.623529,
			0.581592,
			0.003922,
			0.480554,
			-0.498039,
			0.683799,
			0.00549,
			0.494887,
			-0.372549,
			0.776317,
			0.105882,
			0.544098,
			-0.247059,
			0.867866,
			0.206321,
			0.592618,
			-0.121569,
			0.919047,
			0.308681,
			0.612303,
			0.00392157,
			0.968812,
			0.411226,
			0.632603,
			0.129412,
			0.974717,
			0.519493,
			0.671972,
			0.254902,
			0.980546,
			0.626451,
			0.71065,
			0.380392,
			0.984483,
			0.701253,
			0.732303,
			0.505882,
			0.988328,
			0.77504,
			0.755617,
			0.631373,
			0.990296,
			0.828189,
			0.812703,
			0.756863,
			0.992372,
			0.880907,
			0.869035,
			0.882353,
			0.996309,
			0.926182,
			0.912341,
			1,
			1,
			0.968627,
			0.952941
		]
	},
	{
		ColorSpace: "Lab",
		Name: "RdPu",
		RGBPoints: [
			-1,
			0.403922,
			0,
			0.121569,
			-0.87451,
			0.500377,
			0,
			0.192434,
			-0.74902,
			0.596909,
			0.000277,
			0.263037,
			-0.623529,
			0.703206,
			0.035709,
			0.300438,
			-0.498039,
			0.808612,
			0.071296,
			0.338854,
			-0.372549,
			0.857824,
			0.116571,
			0.441215,
			-0.247059,
			0.905513,
			0.163552,
			0.54293,
			-0.121569,
			0.889765,
			0.281661,
			0.617732,
			0.00392157,
			0.873156,
			0.39897,
			0.691611,
			0.129412,
			0.82985,
			0.491488,
			0.736886,
			0.254902,
			0.789081,
			0.583237,
			0.781853,
			0.380392,
			0.810734,
			0.656071,
			0.819254,
			0.505882,
			0.833126,
			0.729181,
			0.85684,
			0.631373,
			0.870527,
			0.80792,
			0.898178,
			0.756863,
			0.907605,
			0.884398,
			0.938331,
			0.882353,
			0.9391,
			0.921799,
			0.958016,
			1,
			0.968627,
			0.956863,
			0.976471
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Oranges",
		RGBPoints: [
			-1,
			0.498039,
			0.152941,
			0.015686,
			-0.87451,
			0.57481,
			0.182468,
			0.013718,
			-0.74902,
			0.651765,
			0.212042,
			0.011734,
			-0.623529,
			0.752157,
			0.247474,
			0.007797,
			-0.498039,
			0.851719,
			0.283368,
			0.004475,
			-0.372549,
			0.898962,
			0.348328,
			0.039908,
			-0.247059,
			0.945652,
			0.413426,
			0.076401,
			-0.121569,
			0.969273,
			0.484291,
			0.157109,
			0.00392157,
			0.992157,
			0.554971,
			0.238185,
			0.129412,
			0.992157,
			0.619931,
			0.330704,
			0.254902,
			0.992157,
			0.684967,
			0.423837,
			0.380392,
			0.992157,
			0.751895,
			0.532103,
			0.505882,
			0.992249,
			0.817716,
			0.639354,
			0.631373,
			0.994218,
			0.861023,
			0.725967,
			0.756863,
			0.996186,
			0.903576,
			0.810965,
			0.882353,
			0.998155,
			0.933103,
			0.868051,
			1,
			1,
			0.960784,
			0.921569
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Reds",
		RGBPoints: [
			-1,
			0.403922,
			0,
			0.05098,
			-0.87451,
			0.525967,
			0.029527,
			0.066728,
			-0.74902,
			0.647643,
			0.058962,
			0.082476,
			-0.623529,
			0.722445,
			0.076678,
			0.098224,
			-0.498039,
			0.797186,
			0.095194,
			0.114187,
			-0.372549,
			0.868051,
			0.164091,
			0.143714,
			-0.247059,
			0.937809,
			0.233541,
			0.173933,
			-0.121569,
			0.96143,
			0.326059,
			0.232987,
			0.00392157,
			0.984375,
			0.418147,
			0.292657,
			0.129412,
			0.986344,
			0.496886,
			0.371396,
			0.254902,
			0.988235,
			0.575702,
			0.450673,
			0.380392,
			0.988235,
			0.656409,
			0.543191,
			0.505882,
			0.98842,
			0.736747,
			0.635894,
			0.631373,
			0.992357,
			0.809581,
			0.732349,
			0.756863,
			0.996186,
			0.880692,
			0.826759,
			0.882353,
			0.998155,
			0.92203,
			0.885813,
			1,
			1,
			0.960784,
			0.941176
		]
	},
	{
		ColorSpace: "Lab",
		Name: "RdOr",
		RGBPoints: [
			-1,
			0.498039,
			0,
			0,
			-0.87451,
			0.6004,
			0,
			0,
			-0.74902,
			0.702514,
			0.000738,
			0.000477,
			-0.623529,
			0.773379,
			0.095225,
			0.061499,
			-0.498039,
			0.843875,
			0.189865,
			0.12283,
			-0.372549,
			0.891119,
			0.294195,
			0.203537,
			-0.247059,
			0.937855,
			0.397924,
			0.283137,
			-0.121569,
			0.963445,
			0.476663,
			0.316601,
			0.00392157,
			0.988297,
			0.555771,
			0.351665,
			0.129412,
			0.990265,
			0.646321,
			0.436309,
			0.254902,
			0.992157,
			0.735256,
			0.519646,
			0.380392,
			0.992157,
			0.784468,
			0.570827,
			0.505882,
			0.992249,
			0.833218,
			0.623483,
			0.631373,
			0.994218,
			0.872587,
			0.706159,
			0.756863,
			0.996186,
			0.911419,
			0.788189,
			0.882353,
			0.998155,
			0.940946,
			0.859054,
			1,
			1,
			0.968627,
			0.92549
		]
	},
	{
		ColorSpace: "Lab",
		Name: "BrOrYl",
		RGBPoints: [
			-1,
			0.4,
			0.145098,
			0.023529,
			-0.87451,
			0.500392,
			0.174625,
			0.019592,
			-0.74902,
			0.600784,
			0.204291,
			0.015656,
			-0.623529,
			0.701176,
			0.251534,
			0.011719,
			-0.498039,
			0.800984,
			0.299146,
			0.008397,
			-0.372549,
			0.863975,
			0.370012,
			0.043829,
			-0.247059,
			0.926321,
			0.441107,
			0.0794,
			-0.121569,
			0.961753,
			0.521815,
			0.120738,
			0.00392157,
			0.996078,
			0.602645,
			0.163122,
			0.129412,
			0.996078,
			0.68729,
			0.237924,
			0.254902,
			0.996078,
			0.771011,
			0.314879,
			0.380392,
			0.996078,
			0.832034,
			0.444798,
			0.505882,
			0.996171,
			0.892042,
			0.572595,
			0.631373,
			0.998139,
			0.931411,
			0.65724,
			0.756863,
			1,
			0.969489,
			0.741669,
			0.882353,
			1,
			0.985236,
			0.822376,
			1,
			1,
			1,
			0.898039
		]
	},
	{
		ColorSpace: "Lab",
		Name: "RdOrYl",
		RGBPoints: [
			-1,
			0.501961,
			0,
			0.14902,
			-0.87451,
			0.622038,
			0,
			0.14902,
			-0.74902,
			0.741761,
			0.0004,
			0.148866,
			-0.623529,
			0.816563,
			0.05158,
			0.129181,
			-0.498039,
			0.890965,
			0.10356,
			0.110235,
			-0.372549,
			0.940177,
			0.205921,
			0.137793,
			-0.247059,
			0.988281,
			0.308789,
			0.165536,
			-0.121569,
			0.99025,
			0.432803,
			0.200969,
			0.00392157,
			0.992218,
			0.555217,
			0.236278,
			0.129412,
			0.994187,
			0.628051,
			0.267774,
			0.254902,
			0.996078,
			0.701038,
			0.301269,
			0.380392,
			0.996078,
			0.777809,
			0.383945,
			0.505882,
			0.996171,
			0.852826,
			0.466621,
			0.631373,
			0.998139,
			0.892195,
			0.549296,
			0.756863,
			1,
			0.931349,
			0.632188,
			0.882353,
			1,
			0.966782,
			0.7188,
			1,
			1,
			1,
			0.8
		]
	},
	{
		ColorSpace: "Lab",
		Name: "CIELab_blue2red",
		RGBPoints: [
			-1,
			0,
			0.6,
			0.74902,
			1,
			0.76863,
			0.46667,
			0.34118
		]
	},
	{
		ColorSpace: "Lab",
		Name: "blue2yellow",
		RGBPoints: [
			-1,
			0,
			0,
			1,
			0,
			0.5,
			0.5,
			0.5,
			1,
			1,
			1,
			0
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_blue2gold",
		RGBPoints: [
			-1,
			0.175119,
			0.0438468,
			1,
			-0.874016,
			0.22383,
			0.159771,
			0.94557,
			-0.748031,
			0.27254,
			0.233611,
			0.891216,
			-0.622047,
			0.321251,
			0.296526,
			0.836857,
			-0.496063,
			0.369962,
			0.354296,
			0.782359,
			-0.370079,
			0.418672,
			0.409139,
			0.72754,
			-0.244094,
			0.467383,
			0.462152,
			0.672148,
			-0.11811,
			0.51609,
			0.51396,
			0.615825,
			0.00787402,
			0.572863,
			0.55452,
			0.559172,
			0.133858,
			0.630269,
			0.593822,
			0.517729,
			0.259843,
			0.689588,
			0.624668,
			0.47446,
			0.385827,
			0.745394,
			0.656113,
			0.428638,
			0.511811,
			0.798624,
			0.688104,
			0.379105,
			0.637795,
			0.849926,
			0.720593,
			0.323834,
			0.76378,
			0.899765,
			0.753543,
			0.258657,
			0.889764,
			0.948487,
			0.78692,
			0.171778,
			1,
			0.990413,
			0.816451,
			0.00729848
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_blue2yellow",
		RGBPoints: [
			-1,
			0.0830122,
			0,
			0.495617,
			-0.87451,
			0.141973,
			0.0551288,
			0.57363,
			-0.74902,
			0.193048,
			0.110258,
			0.604561,
			-0.623529,
			0.234231,
			0.165386,
			0.57643,
			-0.498039,
			0.275413,
			0.220515,
			0.548299,
			-0.372549,
			0.316596,
			0.275644,
			0.520169,
			-0.247059,
			0.357778,
			0.330773,
			0.492038,
			-0.121569,
			0.398961,
			0.385901,
			0.463908,
			0.00392157,
			0.449929,
			0.438487,
			0.426815,
			0.129412,
			0.511572,
			0.488299,
			0.379944,
			0.254902,
			0.581222,
			0.53603,
			0.325741,
			0.380392,
			0.650871,
			0.583761,
			0.271538,
			0.505882,
			0.720521,
			0.631493,
			0.217335,
			0.631373,
			0.79017,
			0.679224,
			0.163132,
			0.756863,
			0.85982,
			0.726955,
			0.108929,
			0.882353,
			0.910254,
			0.774159,
			0.14112,
			1,
			0.927513,
			0.81759,
			0.306289
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_cyan2orange",
		RGBPoints: [
			-1,
			0.0471513,
			0.213874,
			0.414329,
			-0.87451,
			0.0674702,
			0.256648,
			0.439027,
			-0.74902,
			0.0959957,
			0.299331,
			0.462089,
			-0.623529,
			0.132428,
			0.341872,
			0.483212,
			-0.498039,
			0.188743,
			0.38277,
			0.500597,
			-0.372549,
			0.268511,
			0.420229,
			0.512179,
			-0.247059,
			0.352945,
			0.455602,
			0.519101,
			-0.121569,
			0.43893,
			0.489368,
			0.521538,
			0.00392157,
			0.522445,
			0.522495,
			0.522436,
			0.129412,
			0.600089,
			0.555682,
			0.53205,
			0.254902,
			0.67988,
			0.587981,
			0.539163,
			0.380392,
			0.761011,
			0.619586,
			0.544439,
			0.505882,
			0.84278,
			0.650741,
			0.548567,
			0.631373,
			0.910713,
			0.687347,
			0.557822,
			0.756863,
			0.952232,
			0.734972,
			0.577775,
			0.882353,
			0.975642,
			0.789858,
			0.604868,
			1,
			0.990752,
			0.843643,
			0.632857
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_purple2green",
		RGBPoints: [
			-1,
			0.235006,
			0.0483128,
			0.530899,
			-0.87451,
			0.302968,
			0.108419,
			0.552391,
			-0.74902,
			0.360241,
			0.166059,
			0.569502,
			-0.623529,
			0.406746,
			0.226782,
			0.579373,
			-0.498039,
			0.444073,
			0.28964,
			0.582094,
			-0.372549,
			0.473648,
			0.353774,
			0.577947,
			-0.247059,
			0.497636,
			0.418154,
			0.567911,
			-0.121569,
			0.519086,
			0.481741,
			0.553968,
			0.00392157,
			0.542884,
			0.542914,
			0.542875,
			0.129412,
			0.566303,
			0.603989,
			0.527499,
			0.254902,
			0.595218,
			0.662965,
			0.516857,
			0.380392,
			0.628641,
			0.720701,
			0.510673,
			0.505882,
			0.665373,
			0.777849,
			0.508165,
			0.631373,
			0.704182,
			0.834921,
			0.508303,
			0.756863,
			0.743846,
			0.892328,
			0.50999,
			0.882353,
			0.783158,
			0.950422,
			0.512181,
			1,
			0.818617,
			1,
			0.513888
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_purple2green_dark",
		RGBPoints: [
			-1,
			0.107656,
			0,
			0.428682,
			-0.87451,
			0.1924,
			0,
			0.449799,
			-0.74902,
			0.255118,
			0.0648939,
			0.466726,
			-0.623529,
			0.304256,
			0.133066,
			0.476703,
			-0.498039,
			0.343202,
			0.19716,
			0.479793,
			-0.372549,
			0.373876,
			0.260353,
			0.476241,
			-0.247059,
			0.398497,
			0.322872,
			0.466953,
			-0.121569,
			0.420016,
			0.384252,
			0.453785,
			0.00392157,
			0.44319,
			0.443216,
			0.443186,
			0.129412,
			0.465553,
			0.502139,
			0.428233,
			0.254902,
			0.492959,
			0.559151,
			0.417591,
			0.380392,
			0.524654,
			0.615092,
			0.411016,
			0.505882,
			0.55959,
			0.670583,
			0.40779,
			0.631373,
			0.596614,
			0.726102,
			0.406948,
			0.756863,
			0.634544,
			0.782032,
			0.407439,
			0.882353,
			0.672183,
			0.838703,
			0.408237,
			1,
			0.706131,
			0.892759,
			0.408452
		]
	},
	{
		ColorSpace: "Lab",
		Name: "coolwarm",
		RGBPoints: [
			-1,
			0.229806,
			0.298718,
			0.753683,
			-0.875,
			0.303869,
			0.406535,
			0.844959,
			-0.75,
			0.383013,
			0.509419,
			0.917388,
			-0.625,
			0.466667,
			0.604563,
			0.968155,
			-0.5,
			0.552953,
			0.688929,
			0.995376,
			-0.375,
			0.639176,
			0.7596,
			0.998151,
			-0.25,
			0.722193,
			0.813953,
			0.976575,
			-0.125,
			0.798692,
			0.849786,
			0.931689,
			0,
			0.865395,
			0.86541,
			0.865396,
			0.125,
			0.924128,
			0.827385,
			0.774508,
			0.25,
			0.958853,
			0.769768,
			0.678008,
			0.375,
			0.969954,
			0.694267,
			0.579375,
			0.5,
			0.958003,
			0.602842,
			0.481776,
			0.625,
			0.923945,
			0.497309,
			0.38797,
			0.75,
			0.869187,
			0.378313,
			0.300267,
			0.875,
			0.795632,
			0.241284,
			0.220526,
			1,
			0.705673,
			0.0155562,
			0.150233
		]
	},
	{
		ColorSpace: "Lab",
		Name: "BuRd",
		RGBPoints: [
			-1,
			0.019608,
			0.188235,
			0.380392,
			-0.87451,
			0.088504,
			0.321107,
			0.564937,
			-0.74902,
			0.163399,
			0.444983,
			0.697501,
			-0.623529,
			0.247059,
			0.555709,
			0.754095,
			-0.498039,
			0.420684,
			0.676432,
			0.818685,
			-0.372549,
			0.606459,
			0.789773,
			0.880277,
			-0.247059,
			0.761476,
			0.868512,
			0.924567,
			-0.121569,
			0.878047,
			0.925721,
			0.951942,
			0.00392157,
			0.969089,
			0.966474,
			0.964937,
			0.129412,
			0.983852,
			0.897578,
			0.846828,
			0.254902,
			0.982468,
			0.800692,
			0.706113,
			0.380392,
			0.960323,
			0.66782,
			0.536332,
			0.505882,
			0.894579,
			0.503806,
			0.399769,
			0.631373,
			0.81707,
			0.33218,
			0.281046,
			0.756863,
			0.728489,
			0.155017,
			0.197386,
			0.882353,
			0.576932,
			0.055363,
			0.14925,
			1,
			0.403922,
			0,
			0.121569
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Spectral_lowBlue",
		RGBPoints: [
			-1,
			0.368627,
			0.309804,
			0.635294,
			-0.87451,
			0.260361,
			0.450058,
			0.70173,
			-0.74902,
			0.248058,
			0.591311,
			0.717186,
			-0.623529,
			0.376009,
			0.734025,
			0.658132,
			-0.498039,
			0.537947,
			0.814764,
			0.64506,
			-0.372549,
			0.702345,
			0.879585,
			0.636678,
			-0.247059,
			0.84752,
			0.938639,
			0.607151,
			-0.121569,
			0.940408,
			0.976163,
			0.656055,
			0.00392157,
			0.999923,
			0.997616,
			0.745021,
			0.129412,
			0.997463,
			0.921338,
			0.61707,
			0.254902,
			0.995002,
			0.824606,
			0.499885,
			0.380392,
			0.992541,
			0.701576,
			0.39654,
			0.505882,
			0.973472,
			0.547405,
			0.318108,
			0.631373,
			0.937793,
			0.398539,
			0.270127,
			0.756863,
			0.861515,
			0.282891,
			0.299654,
			0.882353,
			0.746482,
			0.144637,
			0.288812,
			1,
			0.619608,
			0.003922,
			0.258824
		]
	},
	{
		ColorSpace: "Lab",
		Name: "GnRP",
		RGBPoints: [
			-1,
			0,
			0.266667,
			0.105882,
			-0.87451,
			0.066436,
			0.394617,
			0.174779,
			-0.74902,
			0.168858,
			0.524567,
			0.25767,
			-0.623529,
			0.323875,
			0.657439,
			0.361015,
			-0.498039,
			0.504883,
			0.772318,
			0.506344,
			-0.372549,
			0.678431,
			0.870127,
			0.654902,
			-0.247059,
			0.803922,
			0.921799,
			0.780392,
			-0.121569,
			0.897116,
			0.951942,
			0.882814,
			0.00392157,
			0.967397,
			0.965936,
			0.967474,
			0.129412,
			0.928028,
			0.879815,
			0.930565,
			0.254902,
			0.866052,
			0.780777,
			0.882891,
			0.380392,
			0.77501,
			0.665129,
			0.821376,
			0.505882,
			0.675663,
			0.537024,
			0.737024,
			0.631373,
			0.57847,
			0.396155,
			0.645982,
			0.756863,
			0.492349,
			0.223914,
			0.547559,
			0.882353,
			0.375548,
			0.096886,
			0.423299,
			1,
			0.25098,
			0,
			0.294118
		]
	},
	{
		ColorSpace: "Lab",
		Name: "GYPi",
		RGBPoints: [
			-1,
			0.152941,
			0.392157,
			0.098039,
			-0.87451,
			0.246444,
			0.505344,
			0.117724,
			-0.74902,
			0.351942,
			0.614533,
			0.161399,
			-0.623529,
			0.474971,
			0.717878,
			0.240138,
			-0.498039,
			0.611995,
			0.811226,
			0.392849,
			-0.372549,
			0.746328,
			0.893118,
			0.565321,
			-0.247059,
			0.859516,
			0.94233,
			0.747405,
			-0.121569,
			0.928105,
			0.96386,
			0.875663,
			0.00392157,
			0.969089,
			0.966859,
			0.968012,
			0.129412,
			0.983852,
			0.910265,
			0.948328,
			0.254902,
			0.979239,
			0.833218,
			0.914648,
			0.380392,
			0.949712,
			0.729873,
			0.862976,
			0.505882,
			0.905652,
			0.58293,
			0.763552,
			0.631373,
			0.85521,
			0.410073,
			0.652211,
			0.756863,
			0.793695,
			0.183699,
			0.531642,
			0.882353,
			0.683737,
			0.063899,
			0.420761,
			1,
			0.556863,
			0.003922,
			0.321569
		]
	},
	{
		ColorSpace: "Lab",
		Name: "GnYlRd",
		RGBPoints: [
			-1,
			0,
			0.407843,
			0.215686,
			-0.87451,
			0.063975,
			0.525952,
			0.277201,
			-0.74902,
			0.177932,
			0.633064,
			0.332718,
			-0.623529,
			0.364937,
			0.724106,
			0.379469,
			-0.498039,
			0.527951,
			0.797155,
			0.40223,
			-0.372549,
			0.678431,
			0.862822,
			0.433449,
			-0.247059,
			0.803922,
			0.916955,
			0.514648,
			-0.121569,
			0.909419,
			0.961861,
			0.625067,
			0.00392157,
			0.999923,
			0.997616,
			0.745021,
			0.129412,
			0.997463,
			0.921338,
			0.61707,
			0.254902,
			0.995002,
			0.824606,
			0.499885,
			0.380392,
			0.992541,
			0.701576,
			0.39654,
			0.505882,
			0.973472,
			0.547405,
			0.318108,
			0.631373,
			0.939023,
			0.389927,
			0.245521,
			0.756863,
			0.867666,
			0.239831,
			0.176624,
			0.882353,
			0.762399,
			0.110727,
			0.151326,
			1,
			0.647059,
			0,
			0.14902
		]
	},
	{
		ColorSpace: "Lab",
		Name: "GBBr",
		RGBPoints: [
			-1,
			0,
			0.235294,
			0.188235,
			-0.87451,
			0.002461,
			0.338639,
			0.301423,
			-0.74902,
			0.055902,
			0.448981,
			0.417609,
			-0.623529,
			0.183852,
			0.56955,
			0.538178,
			-0.498039,
			0.357785,
			0.700115,
			0.660746,
			-0.372549,
			0.540177,
			0.819531,
			0.77624,
			-0.247059,
			0.714879,
			0.890888,
			0.864821,
			-0.121569,
			0.851134,
			0.934564,
			0.922645,
			0.00392157,
			0.960861,
			0.959785,
			0.95694,
			0.129412,
			0.963322,
			0.927797,
			0.83391,
			0.254902,
			0.939946,
			0.868897,
			0.68935,
			0.380392,
			0.883353,
			0.775394,
			0.517109,
			0.505882,
			0.808074,
			0.625836,
			0.324106,
			0.631373,
			0.717647,
			0.476355,
			0.15494,
			0.756863,
			0.592157,
			0.358247,
			0.06882,
			0.882353,
			0.458593,
			0.26436,
			0.031142,
			1,
			0.329412,
			0.188235,
			0.019608
		]
	},
	{
		ColorSpace: "Lab",
		Name: "PuOr",
		RGBPoints: [
			-1,
			0.498039,
			0.231373,
			0.031373,
			-0.87451,
			0.62599,
			0.30273,
			0.026451,
			-0.74902,
			0.746943,
			0.387082,
			0.037524,
			-0.623529,
			0.85767,
			0.490427,
			0.071972,
			-0.498039,
			0.936409,
			0.617762,
			0.236371,
			-0.372549,
			0.992695,
			0.743099,
			0.43291,
			-0.247059,
			0.995156,
			0.841523,
			0.63714,
			-0.121569,
			0.985313,
			0.913802,
			0.813687,
			0.00392157,
			0.966244,
			0.966398,
			0.967705,
			0.129412,
			0.889965,
			0.89504,
			0.938178,
			0.254902,
			0.806151,
			0.804306,
			0.894656,
			0.380392,
			0.712649,
			0.688658,
			0.833141,
			0.505882,
			0.594233,
			0.554325,
			0.744637,
			0.631373,
			0.474894,
			0.404229,
			0.652364,
			0.756863,
			0.366628,
			0.217224,
			0.563783,
			0.882353,
			0.266436,
			0.089965,
			0.434833,
			1,
			0.176471,
			0,
			0.294118
		]
	},
	{
		ColorSpace: "Lab",
		Name: "PRGn",
		RGBPoints: [
			-1,
			0.25098,
			0,
			0.294118,
			-0.87451,
			0.383852,
			0.103345,
			0.431911,
			-0.74902,
			0.497732,
			0.234679,
			0.55371,
			-0.623529,
			0.583852,
			0.40692,
			0.652134,
			-0.498039,
			0.681968,
			0.545175,
			0.742561,
			-0.372549,
			0.7807,
			0.672357,
			0.825221,
			-0.247059,
			0.871742,
			0.788005,
			0.886736,
			-0.121569,
			0.930488,
			0.885198,
			0.932872,
			0.00392157,
			0.966321,
			0.968089,
			0.965859,
			0.129412,
			0.892503,
			0.950865,
			0.877278,
			0.254902,
			0.796078,
			0.91857,
			0.772549,
			0.380392,
			0.670588,
			0.866897,
			0.647059,
			0.505882,
			0.493195,
			0.765398,
			0.496655,
			0.631373,
			0.314187,
			0.649135,
			0.354556,
			0.756863,
			0.15917,
			0.516263,
			0.251211,
			0.882353,
			0.062284,
			0.386621,
			0.170473,
			1,
			0,
			0.266667,
			0.105882
		]
	},
	{
		ColorSpace: "Lab",
		Name: "PiYG",
		RGBPoints: [
			-1,
			0.556863,
			0.003922,
			0.321569,
			-0.87451,
			0.692195,
			0.067897,
			0.427374,
			-0.74902,
			0.797539,
			0.197847,
			0.539177,
			-0.623529,
			0.859054,
			0.424221,
			0.659746,
			-0.498039,
			0.908574,
			0.592618,
			0.770319,
			-0.372549,
			0.951557,
			0.736332,
			0.866205,
			-0.247059,
			0.981084,
			0.839677,
			0.917878,
			-0.121569,
			0.98293,
			0.913802,
			0.949558,
			0.00392157,
			0.96732,
			0.968474,
			0.965629,
			0.129412,
			0.92549,
			0.963552,
			0.869666,
			0.254902,
			0.852441,
			0.939254,
			0.736025,
			0.380392,
			0.739254,
			0.890042,
			0.553941,
			0.505882,
			0.60323,
			0.805536,
			0.382238,
			0.631373,
			0.467282,
			0.711419,
			0.235217,
			0.756863,
			0.344252,
			0.608074,
			0.156478,
			0.882353,
			0.2406,
			0.49827,
			0.116494,
			1,
			0.152941,
			0.392157,
			0.098039
		]
	},
	{
		ColorSpace: "Lab",
		Name: "OrPu",
		RGBPoints: [
			-1,
			0.176471,
			0,
			0.294118,
			-0.87451,
			0.272434,
			0.095963,
			0.444214,
			-0.74902,
			0.373395,
			0.228912,
			0.56932,
			-0.623529,
			0.481661,
			0.415917,
			0.657901,
			-0.498039,
			0.601922,
			0.562937,
			0.750481,
			-0.372549,
			0.718493,
			0.695886,
			0.836986,
			-0.247059,
			0.811995,
			0.811534,
			0.898501,
			-0.121569,
			0.894733,
			0.8995,
			0.940023,
			0.00392157,
			0.969166,
			0.966859,
			0.963629,
			0.129412,
			0.98639,
			0.910265,
			0.803691,
			0.254902,
			0.995002,
			0.835371,
			0.624375,
			0.380392,
			0.992541,
			0.736947,
			0.420146,
			0.505882,
			0.931949,
			0.609458,
			0.224221,
			0.631373,
			0.85075,
			0.483968,
			0.069819,
			0.756863,
			0.740023,
			0.380623,
			0.035371,
			0.882353,
			0.617993,
			0.29827,
			0.026759,
			1,
			0.498039,
			0.231373,
			0.031373
		]
	},
	{
		ColorSpace: "Lab",
		Name: "BrBG",
		RGBPoints: [
			-1,
			0.329412,
			0.188235,
			0.019608,
			-0.87451,
			0.467205,
			0.269435,
			0.031911,
			-0.74902,
			0.6,
			0.365629,
			0.074202,
			-0.623529,
			0.72549,
			0.483737,
			0.160323,
			-0.498039,
			0.812995,
			0.635832,
			0.336409,
			-0.372549,
			0.88689,
			0.781238,
			0.527874,
			-0.247059,
			0.943483,
			0.87474,
			0.700115,
			-0.121569,
			0.963168,
			0.929796,
			0.841599,
			0.00392157,
			0.957247,
			0.959938,
			0.959554,
			0.129412,
			0.84406,
			0.932872,
			0.920185,
			0.254902,
			0.70396,
			0.886428,
			0.859285,
			0.380392,
			0.529258,
			0.815071,
			0.770704,
			0.505882,
			0.346251,
			0.691811,
			0.653057,
			0.631373,
			0.175855,
			0.562015,
			0.530642,
			0.756863,
			0.047905,
			0.441446,
			0.410073,
			0.882353,
			0.002307,
			0.33218,
			0.294348,
			1,
			0,
			0.235294,
			0.188235
		]
	},
	{
		ColorSpace: "Lab",
		Name: "GyRd",
		RGBPoints: [
			-1,
			0.101961,
			0.101961,
			0.101961,
			-0.87451,
			0.227451,
			0.227451,
			0.227451,
			-0.74902,
			0.359939,
			0.359939,
			0.359939,
			-0.623529,
			0.502653,
			0.502653,
			0.502653,
			-0.498039,
			0.631373,
			0.631373,
			0.631373,
			-0.372549,
			0.749865,
			0.749865,
			0.749865,
			-0.247059,
			0.843368,
			0.843368,
			0.843368,
			-0.121569,
			0.926105,
			0.926105,
			0.926105,
			0.00392157,
			0.999846,
			0.997232,
			0.995694,
			0.129412,
			0.994925,
			0.908651,
			0.857901,
			0.254902,
			0.982468,
			0.800692,
			0.706113,
			0.380392,
			0.960323,
			0.66782,
			0.536332,
			0.505882,
			0.894579,
			0.503806,
			0.399769,
			0.631373,
			0.81707,
			0.33218,
			0.281046,
			0.756863,
			0.728489,
			0.155017,
			0.197386,
			0.882353,
			0.576932,
			0.055363,
			0.14925,
			1,
			0.403922,
			0,
			0.121569
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_divHi_purpleGreen",
		RGBPoints: [
			-1,
			0.297553,
			0,
			0.489074,
			-0.87451,
			0.40259,
			0.151146,
			0.567754,
			-0.74902,
			0.516038,
			0.284843,
			0.658231,
			-0.623529,
			0.629783,
			0.423646,
			0.750938,
			-0.498039,
			0.735198,
			0.563697,
			0.835956,
			-0.372549,
			0.82408,
			0.695541,
			0.903582,
			-0.247059,
			0.889091,
			0.807454,
			0.944862,
			-0.121569,
			0.92334,
			0.886917,
			0.951839,
			0.00392157,
			0.921045,
			0.921084,
			0.921003,
			0.129412,
			0.877324,
			0.907455,
			0.845381,
			0.254902,
			0.797649,
			0.849713,
			0.734695,
			0.380392,
			0.691646,
			0.75964,
			0.600532,
			0.505882,
			0.568981,
			0.649159,
			0.453807,
			0.631373,
			0.438945,
			0.529756,
			0.304259,
			0.756863,
			0.30973,
			0.412001,
			0.158303,
			0.882353,
			0.187078,
			0.305111,
			0.00251458,
			1,
			0.101655,
			0.220836,
			0
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_divHi_purpleGreen_dim",
		RGBPoints: [
			-1,
			0.404088,
			0.131038,
			0.592767,
			-0.87451,
			0.486469,
			0.230957,
			0.651243,
			-0.74902,
			0.575165,
			0.339335,
			0.717723,
			-0.623529,
			0.662741,
			0.454332,
			0.784263,
			-0.498039,
			0.742071,
			0.570213,
			0.842918,
			-0.372549,
			0.806935,
			0.678992,
			0.886227,
			-0.247059,
			0.852219,
			0.771315,
			0.90763,
			-0.121569,
			0.873345,
			0.837327,
			0.901572,
			0.00392157,
			0.866783,
			0.86682,
			0.866745,
			0.129412,
			0.82839,
			0.858225,
			0.796812,
			0.254902,
			0.762578,
			0.814287,
			0.700202,
			0.380392,
			0.676429,
			0.744229,
			0.585735,
			0.505882,
			0.577033,
			0.65732,
			0.461526,
			0.631373,
			0.47128,
			0.562476,
			0.33476,
			0.756863,
			0.365461,
			0.467957,
			0.21076,
			0.882353,
			0.264758,
			0.381138,
			0.0878313,
			1,
			0.182591,
			0.312249,
			0
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_divLow_icePeach",
		RGBPoints: [
			-1,
			0.480048,
			0.817441,
			0.998056,
			-0.87451,
			0.425898,
			0.726921,
			0.883187,
			-0.74902,
			0.366682,
			0.629445,
			0.761936,
			-0.623529,
			0.308756,
			0.531002,
			0.640217,
			-0.498039,
			0.258021,
			0.43705,
			0.523433,
			-0.372549,
			0.219244,
			0.352381,
			0.416348,
			-0.247059,
			0.195127,
			0.281032,
			0.322979,
			-0.121569,
			0.186286,
			0.22627,
			0.246525,
			0.00392157,
			0.192352,
			0.19236,
			0.192364,
			0.129412,
			0.255927,
			0.214469,
			0.191756,
			0.254902,
			0.340459,
			0.254426,
			0.206666,
			0.380392,
			0.444655,
			0.309315,
			0.234029,
			0.505882,
			0.565353,
			0.376004,
			0.270969,
			0.631373,
			0.697917,
			0.450748,
			0.314293,
			0.756863,
			0.836657,
			0.529064,
			0.360227,
			0.882353,
			0.972695,
			0.614884,
			0.413123,
			1,
			1,
			0.705904,
			0.472699
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_divLow_purpleGreen",
		RGBPoints: [
			-1,
			0.956034,
			0.666487,
			0.952663,
			-0.87451,
			0.874457,
			0.572698,
			0.936352,
			-0.74902,
			0.753465,
			0.488253,
			0.909063,
			-0.623529,
			0.63309,
			0.413507,
			0.763833,
			-0.498039,
			0.514491,
			0.345878,
			0.620015,
			-0.372549,
			0.405008,
			0.288141,
			0.484376,
			-0.247059,
			0.311388,
			0.241986,
			0.363556,
			-0.121569,
			0.238722,
			0.209044,
			0.263449,
			0.00392157,
			0.192352,
			0.192366,
			0.192362,
			0.129412,
			0.200379,
			0.233201,
			0.168618,
			0.254902,
			0.230151,
			0.291737,
			0.165227,
			0.380392,
			0.279481,
			0.366076,
			0.178607,
			0.505882,
			0.344927,
			0.453267,
			0.205703,
			0.631373,
			0.421554,
			0.549449,
			0.242643,
			0.756863,
			0.503334,
			0.649999,
			0.284377,
			0.882353,
			0.583497,
			0.749672,
			0.324969,
			1,
			0.650705,
			0.837228,
			0.356264
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Haze_green",
		RGBPoints: [
			-1,
			1,
			0.835294,
			0.886275,
			-0.87451,
			0.937255,
			0.756863,
			0.870443,
			-0.74902,
			0.875817,
			0.666376,
			0.857807,
			-0.623529,
			0.778359,
			0.583007,
			0.808134,
			-0.498039,
			0.676253,
			0.494118,
			0.745098,
			-0.372549,
			0.561365,
			0.390123,
			0.682353,
			-0.247059,
			0.438344,
			0.262745,
			0.621496,
			-0.121569,
			0.321133,
			0.141031,
			0.558751,
			0.00392157,
			0.203922,
			0.0217865,
			0.495861,
			0.129412,
			0.265505,
			0.129412,
			0.433261,
			0.254902,
			0.311692,
			0.255338,
			0.37008,
			0.380392,
			0.356282,
			0.377342,
			0.310821,
			0.505882,
			0.39971,
			0.488889,
			0.258243,
			0.631373,
			0.442556,
			0.604357,
			0.205519,
			0.756863,
			0.48671,
			0.71968,
			0.152941,
			0.882353,
			0.529847,
			0.830356,
			0.100944,
			1,
			0.572549,
			0.933333,
			0.054902
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Haze_lime",
		RGBPoints: [
			-1,
			0.704034,
			0.784196,
			1,
			-0.87451,
			0.633111,
			0.691418,
			0.956078,
			-0.74902,
			0.564021,
			0.600606,
			0.912157,
			-0.623529,
			0.496827,
			0.51189,
			0.868235,
			-0.498039,
			0.43157,
			0.425416,
			0.824314,
			-0.372549,
			0.368248,
			0.341347,
			0.780392,
			-0.247059,
			0.306767,
			0.259855,
			0.736471,
			-0.121569,
			0.246862,
			0.181069,
			0.692549,
			0.00392157,
			0.191619,
			0.109542,
			0.648627,
			0.129412,
			0.257404,
			0.194031,
			0.604706,
			0.254902,
			0.321794,
			0.278775,
			0.560784,
			0.380392,
			0.387909,
			0.364617,
			0.516863,
			0.505882,
			0.456569,
			0.451881,
			0.472941,
			0.631373,
			0.527424,
			0.540773,
			0.42902,
			0.756863,
			0.599759,
			0.631427,
			0.385098,
			0.882353,
			0.673065,
			0.723898,
			0.341176,
			1,
			0.742751,
			0.812252,
			0.3
		]
	},
	{
		ColorSpace: "RGB",
		Name: "Haze",
		RGBPoints: [
			-1,
			1,
			0.835294,
			0.996078,
			-0.00392157,
			0.023529,
			0.141176,
			0.498039,
			0.00392157,
			0.015686,
			0.137255,
			0.494118,
			1,
			0.984314,
			0.764706,
			0
		]
	},
	{
		ColorSpace: "Lab",
		Name: "Haze_cyan",
		RGBPoints: [
			-1,
			0.956863,
			1,
			0.835294,
			-0.87451,
			0.933188,
			0.921714,
			0.760784,
			-0.74902,
			0.870588,
			0.803486,
			0.671605,
			-0.623529,
			0.807843,
			0.684096,
			0.583297,
			-0.498039,
			0.745098,
			0.569208,
			0.494118,
			-0.372549,
			0.682353,
			0.437763,
			0.390123,
			-0.247059,
			0.621496,
			0.288163,
			0.262745,
			-0.121569,
			0.558751,
			0.144517,
			0.141031,
			0.00392157,
			0.495861,
			0.0217865,
			0.0413943,
			0.129412,
			0.433261,
			0.137255,
			0.129412,
			0.254902,
			0.37008,
			0.263181,
			0.255338,
			0.380392,
			0.306318,
			0.381845,
			0.372694,
			0.505882,
			0.243137,
			0.503994,
			0.494263,
			0.631373,
			0.180392,
			0.629484,
			0.619753,
			0.756863,
			0.117647,
			0.754975,
			0.747131,
			0.882353,
			0.054902,
			0.876398,
			0.866812,
			1,
			0,
			0.988235,
			0.976471
		]
	},
	{
		ColorSpace: "Lab",
		Name: "nic_Edge",
		RGBPoints: [
			-1,
			0.191208,
			0.191208,
			0.191208,
			-0.87451,
			0.239484,
			0.00545035,
			0.614821,
			-0.74902,
			0.220593,
			0.0617459,
			0.863547,
			-0.623529,
			0.17509,
			0.278988,
			0.97794,
			-0.498039,
			0.143526,
			0.576069,
			0.998553,
			-0.372549,
			0.166456,
			0.871883,
			0.96594,
			-0.247059,
			0.376202,
			0.993555,
			0.981833,
			-0.121569,
			0.681996,
			0.991297,
			0.999239,
			0.00392157,
			0.954172,
			0.952734,
			0.94374,
			0.129412,
			0.999735,
			0.99301,
			0.662896,
			0.254902,
			0.979399,
			0.991466,
			0.357973,
			0.380392,
			0.968771,
			0.854967,
			0.162659,
			0.505882,
			0.999245,
			0.556697,
			0.144323,
			0.631373,
			0.973959,
			0.26223,
			0.177946,
			0.756863,
			0.852358,
			0.0526707,
			0.222974,
			0.882353,
			0.593889,
			0.00912724,
			0.238855,
			1,
			0.191208,
			0.191208,
			0.191208
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_iceFire_H",
		RGBPoints: [
			-1,
			4.05432e-7,
			0,
			0.00000590122,
			-0.87451,
			0,
			0.120401,
			0.302675,
			-0.74902,
			0,
			0.216583,
			0.524574,
			-0.623529,
			0.0552475,
			0.345025,
			0.6595,
			-0.498039,
			0.128047,
			0.492588,
			0.720288,
			-0.372549,
			0.188955,
			0.641309,
			0.792092,
			-0.247059,
			0.327673,
			0.784935,
			0.873434,
			-0.121569,
			0.60824,
			0.892164,
			0.935547,
			0.00392157,
			0.881371,
			0.912178,
			0.818099,
			0.129412,
			0.951407,
			0.835621,
			0.449279,
			0.254902,
			0.904481,
			0.690489,
			0,
			0.380392,
			0.85407,
			0.510864,
			0,
			0.505882,
			0.777093,
			0.33018,
			0.00088199,
			0.631373,
			0.672862,
			0.139087,
			0.00269398,
			0.756863,
			0.508815,
			0,
			0,
			0.882353,
			0.299417,
			0.000366289,
			0.000547829,
			1,
			0.0157519,
			0.00332021,
			4.55569e-8
		]
	},
	{
		ColorSpace: "Lab",
		Name: "erdc_iceFire_L",
		RGBPoints: [
			-1,
			0.870485,
			0.913768,
			0.832905,
			-0.87451,
			0.586919,
			0.887865,
			0.934003,
			-0.74902,
			0.31583,
			0.776442,
			0.867858,
			-0.623529,
			0.18302,
			0.632034,
			0.787722,
			-0.498039,
			0.117909,
			0.484134,
			0.713825,
			-0.372549,
			0.0507239,
			0.335979,
			0.654741,
			-0.247059,
			0,
			0.209874,
			0.511832,
			-0.121569,
			0,
			0.114689,
			0.28935,
			0.00392157,
			0.0157519,
			0.00332021,
			4.55569e-8,
			0.129412,
			0.312914,
			0,
			0,
			0.254902,
			0.520865,
			0,
			0,
			0.380392,
			0.680105,
			0.15255,
			0.0025996,
			0.505882,
			0.785109,
			0.339479,
			0.000797922,
			0.631373,
			0.857354,
			0.522494,
			0,
			0.756863,
			0.910974,
			0.699774,
			0,
			0.882353,
			0.951921,
			0.842817,
			0.478545,
			1,
			0.881371,
			0.912178,
			0.818099
		]
	},
	{
		ColorSpace: "RGB",
		Name: "hsv",
		RGBPoints: [
			-1,
			1,
			0,
			0,
			-0.666666,
			1,
			0,
			1,
			-0.333333,
			0,
			0,
			1,
			0,
			0,
			1,
			1,
			0.33333,
			0,
			1,
			0,
			0.66666,
			1,
			1,
			0,
			1,
			1,
			0,
			0
		]
	},
	{
		ColorSpace: "Lab",
		Name: "hue_L60",
		RGBPoints: [
			-1,
			0.964784,
			0.400592,
			0.349549,
			-0.87451,
			0.964915,
			0.372498,
			0.53785,
			-0.74902,
			0.892353,
			0.401039,
			0.759569,
			-0.623529,
			0.79263,
			0.446956,
			0.903017,
			-0.498039,
			0.682208,
			0.49954,
			0.966673,
			-0.372549,
			0.56392,
			0.553082,
			0.968836,
			-0.247059,
			0.442031,
			0.606396,
			0.901601,
			-0.121569,
			0.305499,
			0.65701,
			0.765784,
			0.00392157,
			0.197251,
			0.687914,
			0.620914,
			0.129412,
			0.193882,
			0.701887,
			0.472654,
			0.254902,
			0.249866,
			0.706123,
			0.320005,
			0.380392,
			0.35132,
			0.697417,
			0.202919,
			0.505882,
			0.498097,
			0.669467,
			0.125232,
			0.631373,
			0.637477,
			0.626239,
			0.107431,
			0.756863,
			0.762115,
			0.56872,
			0.155812,
			0.882353,
			0.889434,
			0.481116,
			0.240445,
			1,
			0.964784,
			0.400592,
			0.349549
		]
	},
	{
		IndexedColors: [
			0,
			0,
			0,
			0.8941176470588236,
			0.1019607843137255,
			0.1098039215686274,
			0.2156862745098039,
			0.4941176470588236,
			0.7215686274509804,
			0.3019607843137255,
			0.6862745098039216,
			0.2901960784313726,
			0.596078431372549,
			0.3058823529411765,
			0.6392156862745098,
			1,
			0.4980392156862745,
			0,
			0.6509803921568628,
			0.3372549019607843,
			0.1568627450980392
		],
		Name: "Spectrum",
		NanColor: [
			0.6509803921568628,
			0.3372549019607843,
			0.1568627450980392
		]
	},
	{
		IndexedColors: [
			0.4745098039215686,
			0.09019607843137255,
			0.09019607843137255,
			0.7098039215686275,
			0.00392156862745098,
			0.00392156862745098,
			0.9372549019607843,
			0.2784313725490196,
			0.09803921568627451,
			0.9764705882352941,
			0.5137254901960784,
			0.1411764705882353,
			1,
			0.7058823529411765,
			0,
			1,
			0.8980392156862745,
			0.02352941176470588
		],
		Name: "Warm",
		NanColor: [
			1,
			0.8980392156862745,
			0.02352941176470588
		]
	},
	{
		IndexedColors: [
			0.4588235294117647,
			0.6941176470588235,
			0.00392156862745098,
			0.3450980392156863,
			0.5019607843137255,
			0.1607843137254902,
			0.3137254901960784,
			0.8431372549019608,
			0.7490196078431373,
			0.1098039215686274,
			0.5843137254901961,
			0.803921568627451,
			0.2313725490196079,
			0.407843137254902,
			0.6705882352941176,
			0.6039215686274509,
			0.407843137254902,
			1,
			0.3725490196078431,
			0.2,
			0.5019607843137255
		],
		Name: "Cool",
		NanColor: [
			0.3725490196078431,
			0.2,
			0.5019607843137255
		]
	},
	{
		IndexedColors: [
			0.2313725490196079,
			0.407843137254902,
			0.6705882352941176,
			0.1098039215686274,
			0.5843137254901961,
			0.803921568627451,
			0.3058823529411765,
			0.8509803921568627,
			0.9176470588235294,
			0.4509803921568628,
			0.6039215686274509,
			0.8352941176470589,
			0.2588235294117647,
			0.2392156862745098,
			0.6627450980392157,
			0.3137254901960784,
			0.3294117647058823,
			0.5294117647058824,
			0.06274509803921569,
			0.1647058823529412,
			0.3215686274509804
		],
		Name: "Blues",
		NanColor: [
			0.06274509803921569,
			0.1647058823529412,
			0.3215686274509804
		]
	},
	{
		IndexedColors: [
			0.1098039215686274,
			0.5843137254901961,
			0.803921568627451,
			0.2313725490196079,
			0.407843137254902,
			0.6705882352941176,
			0.4,
			0.2431372549019608,
			0.7176470588235294,
			0.6352941176470588,
			0.3294117647058823,
			0.8117647058823529,
			0.8705882352941177,
			0.3803921568627451,
			0.807843137254902,
			0.8627450980392157,
			0.3803921568627451,
			0.5843137254901961,
			0.2392156862745098,
			0.06274509803921569,
			0.3215686274509804
		],
		Name: "Wild Flower",
		NanColor: [
			0.2392156862745098,
			0.06274509803921569,
			0.3215686274509804
		]
	},
	{
		IndexedColors: [
			0.396078431372549,
			0.4862745098039216,
			0.2156862745098039,
			0.4588235294117647,
			0.6941176470588235,
			0.00392156862745098,
			0.6980392156862745,
			0.7294117647058823,
			0.1882352941176471,
			1,
			0.8980392156862745,
			0.02352941176470588,
			1,
			0.7058823529411765,
			0,
			0.9764705882352941,
			0.5137254901960784,
			0.1411764705882353
		],
		Name: "Citrus",
		NanColor: [
			0.9764705882352941,
			0.5137254901960784,
			0.1411764705882353
		]
	},
	{
		IndexedColors: [
			0.4980392156862745,
			0.2313725490196079,
			0.03137254901960784,
			0.7019607843137254,
			0.3450980392156863,
			0.02352941176470588,
			0.8784313725490196,
			0.5098039215686274,
			0.0784313725490196,
			0.9921568627450981,
			0.7215686274509804,
			0.3882352941176471,
			0.996078431372549,
			0.8784313725490196,
			0.7137254901960784,
			0.9686274509803922,
			0.9686274509803922,
			0.9686274509803922,
			0.8470588235294118,
			0.8549019607843137,
			0.9215686274509803,
			0.6980392156862745,
			0.6705882352941176,
			0.8235294117647058,
			0.5019607843137255,
			0.4509803921568628,
			0.6745098039215687,
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333,
			0.1764705882352941,
			0,
			0.2941176470588235
		],
		Name: "Brewer Diverging Purple-Orange (11)",
		NanColor: [
			0.1764705882352941,
			0,
			0.2941176470588235
		]
	},
	{
		IndexedColors: [
			0.4980392156862745,
			0.2313725490196079,
			0.03137254901960784,
			0.7019607843137254,
			0.3450980392156863,
			0.02352941176470588,
			0.8784313725490196,
			0.5098039215686274,
			0.0784313725490196,
			0.9921568627450981,
			0.7215686274509804,
			0.3882352941176471,
			0.996078431372549,
			0.8784313725490196,
			0.7137254901960784,
			0.8470588235294118,
			0.8549019607843137,
			0.9215686274509803,
			0.6980392156862745,
			0.6705882352941176,
			0.8235294117647058,
			0.5019607843137255,
			0.4509803921568628,
			0.6745098039215687,
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333,
			0.1764705882352941,
			0,
			0.2941176470588235
		],
		Name: "Brewer Diverging Purple-Orange (10)",
		NanColor: [
			0.1764705882352941,
			0,
			0.2941176470588235
		]
	},
	{
		IndexedColors: [
			0.7019607843137254,
			0.3450980392156863,
			0.02352941176470588,
			0.8784313725490196,
			0.5098039215686274,
			0.0784313725490196,
			0.9921568627450981,
			0.7215686274509804,
			0.3882352941176471,
			0.996078431372549,
			0.8784313725490196,
			0.7137254901960784,
			0.9686274509803922,
			0.9686274509803922,
			0.9686274509803922,
			0.8470588235294118,
			0.8549019607843137,
			0.9215686274509803,
			0.6980392156862745,
			0.6705882352941176,
			0.8235294117647058,
			0.5019607843137255,
			0.4509803921568628,
			0.6745098039215687,
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333
		],
		Name: "Brewer Diverging Purple-Orange (9)",
		NanColor: [
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333
		]
	},
	{
		IndexedColors: [
			0.7019607843137254,
			0.3450980392156863,
			0.02352941176470588,
			0.8784313725490196,
			0.5098039215686274,
			0.0784313725490196,
			0.9921568627450981,
			0.7215686274509804,
			0.3882352941176471,
			0.996078431372549,
			0.8784313725490196,
			0.7137254901960784,
			0.8470588235294118,
			0.8549019607843137,
			0.9215686274509803,
			0.6980392156862745,
			0.6705882352941176,
			0.8235294117647058,
			0.5019607843137255,
			0.4509803921568628,
			0.6745098039215687,
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333
		],
		Name: "Brewer Diverging Purple-Orange (8)",
		NanColor: [
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333
		]
	},
	{
		IndexedColors: [
			0.7019607843137254,
			0.3450980392156863,
			0.02352941176470588,
			0.9450980392156862,
			0.6392156862745098,
			0.2509803921568627,
			0.996078431372549,
			0.8784313725490196,
			0.7137254901960784,
			0.9686274509803922,
			0.9686274509803922,
			0.9686274509803922,
			0.8470588235294118,
			0.8549019607843137,
			0.9215686274509803,
			0.6,
			0.5568627450980392,
			0.7647058823529411,
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333
		],
		Name: "Brewer Diverging Purple-Orange (7)",
		NanColor: [
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333
		]
	},
	{
		IndexedColors: [
			0.7019607843137254,
			0.3450980392156863,
			0.02352941176470588,
			0.9450980392156862,
			0.6392156862745098,
			0.2509803921568627,
			0.996078431372549,
			0.8784313725490196,
			0.7137254901960784,
			0.8470588235294118,
			0.8549019607843137,
			0.9215686274509803,
			0.6,
			0.5568627450980392,
			0.7647058823529411,
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333
		],
		Name: "Brewer Diverging Purple-Orange (6)",
		NanColor: [
			0.3294117647058823,
			0.1529411764705882,
			0.5333333333333333
		]
	},
	{
		IndexedColors: [
			0.9019607843137255,
			0.3803921568627451,
			0.00392156862745098,
			0.9921568627450981,
			0.7215686274509804,
			0.3882352941176471,
			0.9686274509803922,
			0.9686274509803922,
			0.9686274509803922,
			0.6980392156862745,
			0.6705882352941176,
			0.8235294117647058,
			0.3686274509803922,
			0.2352941176470588,
			0.6
		],
		Name: "Brewer Diverging Purple-Orange (5)",
		NanColor: [
			0.3686274509803922,
			0.2352941176470588,
			0.6
		]
	},
	{
		IndexedColors: [
			0.9019607843137255,
			0.3803921568627451,
			0.00392156862745098,
			0.9921568627450981,
			0.7215686274509804,
			0.3882352941176471,
			0.6980392156862745,
			0.6705882352941176,
			0.8235294117647058,
			0.3686274509803922,
			0.2352941176470588,
			0.6
		],
		Name: "Brewer Diverging Purple-Orange (4)",
		NanColor: [
			0.3686274509803922,
			0.2352941176470588,
			0.6
		]
	},
	{
		IndexedColors: [
			0.9450980392156862,
			0.6392156862745098,
			0.2509803921568627,
			0.9686274509803922,
			0.9686274509803922,
			0.9686274509803922,
			0.6,
			0.5568627450980392,
			0.7647058823529411
		],
		Name: "Brewer Diverging Purple-Orange (3)",
		NanColor: [
			0.6,
			0.5568627450980392,
			0.7647058823529411
		]
	},
	{
		IndexedColors: [
			0.6196078431372549,
			0.00392156862745098,
			0.2588235294117647,
			0.8352941176470589,
			0.2431372549019608,
			0.3098039215686275,
			0.9568627450980393,
			0.4274509803921568,
			0.2627450980392157,
			0.9921568627450981,
			0.6823529411764706,
			0.3803921568627451,
			0.996078431372549,
			0.8784313725490196,
			0.5450980392156862,
			1,
			1,
			0.7490196078431373,
			0.9019607843137255,
			0.9607843137254902,
			0.596078431372549,
			0.6705882352941176,
			0.8666666666666667,
			0.6431372549019608,
			0.4,
			0.7607843137254902,
			0.6470588235294118,
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353,
			0.3686274509803922,
			0.3098039215686275,
			0.6352941176470588
		],
		Name: "Brewer Diverging Spectral (11)",
		NanColor: [
			0.3686274509803922,
			0.3098039215686275,
			0.6352941176470588
		]
	},
	{
		IndexedColors: [
			0.6196078431372549,
			0.00392156862745098,
			0.2588235294117647,
			0.8352941176470589,
			0.2431372549019608,
			0.3098039215686275,
			0.9568627450980393,
			0.4274509803921568,
			0.2627450980392157,
			0.9921568627450981,
			0.6823529411764706,
			0.3803921568627451,
			0.996078431372549,
			0.8784313725490196,
			0.5450980392156862,
			0.9019607843137255,
			0.9607843137254902,
			0.596078431372549,
			0.6705882352941176,
			0.8666666666666667,
			0.6431372549019608,
			0.4,
			0.7607843137254902,
			0.6470588235294118,
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353,
			0.3686274509803922,
			0.3098039215686275,
			0.6352941176470588
		],
		Name: "Brewer Diverging Spectral (10)",
		NanColor: [
			0.3686274509803922,
			0.3098039215686275,
			0.6352941176470588
		]
	},
	{
		IndexedColors: [
			0.8352941176470589,
			0.2431372549019608,
			0.3098039215686275,
			0.9568627450980393,
			0.4274509803921568,
			0.2627450980392157,
			0.9921568627450981,
			0.6823529411764706,
			0.3803921568627451,
			0.996078431372549,
			0.8784313725490196,
			0.5450980392156862,
			1,
			1,
			0.7490196078431373,
			0.9019607843137255,
			0.9607843137254902,
			0.596078431372549,
			0.6705882352941176,
			0.8666666666666667,
			0.6431372549019608,
			0.4,
			0.7607843137254902,
			0.6470588235294118,
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353
		],
		Name: "Brewer Diverging Spectral (9)",
		NanColor: [
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353
		]
	},
	{
		IndexedColors: [
			0.8352941176470589,
			0.2431372549019608,
			0.3098039215686275,
			0.9568627450980393,
			0.4274509803921568,
			0.2627450980392157,
			0.9921568627450981,
			0.6823529411764706,
			0.3803921568627451,
			0.996078431372549,
			0.8784313725490196,
			0.5450980392156862,
			0.9019607843137255,
			0.9607843137254902,
			0.596078431372549,
			0.6705882352941176,
			0.8666666666666667,
			0.6431372549019608,
			0.4,
			0.7607843137254902,
			0.6470588235294118,
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353
		],
		Name: "Brewer Diverging Spectral (8)",
		NanColor: [
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353
		]
	},
	{
		IndexedColors: [
			0.8352941176470589,
			0.2431372549019608,
			0.3098039215686275,
			0.9882352941176471,
			0.5529411764705883,
			0.3490196078431372,
			0.996078431372549,
			0.8784313725490196,
			0.5450980392156862,
			1,
			1,
			0.7490196078431373,
			0.9019607843137255,
			0.9607843137254902,
			0.596078431372549,
			0.6,
			0.8352941176470589,
			0.5803921568627451,
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353
		],
		Name: "Brewer Diverging Spectral (7)",
		NanColor: [
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353
		]
	},
	{
		IndexedColors: [
			0.8352941176470589,
			0.2431372549019608,
			0.3098039215686275,
			0.9882352941176471,
			0.5529411764705883,
			0.3490196078431372,
			0.996078431372549,
			0.8784313725490196,
			0.5450980392156862,
			0.9019607843137255,
			0.9607843137254902,
			0.596078431372549,
			0.6,
			0.8352941176470589,
			0.5803921568627451,
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353
		],
		Name: "Brewer Diverging Spectral (6)",
		NanColor: [
			0.196078431372549,
			0.5333333333333333,
			0.7411764705882353
		]
	},
	{
		IndexedColors: [
			0.8431372549019608,
			0.09803921568627451,
			0.1098039215686274,
			0.9921568627450981,
			0.6823529411764706,
			0.3803921568627451,
			1,
			1,
			0.7490196078431373,
			0.6705882352941176,
			0.8666666666666667,
			0.6431372549019608,
			0.1686274509803922,
			0.5137254901960784,
			0.7294117647058823
		],
		Name: "Brewer Diverging Spectral (5)",
		NanColor: [
			0.1686274509803922,
			0.5137254901960784,
			0.7294117647058823
		]
	},
	{
		IndexedColors: [
			0.8431372549019608,
			0.09803921568627451,
			0.1098039215686274,
			0.9921568627450981,
			0.6823529411764706,
			0.3803921568627451,
			0.6705882352941176,
			0.8666666666666667,
			0.6431372549019608,
			0.1686274509803922,
			0.5137254901960784,
			0.7294117647058823
		],
		Name: "Brewer Diverging Spectral (4)",
		NanColor: [
			0.1686274509803922,
			0.5137254901960784,
			0.7294117647058823
		]
	},
	{
		IndexedColors: [
			0.9882352941176471,
			0.5529411764705883,
			0.3490196078431372,
			1,
			1,
			0.7490196078431373,
			0.6,
			0.8352941176470589,
			0.5803921568627451
		],
		Name: "Brewer Diverging Spectral (3)",
		NanColor: [
			0.6,
			0.8352941176470589,
			0.5803921568627451
		]
	},
	{
		IndexedColors: [
			0.3294117647058823,
			0.1882352941176471,
			0.0196078431372549,
			0.5490196078431373,
			0.3176470588235294,
			0.0392156862745098,
			0.7490196078431373,
			0.5058823529411764,
			0.1764705882352941,
			0.8745098039215686,
			0.7607843137254902,
			0.4901960784313725,
			0.9647058823529412,
			0.9098039215686274,
			0.7647058823529411,
			0.9607843137254902,
			0.9607843137254902,
			0.9607843137254902,
			0.7803921568627451,
			0.9176470588235294,
			0.8980392156862745,
			0.5019607843137255,
			0.803921568627451,
			0.7568627450980392,
			0.207843137254902,
			0.592156862745098,
			0.5607843137254902,
			0.00392156862745098,
			0.4,
			0.3686274509803922,
			0,
			0.2352941176470588,
			0.1882352941176471
		],
		Name: "Brewer Diverging Brown-Blue-Green (11)",
		NanColor: [
			0,
			0.2352941176470588,
			0.1882352941176471
		]
	},
	{
		IndexedColors: [
			0.3294117647058823,
			0.1882352941176471,
			0.0196078431372549,
			0.5490196078431373,
			0.3176470588235294,
			0.0392156862745098,
			0.7490196078431373,
			0.5058823529411764,
			0.1764705882352941,
			0.8745098039215686,
			0.7607843137254902,
			0.4901960784313725,
			0.9647058823529412,
			0.9098039215686274,
			0.7647058823529411,
			0.7803921568627451,
			0.9176470588235294,
			0.8980392156862745,
			0.5019607843137255,
			0.803921568627451,
			0.7568627450980392,
			0.207843137254902,
			0.592156862745098,
			0.5607843137254902,
			0.00392156862745098,
			0.4,
			0.3686274509803922,
			0,
			0.2352941176470588,
			0.1882352941176471
		],
		Name: "Brewer Diverging Brown-Blue-Green (10)",
		NanColor: [
			0,
			0.2352941176470588,
			0.1882352941176471
		]
	},
	{
		IndexedColors: [
			0.5490196078431373,
			0.3176470588235294,
			0.0392156862745098,
			0.7490196078431373,
			0.5058823529411764,
			0.1764705882352941,
			0.8745098039215686,
			0.7607843137254902,
			0.4901960784313725,
			0.9647058823529412,
			0.9098039215686274,
			0.7647058823529411,
			0.9607843137254902,
			0.9607843137254902,
			0.9607843137254902,
			0.7803921568627451,
			0.9176470588235294,
			0.8980392156862745,
			0.5019607843137255,
			0.803921568627451,
			0.7568627450980392,
			0.207843137254902,
			0.592156862745098,
			0.5607843137254902,
			0.00392156862745098,
			0.4,
			0.3686274509803922
		],
		Name: "Brewer Diverging Brown-Blue-Green (9)",
		NanColor: [
			0.00392156862745098,
			0.4,
			0.3686274509803922
		]
	},
	{
		IndexedColors: [
			0.5490196078431373,
			0.3176470588235294,
			0.0392156862745098,
			0.7490196078431373,
			0.5058823529411764,
			0.1764705882352941,
			0.8745098039215686,
			0.7607843137254902,
			0.4901960784313725,
			0.9647058823529412,
			0.9098039215686274,
			0.7647058823529411,
			0.7803921568627451,
			0.9176470588235294,
			0.8980392156862745,
			0.5019607843137255,
			0.803921568627451,
			0.7568627450980392,
			0.207843137254902,
			0.592156862745098,
			0.5607843137254902,
			0.00392156862745098,
			0.4,
			0.3686274509803922
		],
		Name: "Brewer Diverging Brown-Blue-Green (8)",
		NanColor: [
			0.00392156862745098,
			0.4,
			0.3686274509803922
		]
	},
	{
		IndexedColors: [
			0.5490196078431373,
			0.3176470588235294,
			0.0392156862745098,
			0.8470588235294118,
			0.7019607843137254,
			0.396078431372549,
			0.9647058823529412,
			0.9098039215686274,
			0.7647058823529411,
			0.9607843137254902,
			0.9607843137254902,
			0.9607843137254902,
			0.7803921568627451,
			0.9176470588235294,
			0.8980392156862745,
			0.3529411764705883,
			0.7058823529411765,
			0.6745098039215687,
			0.00392156862745098,
			0.4,
			0.3686274509803922
		],
		Name: "Brewer Diverging Brown-Blue-Green (7)",
		NanColor: [
			0.00392156862745098,
			0.4,
			0.3686274509803922
		]
	},
	{
		IndexedColors: [
			0.5490196078431373,
			0.3176470588235294,
			0.0392156862745098,
			0.8470588235294118,
			0.7019607843137254,
			0.396078431372549,
			0.9647058823529412,
			0.9098039215686274,
			0.7647058823529411,
			0.7803921568627451,
			0.9176470588235294,
			0.8980392156862745,
			0.3529411764705883,
			0.7058823529411765,
			0.6745098039215687,
			0.00392156862745098,
			0.4,
			0.3686274509803922
		],
		Name: "Brewer Diverging Brown-Blue-Green (6)",
		NanColor: [
			0.00392156862745098,
			0.4,
			0.3686274509803922
		]
	},
	{
		IndexedColors: [
			0.6509803921568628,
			0.3803921568627451,
			0.1019607843137255,
			0.8745098039215686,
			0.7607843137254902,
			0.4901960784313725,
			0.9607843137254902,
			0.9607843137254902,
			0.9607843137254902,
			0.5019607843137255,
			0.803921568627451,
			0.7568627450980392,
			0.00392156862745098,
			0.5215686274509804,
			0.4431372549019608
		],
		Name: "Brewer Diverging Brown-Blue-Green (5)",
		NanColor: [
			0.00392156862745098,
			0.5215686274509804,
			0.4431372549019608
		]
	},
	{
		IndexedColors: [
			0.6509803921568628,
			0.3803921568627451,
			0.1019607843137255,
			0.8745098039215686,
			0.7607843137254902,
			0.4901960784313725,
			0.5019607843137255,
			0.803921568627451,
			0.7568627450980392,
			0.00392156862745098,
			0.5215686274509804,
			0.4431372549019608
		],
		Name: "Brewer Diverging Brown-Blue-Green (4)",
		NanColor: [
			0.00392156862745098,
			0.5215686274509804,
			0.4431372549019608
		]
	},
	{
		IndexedColors: [
			0.8470588235294118,
			0.7019607843137254,
			0.396078431372549,
			0.9607843137254902,
			0.9607843137254902,
			0.9607843137254902,
			0.3529411764705883,
			0.7058823529411765,
			0.6745098039215687
		],
		Name: "Brewer Diverging Brown-Blue-Green (3)",
		NanColor: [
			0.3529411764705883,
			0.7058823529411765,
			0.6745098039215687
		]
	},
	{
		IndexedColors: [
			0.9686274509803922,
			0.9882352941176471,
			0.9921568627450981,
			0.8980392156862745,
			0.9607843137254902,
			0.9764705882352941,
			0.8,
			0.9254901960784314,
			0.9019607843137255,
			0.6,
			0.8470588235294118,
			0.788235294117647,
			0.4,
			0.7607843137254902,
			0.6431372549019608,
			0.2549019607843137,
			0.6823529411764706,
			0.4627450980392157,
			0.1372549019607843,
			0.5450980392156862,
			0.2705882352941176,
			0,
			0.4274509803921568,
			0.1725490196078431,
			0,
			0.2666666666666667,
			0.1058823529411765
		],
		Name: "Brewer Sequential Blue-Green (9)",
		NanColor: [
			0,
			0.2666666666666667,
			0.1058823529411765
		]
	},
	{
		IndexedColors: [
			0.9686274509803922,
			0.9882352941176471,
			0.9921568627450981,
			0.8980392156862745,
			0.9607843137254902,
			0.9764705882352941,
			0.8,
			0.9254901960784314,
			0.9019607843137255,
			0.6,
			0.8470588235294118,
			0.788235294117647,
			0.4,
			0.7607843137254902,
			0.6431372549019608,
			0.2549019607843137,
			0.6823529411764706,
			0.4627450980392157,
			0.1372549019607843,
			0.5450980392156862,
			0.2705882352941176,
			0,
			0.3450980392156863,
			0.1411764705882353
		],
		Name: "Brewer Sequential Blue-Green (8)",
		NanColor: [
			0,
			0.3450980392156863,
			0.1411764705882353
		]
	},
	{
		IndexedColors: [
			0.9294117647058824,
			0.9725490196078431,
			0.984313725490196,
			0.8,
			0.9254901960784314,
			0.9019607843137255,
			0.8,
			0.9254901960784314,
			0.9019607843137255,
			0.4,
			0.7607843137254902,
			0.6431372549019608,
			0.2549019607843137,
			0.6823529411764706,
			0.4627450980392157,
			0.1372549019607843,
			0.5450980392156862,
			0.2705882352941176,
			0,
			0.3450980392156863,
			0.1411764705882353
		],
		Name: "Brewer Sequential Blue-Green (7)",
		NanColor: [
			0,
			0.3450980392156863,
			0.1411764705882353
		]
	},
	{
		IndexedColors: [
			0.9294117647058824,
			0.9725490196078431,
			0.984313725490196,
			0.8,
			0.9254901960784314,
			0.9019607843137255,
			0.6,
			0.8470588235294118,
			0.788235294117647,
			0.4,
			0.7607843137254902,
			0.6431372549019608,
			0.1725490196078431,
			0.6352941176470588,
			0.3725490196078431,
			0,
			0.4274509803921568,
			0.1725490196078431
		],
		Name: "Brewer Sequential Blue-Green (6)",
		NanColor: [
			0,
			0.4274509803921568,
			0.1725490196078431
		]
	},
	{
		IndexedColors: [
			0.9294117647058824,
			0.9725490196078431,
			0.984313725490196,
			0.6980392156862745,
			0.8862745098039215,
			0.8862745098039215,
			0.4,
			0.7607843137254902,
			0.6431372549019608,
			0.1725490196078431,
			0.6352941176470588,
			0.3725490196078431,
			0,
			0.4274509803921568,
			0.1725490196078431
		],
		Name: "Brewer Sequential Blue-Green (5)",
		NanColor: [
			0,
			0.4274509803921568,
			0.1725490196078431
		]
	},
	{
		IndexedColors: [
			0.9294117647058824,
			0.9725490196078431,
			0.984313725490196,
			0.6980392156862745,
			0.8862745098039215,
			0.8862745098039215,
			0.4,
			0.7607843137254902,
			0.6431372549019608,
			0.1372549019607843,
			0.5450980392156862,
			0.2705882352941176
		],
		Name: "Brewer Sequential Blue-Green (4)",
		NanColor: [
			0.1372549019607843,
			0.5450980392156862,
			0.2705882352941176
		]
	},
	{
		IndexedColors: [
			0.8980392156862745,
			0.9607843137254902,
			0.9764705882352941,
			0.6,
			0.8470588235294118,
			0.788235294117647,
			0.1725490196078431,
			0.6352941176470588,
			0.3725490196078431
		],
		Name: "Brewer Sequential Blue-Green (3)",
		NanColor: [
			0.1725490196078431,
			0.6352941176470588,
			0.3725490196078431
		]
	},
	{
		IndexedColors: [
			1,
			1,
			0.8980392156862745,
			1,
			0.9686274509803922,
			0.7372549019607844,
			0.996078431372549,
			0.8901960784313725,
			0.5686274509803921,
			0.996078431372549,
			0.7686274509803922,
			0.3098039215686275,
			0.996078431372549,
			0.6,
			0.1607843137254902,
			0.9254901960784314,
			0.4392156862745098,
			0.0784313725490196,
			0.8,
			0.2980392156862745,
			0.00784313725490196,
			0.6,
			0.203921568627451,
			0.01568627450980392,
			0.4,
			0.1450980392156863,
			0.02352941176470588
		],
		Name: "Brewer Sequential Yellow-Orange-Brown (9)",
		NanColor: [
			0.4,
			0.1450980392156863,
			0.02352941176470588
		]
	},
	{
		IndexedColors: [
			1,
			1,
			0.8980392156862745,
			1,
			0.9686274509803922,
			0.7372549019607844,
			0.996078431372549,
			0.8901960784313725,
			0.5686274509803921,
			0.996078431372549,
			0.7686274509803922,
			0.3098039215686275,
			0.996078431372549,
			0.6,
			0.1607843137254902,
			0.9254901960784314,
			0.4392156862745098,
			0.0784313725490196,
			0.8,
			0.2980392156862745,
			0.00784313725490196,
			0.5490196078431373,
			0.1764705882352941,
			0.01568627450980392
		],
		Name: "Brewer Sequential Yellow-Orange-Brown (8)",
		NanColor: [
			0.5490196078431373,
			0.1764705882352941,
			0.01568627450980392
		]
	},
	{
		IndexedColors: [
			1,
			1,
			0.8313725490196079,
			0.996078431372549,
			0.8901960784313725,
			0.5686274509803921,
			0.996078431372549,
			0.7686274509803922,
			0.3098039215686275,
			0.996078431372549,
			0.6,
			0.1607843137254902,
			0.9254901960784314,
			0.4392156862745098,
			0.0784313725490196,
			0.8,
			0.2980392156862745,
			0.00784313725490196,
			0.5490196078431373,
			0.1764705882352941,
			0.01568627450980392
		],
		Name: "Brewer Sequential Yellow-Orange-Brown (7)",
		NanColor: [
			0.5490196078431373,
			0.1764705882352941,
			0.01568627450980392
		]
	},
	{
		IndexedColors: [
			1,
			1,
			0.8313725490196079,
			0.996078431372549,
			0.8901960784313725,
			0.5686274509803921,
			0.996078431372549,
			0.7686274509803922,
			0.3098039215686275,
			0.996078431372549,
			0.6,
			0.1607843137254902,
			0.8509803921568627,
			0.3725490196078431,
			0.05490196078431372,
			0.6,
			0.203921568627451,
			0.01568627450980392
		],
		Name: "Brewer Sequential Yellow-Orange-Brown (6)",
		NanColor: [
			0.6,
			0.203921568627451,
			0.01568627450980392
		]
	},
	{
		IndexedColors: [
			1,
			1,
			0.8313725490196079,
			0.996078431372549,
			0.8509803921568627,
			0.5568627450980392,
			0.996078431372549,
			0.6,
			0.1607843137254902,
			0.8509803921568627,
			0.3725490196078431,
			0.05490196078431372,
			0.6,
			0.203921568627451,
			0.01568627450980392
		],
		Name: "Brewer Sequential Yellow-Orange-Brown (5)",
		NanColor: [
			0.6,
			0.203921568627451,
			0.01568627450980392
		]
	},
	{
		IndexedColors: [
			1,
			1,
			0.8313725490196079,
			0.996078431372549,
			0.8509803921568627,
			0.5568627450980392,
			0.996078431372549,
			0.6,
			0.1607843137254902,
			0.8,
			0.2980392156862745,
			0.00784313725490196
		],
		Name: "Brewer Sequential Yellow-Orange-Brown (4)",
		NanColor: [
			0.8,
			0.2980392156862745,
			0.00784313725490196
		]
	},
	{
		IndexedColors: [
			1,
			0.9686274509803922,
			0.7372549019607844,
			0.996078431372549,
			0.7686274509803922,
			0.3098039215686275,
			0.8509803921568627,
			0.3725490196078431,
			0.05490196078431372
		],
		Name: "Brewer Sequential Yellow-Orange-Brown (3)",
		NanColor: [
			0.8509803921568627,
			0.3725490196078431,
			0.05490196078431372
		]
	},
	{
		IndexedColors: [
			0.9686274509803922,
			0.9882352941176471,
			0.9921568627450981,
			0.8784313725490196,
			0.9254901960784314,
			0.9568627450980393,
			0.7490196078431373,
			0.8274509803921568,
			0.9019607843137255,
			0.6196078431372549,
			0.7372549019607844,
			0.8549019607843137,
			0.5490196078431373,
			0.5882352941176471,
			0.7764705882352941,
			0.5490196078431373,
			0.4196078431372549,
			0.6941176470588235,
			0.5333333333333333,
			0.2549019607843137,
			0.615686274509804,
			0.5058823529411764,
			0.05882352941176471,
			0.4862745098039216,
			0.3019607843137255,
			0,
			0.2941176470588235
		],
		Name: "Brewer Sequential Blue-Purple (9)",
		NanColor: [
			0.3019607843137255,
			0,
			0.2941176470588235
		]
	},
	{
		IndexedColors: [
			0.9686274509803922,
			0.9882352941176471,
			0.9921568627450981,
			0.8784313725490196,
			0.9254901960784314,
			0.9568627450980393,
			0.7490196078431373,
			0.8274509803921568,
			0.9019607843137255,
			0.6196078431372549,
			0.7372549019607844,
			0.8549019607843137,
			0.5490196078431373,
			0.5882352941176471,
			0.7764705882352941,
			0.5490196078431373,
			0.4196078431372549,
			0.6941176470588235,
			0.5333333333333333,
			0.2549019607843137,
			0.615686274509804,
			0.4313725490196079,
			0.00392156862745098,
			0.4196078431372549
		],
		Name: "Brewer Sequential Blue-Purple (8)",
		NanColor: [
			0.4313725490196079,
			0.00392156862745098,
			0.4196078431372549
		]
	},
	{
		IndexedColors: [
			0.9294117647058824,
			0.9725490196078431,
			0.984313725490196,
			0.7490196078431373,
			0.8274509803921568,
			0.9019607843137255,
			0.6196078431372549,
			0.7372549019607844,
			0.8549019607843137,
			0.5490196078431373,
			0.5882352941176471,
			0.7764705882352941,
			0.5490196078431373,
			0.4196078431372549,
			0.6941176470588235,
			0.5333333333333333,
			0.2549019607843137,
			0.615686274509804,
			0.4313725490196079,
			0.00392156862745098,
			0.4196078431372549
		],
		Name: "Brewer Sequential Blue-Purple (7)",
		NanColor: [
			0.4313725490196079,
			0.00392156862745098,
			0.4196078431372549
		]
	},
	{
		IndexedColors: [
			0.9294117647058824,
			0.9725490196078431,
			0.984313725490196,
			0.7490196078431373,
			0.8274509803921568,
			0.9019607843137255,
			0.6196078431372549,
			0.7372549019607844,
			0.8549019607843137,
			0.5490196078431373,
			0.5882352941176471,
			0.7764705882352941,
			0.5333333333333333,
			0.3372549019607843,
			0.6549019607843137,
			0.5058823529411764,
			0.05882352941176471,
			0.4862745098039216
		],
		Name: "Brewer Sequential Blue-Purple (6)",
		NanColor: [
			0.5058823529411764,
			0.05882352941176471,
			0.4862745098039216
		]
	},
	{
		IndexedColors: [
			0.9294117647058824,
			0.9725490196078431,
			0.984313725490196,
			0.7019607843137254,
			0.803921568627451,
			0.8901960784313725,
			0.5490196078431373,
			0.5882352941176471,
			0.7764705882352941,
			0.5333333333333333,
			0.3372549019607843,
			0.6549019607843137,
			0.5058823529411764,
			0.05882352941176471,
			0.4862745098039216
		],
		Name: "Brewer Sequential Blue-Purple (5)",
		NanColor: [
			0.5058823529411764,
			0.05882352941176471,
			0.4862745098039216
		]
	},
	{
		IndexedColors: [
			0.9294117647058824,
			0.9725490196078431,
			0.984313725490196,
			0.7019607843137254,
			0.803921568627451,
			0.8901960784313725,
			0.5490196078431373,
			0.5882352941176471,
			0.7764705882352941,
			0.5333333333333333,
			0.2549019607843137,
			0.615686274509804
		],
		Name: "Brewer Sequential Blue-Purple (4)",
		NanColor: [
			0.5333333333333333,
			0.2549019607843137,
			0.615686274509804
		]
	},
	{
		IndexedColors: [
			0.8784313725490196,
			0.9254901960784314,
			0.9568627450980393,
			0.6196078431372549,
			0.7372549019607844,
			0.8549019607843137,
			0.5333333333333333,
			0.3372549019607843,
			0.6549019607843137
		],
		Name: "Brewer Sequential Blue-Purple (3)",
		NanColor: [
			0.5333333333333333,
			0.3372549019607843,
			0.6549019607843137
		]
	},
	{
		IndexedColors: [
			0.4980392156862745,
			0.788235294117647,
			0.4980392156862745,
			0.7450980392156863,
			0.6823529411764706,
			0.8313725490196079,
			0.9921568627450981,
			0.7529411764705882,
			0.5254901960784314,
			1,
			1,
			0.6,
			0.2196078431372549,
			0.4235294117647059,
			0.6901960784313725,
			0.9411764705882353,
			0.00784313725490196,
			0.4980392156862745,
			0.7490196078431373,
			0.3568627450980392,
			0.09019607843137255,
			0.4,
			0.4,
			0.4
		],
		Name: "Brewer Qualitative Accent",
		NanColor: [
			0.4,
			0.4,
			0.4
		]
	},
	{
		IndexedColors: [
			0.1058823529411765,
			0.6196078431372549,
			0.4666666666666667,
			0.8509803921568627,
			0.3725490196078431,
			0.00784313725490196,
			0.4588235294117647,
			0.4392156862745098,
			0.7019607843137254,
			0.9058823529411765,
			0.1607843137254902,
			0.5411764705882353,
			0.4,
			0.6509803921568628,
			0.1176470588235294,
			0.9019607843137255,
			0.6705882352941176,
			0.00784313725490196,
			0.6509803921568628,
			0.4627450980392157,
			0.1137254901960784,
			0.4,
			0.4,
			0.4
		],
		Name: "Brewer Qualitative Dark2",
		NanColor: [
			0.4,
			0.4,
			0.4
		]
	},
	{
		IndexedColors: [
			0.4,
			0.7607843137254902,
			0.6470588235294118,
			0.9882352941176471,
			0.5529411764705883,
			0.3843137254901961,
			0.5529411764705883,
			0.6274509803921569,
			0.796078431372549,
			0.9058823529411765,
			0.5411764705882353,
			0.7647058823529411,
			0.6509803921568628,
			0.8470588235294118,
			0.3294117647058823,
			1,
			0.8509803921568627,
			0.1843137254901961,
			0.8980392156862745,
			0.7686274509803922,
			0.5803921568627451,
			0.7019607843137254,
			0.7019607843137254,
			0.7019607843137254
		],
		Name: "Brewer Qualitative Set2",
		NanColor: [
			0.7019607843137254,
			0.7019607843137254,
			0.7019607843137254
		]
	},
	{
		IndexedColors: [
			0.7019607843137254,
			0.8862745098039215,
			0.803921568627451,
			0.9921568627450981,
			0.803921568627451,
			0.6745098039215687,
			0.796078431372549,
			0.8352941176470589,
			0.9098039215686274,
			0.9568627450980393,
			0.792156862745098,
			0.8941176470588236,
			0.9019607843137255,
			0.9607843137254902,
			0.788235294117647,
			1,
			0.9490196078431372,
			0.6823529411764706,
			0.9450980392156862,
			0.8862745098039215,
			0.8,
			0.8,
			0.8,
			0.8
		],
		Name: "Brewer Qualitative Pastel2",
		NanColor: [
			0.8,
			0.8,
			0.8
		]
	},
	{
		IndexedColors: [
			0.984313725490196,
			0.7058823529411765,
			0.6823529411764706,
			0.7019607843137254,
			0.803921568627451,
			0.8901960784313725,
			0.8,
			0.9215686274509803,
			0.7725490196078432,
			0.8705882352941177,
			0.796078431372549,
			0.8941176470588236,
			0.996078431372549,
			0.8509803921568627,
			0.6509803921568628,
			1,
			1,
			0.8,
			0.8980392156862745,
			0.8470588235294118,
			0.7411764705882353,
			0.9921568627450981,
			0.8549019607843137,
			0.9254901960784314,
			0.9490196078431372,
			0.9490196078431372,
			0.9490196078431372
		],
		Name: "Brewer Qualitative Pastel1",
		NanColor: [
			0.9490196078431372,
			0.9490196078431372,
			0.9490196078431372
		]
	},
	{
		IndexedColors: [
			0.8941176470588236,
			0.1019607843137255,
			0.1098039215686274,
			0.2156862745098039,
			0.4941176470588236,
			0.7215686274509804,
			0.3019607843137255,
			0.6862745098039216,
			0.2901960784313726,
			0.596078431372549,
			0.3058823529411765,
			0.6392156862745098,
			1,
			0.4980392156862745,
			0,
			1,
			1,
			0.2,
			0.6509803921568628,
			0.3372549019607843,
			0.1568627450980392,
			0.9686274509803922,
			0.5058823529411764,
			0.7490196078431373,
			0.6,
			0.6,
			0.6
		],
		Name: "Brewer Qualitative Set1",
		NanColor: [
			0.6,
			0.6,
			0.6
		]
	},
	{
		IndexedColors: [
			0.6509803921568628,
			0.807843137254902,
			0.8901960784313725,
			0.1215686274509804,
			0.4705882352941176,
			0.7058823529411765,
			0.6980392156862745,
			0.8745098039215686,
			0.5411764705882353,
			0.2,
			0.6274509803921569,
			0.1725490196078431,
			0.984313725490196,
			0.6039215686274509,
			0.6,
			0.8901960784313725,
			0.1019607843137255,
			0.1098039215686274,
			0.9921568627450981,
			0.7490196078431373,
			0.4352941176470588,
			1,
			0.4980392156862745,
			0,
			0.792156862745098,
			0.6980392156862745,
			0.8392156862745098,
			0.4156862745098039,
			0.2392156862745098,
			0.6039215686274509,
			1,
			1,
			0.6
		],
		Name: "Brewer Qualitative Paired",
		NanColor: [
			1,
			1,
			0.6
		]
	},
	{
		IndexedColors: [
			0.5529411764705883,
			0.8274509803921568,
			0.7803921568627451,
			1,
			1,
			0.7019607843137254,
			0.7450980392156863,
			0.7294117647058823,
			0.8549019607843137,
			0.984313725490196,
			0.5019607843137255,
			0.4470588235294118,
			0.5019607843137255,
			0.6941176470588235,
			0.8274509803921568,
			0.9921568627450981,
			0.7058823529411765,
			0.3843137254901961,
			0.7019607843137254,
			0.8705882352941177,
			0.4117647058823529,
			0.9882352941176471,
			0.803921568627451,
			0.8980392156862745,
			0.8509803921568627,
			0.8509803921568627,
			0.8509803921568627,
			0.7372549019607844,
			0.5019607843137255,
			0.7411764705882353,
			0.8,
			0.9215686274509803,
			0.7725490196078432,
			1,
			0.9294117647058824,
			0.4352941176470588
		],
		Name: "Brewer Qualitative Set3",
		NanColor: [
			1,
			0.9294117647058824,
			0.4352941176470588
		]
	},
	{
		IndexedColors: [
			1,
			0,
			0,
			1,
			0.862745,
			0,
			0,
			0.695201,
			0
		],
		Name: "Traffic Lights",
		NanColor: [
			0.803922,
			0,
			0.803922
		]
	},
	{
		IndexedColors: [
			0.908659,
			0.604013,
			0.581857,
			1,
			0.862745,
			0,
			0,
			0.695201,
			0
		],
		Name: "Traffic Lights For Deuteranopes",
		NanColor: [
			0.803922,
			0,
			0.803922
		]
	},
	{
		IndexedColors: [
			0.4196078431372549,
			0,
			0.07058823529411765,
			0.9019607843137255,
			0.9411764705882353,
			0.0196078431372549,
			0.01568627450980392,
			0.6196078431372549,
			0.00784313725490196
		],
		Name: "Traffic Lights For Deuteranopes 2",
		NanColor: [
			0.803922,
			0,
			0.803922
		]
	},
	{
		ColorSpace: "Lab",
		Creator: "Francesca Samsel",
		Name: "Muted Blue-Green",
		NanColor: [
			0.25,
			0,
			0
		],
		RGBPoints: [
			0,
			0.109804,
			0.27451,
			0.301961,
			0.02,
			0.129412,
			0.309804,
			0.341176,
			0.05,
			0.14902,
			0.341176,
			0.380392,
			0.1,
			0.188235,
			0.403922,
			0.458824,
			0.15,
			0.227451,
			0.447059,
			0.521569,
			0.2,
			0.290196,
			0.494118,
			0.588235,
			0.25,
			0.368627,
			0.552941,
			0.670588,
			0.3,
			0.458824,
			0.619608,
			0.74902,
			0.35,
			0.588235,
			0.713725,
			0.85098,
			0.4,
			0.72549,
			0.815686,
			0.941176,
			0.45,
			0.831373,
			0.882353,
			0.980392,
			0.475,
			0.909804,
			0.933333,
			1,
			0.5,
			0.980392,
			0.984314,
			1,
			0.5,
			0.996078,
			1,
			0.94902,
			0.5,
			1,
			1,
			0.980392,
			0.5,
			0.980392,
			0.984314,
			1,
			0.525,
			0.972549,
			0.988235,
			0.890196,
			0.55,
			0.917647,
			0.960784,
			0.835294,
			0.6,
			0.835294,
			0.921569,
			0.772549,
			0.65,
			0.768627,
			0.901961,
			0.737255,
			0.7,
			0.670588,
			0.831373,
			0.654902,
			0.75,
			0.576471,
			0.760784,
			0.584314,
			0.8,
			0.498039,
			0.678431,
			0.521569,
			0.85,
			0.392157,
			0.560784,
			0.427451,
			0.9,
			0.294118,
			0.45098,
			0.333333,
			0.95,
			0.211765,
			0.34902,
			0.254902,
			1,
			0.152941,
			0.278431,
			0.196078
		]
	},
	{
		ColorSpace: "Lab",
		Creator: "Francesca Samsel",
		Name: "Green-Blue Asymmetric Divergent (62Blbc)",
		NanColor: [
			0.25,
			0,
			0
		],
		RGBPoints: [
			0,
			0.121569,
			0.2,
			0.145098,
			0.05,
			0.196078,
			0.301961,
			0.223529,
			0.1,
			0.258824,
			0.4,
			0.278431,
			0.2,
			0.341176,
			0.54902,
			0.341176,
			0.25,
			0.419608,
			0.619608,
			0.376471,
			0.3,
			0.545098,
			0.701961,
			0.392157,
			0.35,
			0.643137,
			0.780392,
			0.403922,
			0.4,
			0.729412,
			0.819608,
			0.45098,
			0.45,
			0.811765,
			0.870588,
			0.521569,
			0.5,
			0.898039,
			0.909804,
			0.564706,
			0.55,
			0.941176,
			0.92549,
			0.686275,
			0.6,
			0.960784,
			0.94902,
			0.776471,
			0.64,
			1,
			1,
			1,
			0.65,
			0.890196,
			0.988235,
			0.972549,
			0.7,
			0.721569,
			0.894118,
			0.901961,
			0.75,
			0.631373,
			0.823529,
			0.839216,
			0.8,
			0.517647,
			0.662745,
			0.701961,
			0.85,
			0.384314,
			0.494118,
			0.54902,
			0.9,
			0.298039,
			0.360784,
			0.45098,
			0.95,
			0.223529,
			0.25098,
			0.34902,
			0.99,
			0.156863,
			0.172549,
			0.25098,
			1,
			0.137255,
			0.137255,
			0.188235
		]
	},
	{
		ColorSpace: "Lab",
		Creator: "Francesca Samsel",
		Name: "Asymmtrical Earth Tones (6_21b)",
		NanColor: [
			0.25,
			0,
			0
		],
		RGBPoints: [
			0,
			0.141176,
			0.14902,
			0.2,
			0.05,
			0.215686,
			0.258824,
			0.321569,
			0.1,
			0.243137,
			0.368627,
			0.380392,
			0.15,
			0.27451,
			0.439216,
			0.4,
			0.2,
			0.32549,
			0.501961,
			0.384314,
			0.25,
			0.403922,
			0.6,
			0.419608,
			0.3,
			0.486275,
			0.701961,
			0.454902,
			0.35,
			0.556863,
			0.74902,
			0.494118,
			0.4,
			0.670588,
			0.8,
			0.545098,
			0.5,
			0.854902,
			0.901961,
			0.631373,
			0.55,
			0.92549,
			0.941176,
			0.694118,
			0.6,
			0.960784,
			0.94902,
			0.776471,
			0.65,
			0.988235,
			0.968627,
			0.909804,
			0.7,
			0.839216,
			0.815686,
			0.772549,
			0.75,
			0.701961,
			0.662745,
			0.615686,
			0.8,
			0.6,
			0.529412,
			0.478431,
			0.85,
			0.501961,
			0.403922,
			0.360784,
			0.9,
			0.439216,
			0.313725,
			0.290196,
			1,
			0.301961,
			0.164706,
			0.176471
		]
	},
	{
		ColorSpace: "Lab",
		Creator: "Francesca Samsel",
		Name: "Yellow 15",
		NanColor: [
			0.25,
			0,
			0
		],
		RGBPoints: [
			0,
			1,
			1,
			0.988235,
			0.002,
			1,
			1,
			0.988235,
			0.05,
			0.984314,
			0.988235,
			0.843137,
			0.1,
			0.988235,
			0.988235,
			0.741176,
			0.15,
			0.980392,
			0.968627,
			0.654902,
			0.2,
			0.980392,
			0.945098,
			0.576471,
			0.25,
			0.968627,
			0.905882,
			0.486275,
			0.3,
			0.968627,
			0.862745,
			0.388235,
			0.35,
			0.960784,
			0.803922,
			0.286275,
			0.4,
			0.94902,
			0.741176,
			0.219608,
			0.45,
			0.941176,
			0.678431,
			0.14902,
			0.5,
			0.929412,
			0.607843,
			0.094118,
			0.55,
			0.921569,
			0.545098,
			0.054902,
			0.6,
			0.909804,
			0.486275,
			0.035294,
			0.65,
			0.890196,
			0.411765,
			0.019608,
			0.7,
			0.8,
			0.305882,
			0,
			0.75,
			0.760784,
			0.239216,
			0,
			0.8,
			0.678431,
			0.180392,
			0.011765,
			0.85,
			0.6,
			0.121569,
			0.023529,
			0.9,
			0.501961,
			0.054902,
			0.031373,
			0.95,
			0.4,
			0.039216,
			0.058824,
			1,
			0.301961,
			0.047059,
			0.090196
		]
	},
	{
		ColorSpace: "Diverging",
		Name: "Magma (matplotlib)",
		NanColor: [
			0,
			1,
			0
		],
		Source: "https://github.com/BIDS/colormap/blob/master/colormaps.py",
		License: "CC0",
		Creator: "Nathaniel J. Smith & Stefan van der Walt",
		RGBPoints: [
			0,
			0.001462,
			0.000466,
			0.013866,
			0.003922,
			0.002258,
			0.001295,
			0.018331,
			0.007843,
			0.003279,
			0.002305,
			0.023708,
			0.011765,
			0.004512,
			0.00349,
			0.029965,
			0.015686,
			0.00595,
			0.004843,
			0.03713,
			0.019608,
			0.007588,
			0.006356,
			0.044973,
			0.023529,
			0.009426,
			0.008022,
			0.052844,
			0.027451,
			0.011465,
			0.009828,
			0.06075,
			0.031373,
			0.013708,
			0.011771,
			0.068667,
			0.035294,
			0.016156,
			0.01384,
			0.076603,
			0.039216,
			0.018815,
			0.016026,
			0.084584,
			0.043137,
			0.021692,
			0.01832,
			0.09261,
			0.047059,
			0.024792,
			0.020715,
			0.100676,
			0.05098,
			0.028123,
			0.023201,
			0.108787,
			0.054902,
			0.031696,
			0.025765,
			0.116965,
			0.058824,
			0.03552,
			0.028397,
			0.125209,
			0.062745,
			0.039608,
			0.03109,
			0.133515,
			0.066667,
			0.04383,
			0.03383,
			0.141886,
			0.070588,
			0.048062,
			0.036607,
			0.150327,
			0.07451,
			0.05232,
			0.039407,
			0.158841,
			0.078431,
			0.056615,
			0.04216,
			0.167446,
			0.082353,
			0.060949,
			0.044794,
			0.176129,
			0.086275,
			0.06533,
			0.047318,
			0.184892,
			0.090196,
			0.069764,
			0.049726,
			0.193735,
			0.094118,
			0.074257,
			0.052017,
			0.20266,
			0.098039,
			0.078815,
			0.054184,
			0.211667,
			0.101961,
			0.083446,
			0.056225,
			0.220755,
			0.105882,
			0.088155,
			0.058133,
			0.229922,
			0.109804,
			0.092949,
			0.059904,
			0.239164,
			0.113725,
			0.097833,
			0.061531,
			0.248477,
			0.117647,
			0.102815,
			0.06301,
			0.257854,
			0.121569,
			0.107899,
			0.064335,
			0.267289,
			0.12549,
			0.113094,
			0.065492,
			0.276784,
			0.129412,
			0.118405,
			0.066479,
			0.286321,
			0.133333,
			0.123833,
			0.067295,
			0.295879,
			0.137255,
			0.12938,
			0.067935,
			0.305443,
			0.141176,
			0.135053,
			0.068391,
			0.315,
			0.145098,
			0.140858,
			0.068654,
			0.324538,
			0.14902,
			0.146785,
			0.068738,
			0.334011,
			0.152941,
			0.152839,
			0.068637,
			0.343404,
			0.156863,
			0.159018,
			0.068354,
			0.352688,
			0.160784,
			0.165308,
			0.067911,
			0.361816,
			0.164706,
			0.171713,
			0.067305,
			0.370771,
			0.168627,
			0.178212,
			0.066576,
			0.379497,
			0.172549,
			0.184801,
			0.065732,
			0.387973,
			0.176471,
			0.19146,
			0.064818,
			0.396152,
			0.180392,
			0.198177,
			0.063862,
			0.404009,
			0.184314,
			0.204935,
			0.062907,
			0.411514,
			0.188235,
			0.211718,
			0.061992,
			0.418647,
			0.192157,
			0.218512,
			0.061158,
			0.425392,
			0.196078,
			0.225302,
			0.060445,
			0.431742,
			0.2,
			0.232077,
			0.059889,
			0.437695,
			0.203922,
			0.238826,
			0.059517,
			0.443256,
			0.207843,
			0.245543,
			0.059352,
			0.448436,
			0.211765,
			0.25222,
			0.059415,
			0.453248,
			0.215686,
			0.258857,
			0.059706,
			0.45771,
			0.219608,
			0.265447,
			0.060237,
			0.46184,
			0.223529,
			0.271994,
			0.060994,
			0.46566,
			0.227451,
			0.278493,
			0.061978,
			0.46919,
			0.231373,
			0.284951,
			0.063168,
			0.472451,
			0.235294,
			0.291366,
			0.064553,
			0.475462,
			0.239216,
			0.29774,
			0.066117,
			0.478243,
			0.243137,
			0.304081,
			0.067835,
			0.480812,
			0.247059,
			0.310382,
			0.069702,
			0.483186,
			0.25098,
			0.316654,
			0.07169,
			0.48538,
			0.254902,
			0.322899,
			0.073782,
			0.487408,
			0.258824,
			0.329114,
			0.075972,
			0.489287,
			0.262745,
			0.335308,
			0.078236,
			0.491024,
			0.266667,
			0.341482,
			0.080564,
			0.492631,
			0.270588,
			0.347636,
			0.082946,
			0.494121,
			0.27451,
			0.353773,
			0.085373,
			0.495501,
			0.278431,
			0.359898,
			0.087831,
			0.496778,
			0.282353,
			0.366012,
			0.090314,
			0.49796,
			0.286275,
			0.372116,
			0.092816,
			0.499053,
			0.290196,
			0.378211,
			0.095332,
			0.500067,
			0.294118,
			0.384299,
			0.097855,
			0.501002,
			0.298039,
			0.390384,
			0.100379,
			0.501864,
			0.301961,
			0.396467,
			0.102902,
			0.502658,
			0.305882,
			0.402548,
			0.10542,
			0.503386,
			0.309804,
			0.408629,
			0.10793,
			0.504052,
			0.313725,
			0.414709,
			0.110431,
			0.504662,
			0.317647,
			0.420791,
			0.11292,
			0.505215,
			0.321569,
			0.426877,
			0.115395,
			0.505714,
			0.32549,
			0.432967,
			0.117855,
			0.50616,
			0.329412,
			0.439062,
			0.120298,
			0.506555,
			0.333333,
			0.445163,
			0.122724,
			0.506901,
			0.337255,
			0.451271,
			0.125132,
			0.507198,
			0.341176,
			0.457386,
			0.127522,
			0.507448,
			0.345098,
			0.463508,
			0.129893,
			0.507652,
			0.34902,
			0.46964,
			0.132245,
			0.507809,
			0.352941,
			0.47578,
			0.134577,
			0.507921,
			0.356863,
			0.481929,
			0.136891,
			0.507989,
			0.360784,
			0.488088,
			0.139186,
			0.508011,
			0.364706,
			0.494258,
			0.141462,
			0.507988,
			0.368627,
			0.500438,
			0.143719,
			0.50792,
			0.372549,
			0.506629,
			0.145958,
			0.507806,
			0.376471,
			0.512831,
			0.148179,
			0.507648,
			0.380392,
			0.519045,
			0.150383,
			0.507443,
			0.384314,
			0.52527,
			0.152569,
			0.507192,
			0.388235,
			0.531507,
			0.154739,
			0.506895,
			0.392157,
			0.537755,
			0.156894,
			0.506551,
			0.396078,
			0.544015,
			0.159033,
			0.506159,
			0.4,
			0.550287,
			0.161158,
			0.505719,
			0.403922,
			0.556571,
			0.163269,
			0.50523,
			0.407843,
			0.562866,
			0.165368,
			0.504692,
			0.411765,
			0.569172,
			0.167454,
			0.504105,
			0.415686,
			0.57549,
			0.16953,
			0.503466,
			0.419608,
			0.581819,
			0.171596,
			0.502777,
			0.423529,
			0.588158,
			0.173652,
			0.502035,
			0.427451,
			0.594508,
			0.175701,
			0.501241,
			0.431373,
			0.600868,
			0.177743,
			0.500394,
			0.435294,
			0.607238,
			0.179779,
			0.499492,
			0.439216,
			0.613617,
			0.181811,
			0.498536,
			0.443137,
			0.620005,
			0.18384,
			0.497524,
			0.447059,
			0.626401,
			0.185867,
			0.496456,
			0.45098,
			0.632805,
			0.187893,
			0.495332,
			0.454902,
			0.639216,
			0.189921,
			0.49415,
			0.458824,
			0.645633,
			0.191952,
			0.49291,
			0.462745,
			0.652056,
			0.193986,
			0.491611,
			0.466667,
			0.658483,
			0.196027,
			0.490253,
			0.470588,
			0.664915,
			0.198075,
			0.488836,
			0.47451,
			0.671349,
			0.200133,
			0.487358,
			0.478431,
			0.677786,
			0.202203,
			0.485819,
			0.482353,
			0.684224,
			0.204286,
			0.484219,
			0.486275,
			0.690661,
			0.206384,
			0.482558,
			0.490196,
			0.697098,
			0.208501,
			0.480835,
			0.494118,
			0.703532,
			0.210638,
			0.479049,
			0.498039,
			0.709962,
			0.212797,
			0.477201,
			0.501961,
			0.716387,
			0.214982,
			0.47529,
			0.505882,
			0.722805,
			0.217194,
			0.473316,
			0.509804,
			0.729216,
			0.219437,
			0.471279,
			0.513725,
			0.735616,
			0.221713,
			0.46918,
			0.517647,
			0.742004,
			0.224025,
			0.467018,
			0.521569,
			0.748378,
			0.226377,
			0.464794,
			0.52549,
			0.754737,
			0.228772,
			0.462509,
			0.529412,
			0.761077,
			0.231214,
			0.460162,
			0.533333,
			0.767398,
			0.233705,
			0.457755,
			0.537255,
			0.773695,
			0.236249,
			0.455289,
			0.541176,
			0.779968,
			0.238851,
			0.452765,
			0.545098,
			0.786212,
			0.241514,
			0.450184,
			0.54902,
			0.792427,
			0.244242,
			0.447543,
			0.552941,
			0.798608,
			0.24704,
			0.444848,
			0.556863,
			0.804752,
			0.249911,
			0.442102,
			0.560784,
			0.810855,
			0.252861,
			0.439305,
			0.564706,
			0.816914,
			0.255895,
			0.436461,
			0.568627,
			0.822926,
			0.259016,
			0.433573,
			0.572549,
			0.828886,
			0.262229,
			0.430644,
			0.576471,
			0.834791,
			0.26554,
			0.427671,
			0.580392,
			0.840636,
			0.268953,
			0.424666,
			0.584314,
			0.846416,
			0.272473,
			0.421631,
			0.588235,
			0.852126,
			0.276106,
			0.418573,
			0.592157,
			0.857763,
			0.279857,
			0.415496,
			0.596078,
			0.86332,
			0.283729,
			0.412403,
			0.6,
			0.868793,
			0.287728,
			0.409303,
			0.603922,
			0.874176,
			0.291859,
			0.406205,
			0.607843,
			0.879464,
			0.296125,
			0.403118,
			0.611765,
			0.884651,
			0.30053,
			0.400047,
			0.615686,
			0.889731,
			0.305079,
			0.397002,
			0.619608,
			0.8947,
			0.309773,
			0.393995,
			0.623529,
			0.899552,
			0.314616,
			0.391037,
			0.627451,
			0.904281,
			0.31961,
			0.388137,
			0.631373,
			0.908884,
			0.324755,
			0.385308,
			0.635294,
			0.913354,
			0.330052,
			0.382563,
			0.639216,
			0.917689,
			0.3355,
			0.379915,
			0.643137,
			0.921884,
			0.341098,
			0.377376,
			0.647059,
			0.925937,
			0.346844,
			0.374959,
			0.65098,
			0.929845,
			0.352734,
			0.372677,
			0.654902,
			0.933606,
			0.358764,
			0.370541,
			0.658824,
			0.937221,
			0.364929,
			0.368567,
			0.662745,
			0.940687,
			0.371224,
			0.366762,
			0.666667,
			0.944006,
			0.377643,
			0.365136,
			0.670588,
			0.94718,
			0.384178,
			0.363701,
			0.67451,
			0.95021,
			0.39082,
			0.362468,
			0.678431,
			0.953099,
			0.397563,
			0.361438,
			0.682353,
			0.955849,
			0.4044,
			0.360619,
			0.686275,
			0.958464,
			0.411324,
			0.360014,
			0.690196,
			0.960949,
			0.418323,
			0.35963,
			0.694118,
			0.96331,
			0.42539,
			0.359469,
			0.698039,
			0.965549,
			0.432519,
			0.359529,
			0.701961,
			0.967671,
			0.439703,
			0.35981,
			0.705882,
			0.96968,
			0.446936,
			0.360311,
			0.709804,
			0.971582,
			0.45421,
			0.36103,
			0.713725,
			0.973381,
			0.46152,
			0.361965,
			0.717647,
			0.975082,
			0.468861,
			0.363111,
			0.721569,
			0.97669,
			0.476226,
			0.364466,
			0.72549,
			0.97821,
			0.483612,
			0.366025,
			0.729412,
			0.979645,
			0.491014,
			0.367783,
			0.733333,
			0.981,
			0.498428,
			0.369734,
			0.737255,
			0.982279,
			0.505851,
			0.371874,
			0.741176,
			0.983485,
			0.51328,
			0.374198,
			0.745098,
			0.984622,
			0.520713,
			0.376698,
			0.74902,
			0.985693,
			0.528148,
			0.379371,
			0.752941,
			0.9867,
			0.535582,
			0.38221,
			0.756863,
			0.987646,
			0.543015,
			0.38521,
			0.760784,
			0.988533,
			0.550446,
			0.388365,
			0.764706,
			0.989363,
			0.557873,
			0.391671,
			0.768627,
			0.990138,
			0.565296,
			0.395122,
			0.772549,
			0.990871,
			0.572706,
			0.398714,
			0.776471,
			0.991558,
			0.580107,
			0.402441,
			0.780392,
			0.992196,
			0.587502,
			0.406299,
			0.784314,
			0.992785,
			0.594891,
			0.410283,
			0.788235,
			0.993326,
			0.602275,
			0.41439,
			0.792157,
			0.993834,
			0.609644,
			0.418613,
			0.796078,
			0.994309,
			0.616999,
			0.42295,
			0.8,
			0.994738,
			0.62435,
			0.427397,
			0.803922,
			0.995122,
			0.631696,
			0.431951,
			0.807843,
			0.99548,
			0.639027,
			0.436607,
			0.811765,
			0.99581,
			0.646344,
			0.441361,
			0.815686,
			0.996096,
			0.653659,
			0.446213,
			0.819608,
			0.996341,
			0.660969,
			0.45116,
			0.823529,
			0.99658,
			0.668256,
			0.456192,
			0.827451,
			0.996775,
			0.675541,
			0.461314,
			0.831373,
			0.996925,
			0.682828,
			0.466526,
			0.835294,
			0.997077,
			0.690088,
			0.471811,
			0.839216,
			0.997186,
			0.697349,
			0.477182,
			0.843137,
			0.997254,
			0.704611,
			0.482635,
			0.847059,
			0.997325,
			0.711848,
			0.488154,
			0.85098,
			0.997351,
			0.719089,
			0.493755,
			0.854902,
			0.997351,
			0.726324,
			0.499428,
			0.858824,
			0.997341,
			0.733545,
			0.505167,
			0.862745,
			0.997285,
			0.740772,
			0.510983,
			0.866667,
			0.997228,
			0.747981,
			0.516859,
			0.870588,
			0.997138,
			0.75519,
			0.522806,
			0.87451,
			0.997019,
			0.762398,
			0.528821,
			0.878431,
			0.996898,
			0.769591,
			0.534892,
			0.882353,
			0.996727,
			0.776795,
			0.541039,
			0.886275,
			0.996571,
			0.783977,
			0.547233,
			0.890196,
			0.996369,
			0.791167,
			0.553499,
			0.894118,
			0.996162,
			0.798348,
			0.55982,
			0.898039,
			0.995932,
			0.805527,
			0.566202,
			0.901961,
			0.99568,
			0.812706,
			0.572645,
			0.905882,
			0.995424,
			0.819875,
			0.57914,
			0.909804,
			0.995131,
			0.827052,
			0.585701,
			0.913725,
			0.994851,
			0.834213,
			0.592307,
			0.917647,
			0.994524,
			0.841387,
			0.598983,
			0.921569,
			0.994222,
			0.84854,
			0.605696,
			0.92549,
			0.993866,
			0.855711,
			0.612482,
			0.929412,
			0.993545,
			0.862859,
			0.619299,
			0.933333,
			0.99317,
			0.870024,
			0.626189,
			0.937255,
			0.992831,
			0.877168,
			0.633109,
			0.941176,
			0.99244,
			0.88433,
			0.640099,
			0.945098,
			0.992089,
			0.89147,
			0.647116,
			0.94902,
			0.991688,
			0.898627,
			0.654202,
			0.952941,
			0.991332,
			0.905763,
			0.661309,
			0.956863,
			0.99093,
			0.912915,
			0.668481,
			0.960784,
			0.99057,
			0.920049,
			0.675675,
			0.964706,
			0.990175,
			0.927196,
			0.682926,
			0.968627,
			0.989815,
			0.934329,
			0.690198,
			0.972549,
			0.989434,
			0.94147,
			0.697519,
			0.976471,
			0.989077,
			0.948604,
			0.704863,
			0.980392,
			0.988717,
			0.955742,
			0.712242,
			0.984314,
			0.988367,
			0.962878,
			0.719649,
			0.988235,
			0.988033,
			0.970012,
			0.727077,
			0.992157,
			0.987691,
			0.977154,
			0.734536,
			0.996078,
			0.987387,
			0.984288,
			0.742002,
			1,
			0.987053,
			0.991438,
			0.749504
		]
	},
	{
		ColorSpace: "Diverging",
		Name: "Inferno (matplotlib)",
		NanColor: [
			0,
			1,
			0
		],
		Source: "https://github.com/BIDS/colormap/blob/master/colormaps.py",
		License: "CC0",
		Creator: "Nathaniel J. Smith & Stefan van der Walt",
		RGBPoints: [
			0,
			0.001462,
			0.000466,
			0.013866,
			0.003922,
			0.002267,
			0.00127,
			0.01857,
			0.007843,
			0.003299,
			0.002249,
			0.024239,
			0.011765,
			0.004547,
			0.003392,
			0.030909,
			0.015686,
			0.006006,
			0.004692,
			0.038558,
			0.019608,
			0.007676,
			0.006136,
			0.046836,
			0.023529,
			0.009561,
			0.007713,
			0.055143,
			0.027451,
			0.011663,
			0.009417,
			0.06346,
			0.031373,
			0.013995,
			0.011225,
			0.071862,
			0.035294,
			0.016561,
			0.013136,
			0.080282,
			0.039216,
			0.019373,
			0.015133,
			0.088767,
			0.043137,
			0.022447,
			0.017199,
			0.097327,
			0.047059,
			0.025793,
			0.019331,
			0.10593,
			0.05098,
			0.029432,
			0.021503,
			0.114621,
			0.054902,
			0.033385,
			0.023702,
			0.123397,
			0.058824,
			0.037668,
			0.025921,
			0.132232,
			0.062745,
			0.042253,
			0.028139,
			0.141141,
			0.066667,
			0.046915,
			0.030324,
			0.150164,
			0.070588,
			0.051644,
			0.032474,
			0.159254,
			0.07451,
			0.056449,
			0.034569,
			0.168414,
			0.078431,
			0.06134,
			0.03659,
			0.177642,
			0.082353,
			0.066331,
			0.038504,
			0.186962,
			0.086275,
			0.071429,
			0.040294,
			0.196354,
			0.090196,
			0.076637,
			0.041905,
			0.205799,
			0.094118,
			0.081962,
			0.043328,
			0.215289,
			0.098039,
			0.087411,
			0.044556,
			0.224813,
			0.101961,
			0.09299,
			0.045583,
			0.234358,
			0.105882,
			0.098702,
			0.046402,
			0.243904,
			0.109804,
			0.104551,
			0.047008,
			0.25343,
			0.113725,
			0.110536,
			0.047399,
			0.262912,
			0.117647,
			0.116656,
			0.047574,
			0.272321,
			0.121569,
			0.122908,
			0.047536,
			0.281624,
			0.12549,
			0.129285,
			0.047293,
			0.290788,
			0.129412,
			0.135778,
			0.046856,
			0.299776,
			0.133333,
			0.142378,
			0.046242,
			0.308553,
			0.137255,
			0.149073,
			0.045468,
			0.317085,
			0.141176,
			0.15585,
			0.044559,
			0.325338,
			0.145098,
			0.162689,
			0.043554,
			0.333277,
			0.14902,
			0.169575,
			0.042489,
			0.340874,
			0.152941,
			0.176493,
			0.041402,
			0.348111,
			0.156863,
			0.183429,
			0.040329,
			0.354971,
			0.160784,
			0.190367,
			0.039309,
			0.361447,
			0.164706,
			0.197297,
			0.0384,
			0.367535,
			0.168627,
			0.204209,
			0.037632,
			0.373238,
			0.172549,
			0.211095,
			0.03703,
			0.378563,
			0.176471,
			0.217949,
			0.036615,
			0.383522,
			0.180392,
			0.224763,
			0.036405,
			0.388129,
			0.184314,
			0.231538,
			0.036405,
			0.3924,
			0.188235,
			0.238273,
			0.036621,
			0.396353,
			0.192157,
			0.244967,
			0.037055,
			0.400007,
			0.196078,
			0.25162,
			0.037705,
			0.403378,
			0.2,
			0.258234,
			0.038571,
			0.406485,
			0.203922,
			0.26481,
			0.039647,
			0.409345,
			0.207843,
			0.271347,
			0.040922,
			0.411976,
			0.211765,
			0.27785,
			0.042353,
			0.414392,
			0.215686,
			0.284321,
			0.043933,
			0.416608,
			0.219608,
			0.290763,
			0.045644,
			0.418637,
			0.223529,
			0.297178,
			0.04747,
			0.420491,
			0.227451,
			0.303568,
			0.049396,
			0.422182,
			0.231373,
			0.309935,
			0.051407,
			0.423721,
			0.235294,
			0.316282,
			0.05349,
			0.425116,
			0.239216,
			0.32261,
			0.055634,
			0.426377,
			0.243137,
			0.328921,
			0.057827,
			0.427511,
			0.247059,
			0.335217,
			0.06006,
			0.428524,
			0.25098,
			0.3415,
			0.062325,
			0.429425,
			0.254902,
			0.347771,
			0.064616,
			0.430217,
			0.258824,
			0.354032,
			0.066925,
			0.430906,
			0.262745,
			0.360284,
			0.069247,
			0.431497,
			0.266667,
			0.366529,
			0.071579,
			0.431994,
			0.270588,
			0.372768,
			0.073915,
			0.4324,
			0.27451,
			0.379001,
			0.076253,
			0.432719,
			0.278431,
			0.385228,
			0.078591,
			0.432955,
			0.282353,
			0.391453,
			0.080927,
			0.433109,
			0.286275,
			0.397674,
			0.083257,
			0.433183,
			0.290196,
			0.403894,
			0.08558,
			0.433179,
			0.294118,
			0.410113,
			0.087896,
			0.433098,
			0.298039,
			0.416331,
			0.090203,
			0.432943,
			0.301961,
			0.422549,
			0.092501,
			0.432714,
			0.305882,
			0.428768,
			0.09479,
			0.432412,
			0.309804,
			0.434987,
			0.097069,
			0.432039,
			0.313725,
			0.441207,
			0.099338,
			0.431594,
			0.317647,
			0.447428,
			0.101597,
			0.43108,
			0.321569,
			0.453651,
			0.103848,
			0.430498,
			0.32549,
			0.459875,
			0.106089,
			0.429846,
			0.329412,
			0.4661,
			0.108322,
			0.429125,
			0.333333,
			0.472328,
			0.110547,
			0.428334,
			0.337255,
			0.478558,
			0.112764,
			0.427475,
			0.341176,
			0.484789,
			0.114974,
			0.426548,
			0.345098,
			0.491022,
			0.117179,
			0.425552,
			0.34902,
			0.497257,
			0.119379,
			0.424488,
			0.352941,
			0.503493,
			0.121575,
			0.423356,
			0.356863,
			0.50973,
			0.123769,
			0.422156,
			0.360784,
			0.515967,
			0.12596,
			0.420887,
			0.364706,
			0.522206,
			0.12815,
			0.419549,
			0.368627,
			0.528444,
			0.130341,
			0.418142,
			0.372549,
			0.534683,
			0.132534,
			0.416667,
			0.376471,
			0.54092,
			0.134729,
			0.415123,
			0.380392,
			0.547157,
			0.136929,
			0.413511,
			0.384314,
			0.553392,
			0.139134,
			0.411829,
			0.388235,
			0.559624,
			0.141346,
			0.410078,
			0.392157,
			0.565854,
			0.143567,
			0.408258,
			0.396078,
			0.572081,
			0.145797,
			0.406369,
			0.4,
			0.578304,
			0.148039,
			0.404411,
			0.403922,
			0.584521,
			0.150294,
			0.402385,
			0.407843,
			0.590734,
			0.152563,
			0.40029,
			0.411765,
			0.59694,
			0.154848,
			0.398125,
			0.415686,
			0.603139,
			0.157151,
			0.395891,
			0.419608,
			0.60933,
			0.159474,
			0.393589,
			0.423529,
			0.615513,
			0.161817,
			0.391219,
			0.427451,
			0.621685,
			0.164184,
			0.388781,
			0.431373,
			0.627847,
			0.166575,
			0.386276,
			0.435294,
			0.633998,
			0.168992,
			0.383704,
			0.439216,
			0.640135,
			0.171438,
			0.381065,
			0.443137,
			0.64626,
			0.173914,
			0.378359,
			0.447059,
			0.652369,
			0.176421,
			0.375586,
			0.45098,
			0.658463,
			0.178962,
			0.372748,
			0.454902,
			0.66454,
			0.181539,
			0.369846,
			0.458824,
			0.670599,
			0.184153,
			0.366879,
			0.462745,
			0.676638,
			0.186807,
			0.363849,
			0.466667,
			0.682656,
			0.189501,
			0.360757,
			0.470588,
			0.688653,
			0.192239,
			0.357603,
			0.47451,
			0.694627,
			0.195021,
			0.354388,
			0.478431,
			0.700576,
			0.197851,
			0.351113,
			0.482353,
			0.7065,
			0.200728,
			0.347777,
			0.486275,
			0.712396,
			0.203656,
			0.344383,
			0.490196,
			0.718264,
			0.206636,
			0.340931,
			0.494118,
			0.724103,
			0.20967,
			0.337424,
			0.498039,
			0.729909,
			0.212759,
			0.333861,
			0.501961,
			0.735683,
			0.215906,
			0.330245,
			0.505882,
			0.741423,
			0.219112,
			0.326576,
			0.509804,
			0.747127,
			0.222378,
			0.322856,
			0.513725,
			0.752794,
			0.225706,
			0.319085,
			0.517647,
			0.758422,
			0.229097,
			0.315266,
			0.521569,
			0.76401,
			0.232554,
			0.311399,
			0.52549,
			0.769556,
			0.236077,
			0.307485,
			0.529412,
			0.775059,
			0.239667,
			0.303526,
			0.533333,
			0.780517,
			0.243327,
			0.299523,
			0.537255,
			0.785929,
			0.247056,
			0.295477,
			0.541176,
			0.791293,
			0.250856,
			0.29139,
			0.545098,
			0.796607,
			0.254728,
			0.287264,
			0.54902,
			0.801871,
			0.258674,
			0.283099,
			0.552941,
			0.807082,
			0.262692,
			0.278898,
			0.556863,
			0.812239,
			0.266786,
			0.274661,
			0.560784,
			0.817341,
			0.270954,
			0.27039,
			0.564706,
			0.822386,
			0.275197,
			0.266085,
			0.568627,
			0.827372,
			0.279517,
			0.26175,
			0.572549,
			0.832299,
			0.283913,
			0.257383,
			0.576471,
			0.837165,
			0.288385,
			0.252988,
			0.580392,
			0.841969,
			0.292933,
			0.248564,
			0.584314,
			0.846709,
			0.297559,
			0.244113,
			0.588235,
			0.851384,
			0.30226,
			0.239636,
			0.592157,
			0.855992,
			0.307038,
			0.235133,
			0.596078,
			0.860533,
			0.311892,
			0.230606,
			0.6,
			0.865006,
			0.316822,
			0.226055,
			0.603922,
			0.869409,
			0.321827,
			0.221482,
			0.607843,
			0.873741,
			0.326906,
			0.216886,
			0.611765,
			0.878001,
			0.33206,
			0.212268,
			0.615686,
			0.882188,
			0.337287,
			0.207628,
			0.619608,
			0.886302,
			0.342586,
			0.202968,
			0.623529,
			0.890341,
			0.347957,
			0.198286,
			0.627451,
			0.894305,
			0.353399,
			0.193584,
			0.631373,
			0.898192,
			0.358911,
			0.18886,
			0.635294,
			0.902003,
			0.364492,
			0.184116,
			0.639216,
			0.905735,
			0.37014,
			0.17935,
			0.643137,
			0.90939,
			0.375856,
			0.174563,
			0.647059,
			0.912966,
			0.381636,
			0.169755,
			0.65098,
			0.916462,
			0.387481,
			0.164924,
			0.654902,
			0.919879,
			0.393389,
			0.16007,
			0.658824,
			0.923215,
			0.399359,
			0.155193,
			0.662745,
			0.92647,
			0.405389,
			0.150292,
			0.666667,
			0.929644,
			0.411479,
			0.145367,
			0.670588,
			0.932737,
			0.417627,
			0.140417,
			0.67451,
			0.935747,
			0.423831,
			0.13544,
			0.678431,
			0.938675,
			0.430091,
			0.130438,
			0.682353,
			0.941521,
			0.436405,
			0.125409,
			0.686275,
			0.944285,
			0.442772,
			0.120354,
			0.690196,
			0.946965,
			0.449191,
			0.115272,
			0.694118,
			0.949562,
			0.45566,
			0.110164,
			0.698039,
			0.952075,
			0.462178,
			0.105031,
			0.701961,
			0.954506,
			0.468744,
			0.099874,
			0.705882,
			0.956852,
			0.475356,
			0.094695,
			0.709804,
			0.959114,
			0.482014,
			0.089499,
			0.713725,
			0.961293,
			0.488716,
			0.084289,
			0.717647,
			0.963387,
			0.495462,
			0.079073,
			0.721569,
			0.965397,
			0.502249,
			0.073859,
			0.72549,
			0.967322,
			0.509078,
			0.068659,
			0.729412,
			0.969163,
			0.515946,
			0.063488,
			0.733333,
			0.970919,
			0.522853,
			0.058367,
			0.737255,
			0.97259,
			0.529798,
			0.053324,
			0.741176,
			0.974176,
			0.53678,
			0.048392,
			0.745098,
			0.975677,
			0.543798,
			0.043618,
			0.74902,
			0.977092,
			0.55085,
			0.03905,
			0.752941,
			0.978422,
			0.557937,
			0.034931,
			0.756863,
			0.979666,
			0.565057,
			0.031409,
			0.760784,
			0.980824,
			0.572209,
			0.028508,
			0.764706,
			0.981895,
			0.579392,
			0.02625,
			0.768627,
			0.982881,
			0.586606,
			0.024661,
			0.772549,
			0.983779,
			0.593849,
			0.02377,
			0.776471,
			0.984591,
			0.601122,
			0.023606,
			0.780392,
			0.985315,
			0.608422,
			0.024202,
			0.784314,
			0.985952,
			0.61575,
			0.025592,
			0.788235,
			0.986502,
			0.623105,
			0.027814,
			0.792157,
			0.986964,
			0.630485,
			0.030908,
			0.796078,
			0.987337,
			0.63789,
			0.034916,
			0.8,
			0.987622,
			0.64532,
			0.039886,
			0.803922,
			0.987819,
			0.652773,
			0.045581,
			0.807843,
			0.987926,
			0.66025,
			0.05175,
			0.811765,
			0.987945,
			0.667748,
			0.058329,
			0.815686,
			0.987874,
			0.675267,
			0.065257,
			0.819608,
			0.987714,
			0.682807,
			0.072489,
			0.823529,
			0.987464,
			0.690366,
			0.07999,
			0.827451,
			0.987124,
			0.697944,
			0.087731,
			0.831373,
			0.986694,
			0.70554,
			0.095694,
			0.835294,
			0.986175,
			0.713153,
			0.103863,
			0.839216,
			0.985566,
			0.720782,
			0.112229,
			0.843137,
			0.984865,
			0.728427,
			0.120785,
			0.847059,
			0.984075,
			0.736087,
			0.129527,
			0.85098,
			0.983196,
			0.743758,
			0.138453,
			0.854902,
			0.982228,
			0.751442,
			0.147565,
			0.858824,
			0.981173,
			0.759135,
			0.156863,
			0.862745,
			0.980032,
			0.766837,
			0.166353,
			0.866667,
			0.978806,
			0.774545,
			0.176037,
			0.870588,
			0.977497,
			0.782258,
			0.185923,
			0.87451,
			0.976108,
			0.789974,
			0.196018,
			0.878431,
			0.974638,
			0.797692,
			0.206332,
			0.882353,
			0.973088,
			0.805409,
			0.216877,
			0.886275,
			0.971468,
			0.813122,
			0.227658,
			0.890196,
			0.969783,
			0.820825,
			0.238686,
			0.894118,
			0.968041,
			0.828515,
			0.249972,
			0.898039,
			0.966243,
			0.836191,
			0.261534,
			0.901961,
			0.964394,
			0.843848,
			0.273391,
			0.905882,
			0.962517,
			0.851476,
			0.285546,
			0.909804,
			0.960626,
			0.859069,
			0.29801,
			0.913725,
			0.95872,
			0.866624,
			0.31082,
			0.917647,
			0.956834,
			0.874129,
			0.323974,
			0.921569,
			0.954997,
			0.881569,
			0.337475,
			0.92549,
			0.953215,
			0.888942,
			0.351369,
			0.929412,
			0.951546,
			0.896226,
			0.365627,
			0.933333,
			0.950018,
			0.903409,
			0.380271,
			0.937255,
			0.948683,
			0.910473,
			0.395289,
			0.941176,
			0.947594,
			0.917399,
			0.410665,
			0.945098,
			0.946809,
			0.924168,
			0.426373,
			0.94902,
			0.946392,
			0.930761,
			0.442367,
			0.952941,
			0.946403,
			0.937159,
			0.458592,
			0.956863,
			0.946903,
			0.943348,
			0.47497,
			0.960784,
			0.947937,
			0.949318,
			0.491426,
			0.964706,
			0.949545,
			0.955063,
			0.50786,
			0.968627,
			0.95174,
			0.960587,
			0.524203,
			0.972549,
			0.954529,
			0.965896,
			0.540361,
			0.976471,
			0.957896,
			0.971003,
			0.556275,
			0.980392,
			0.961812,
			0.975924,
			0.571925,
			0.984314,
			0.966249,
			0.980678,
			0.587206,
			0.988235,
			0.971162,
			0.985282,
			0.602154,
			0.992157,
			0.976511,
			0.989753,
			0.61676,
			0.996078,
			0.982257,
			0.994109,
			0.631017,
			1,
			0.988362,
			0.998364,
			0.644924
		]
	},
	{
		ColorSpace: "Diverging",
		Name: "Plasma (matplotlib)",
		NanColor: [
			0,
			1,
			0
		],
		Source: "https://github.com/BIDS/colormap/blob/master/colormaps.py",
		License: "CC0",
		Creator: "Nathaniel J. Smith & Stefan van der Walt",
		RGBPoints: [
			0,
			0.050383,
			0.029803,
			0.527975,
			0.003922,
			0.063536,
			0.028426,
			0.533124,
			0.007843,
			0.075353,
			0.027206,
			0.538007,
			0.011765,
			0.086222,
			0.026125,
			0.542658,
			0.015686,
			0.096379,
			0.025165,
			0.547103,
			0.019608,
			0.10598,
			0.024309,
			0.551368,
			0.023529,
			0.115124,
			0.023556,
			0.555468,
			0.027451,
			0.123903,
			0.022878,
			0.559423,
			0.031373,
			0.132381,
			0.022258,
			0.56325,
			0.035294,
			0.140603,
			0.021687,
			0.566959,
			0.039216,
			0.148607,
			0.021154,
			0.570562,
			0.043137,
			0.156421,
			0.020651,
			0.574065,
			0.047059,
			0.16407,
			0.020171,
			0.577478,
			0.05098,
			0.171574,
			0.019706,
			0.580806,
			0.054902,
			0.17895,
			0.019252,
			0.584054,
			0.058824,
			0.186213,
			0.018803,
			0.587228,
			0.062745,
			0.193374,
			0.018354,
			0.59033,
			0.066667,
			0.200445,
			0.017902,
			0.593364,
			0.070588,
			0.207435,
			0.017442,
			0.596333,
			0.07451,
			0.21435,
			0.016973,
			0.599239,
			0.078431,
			0.221197,
			0.016497,
			0.602083,
			0.082353,
			0.227983,
			0.016007,
			0.604867,
			0.086275,
			0.234715,
			0.015502,
			0.607592,
			0.090196,
			0.241396,
			0.014979,
			0.610259,
			0.094118,
			0.248032,
			0.014439,
			0.612868,
			0.098039,
			0.254627,
			0.013882,
			0.615419,
			0.101961,
			0.261183,
			0.013308,
			0.617911,
			0.105882,
			0.267703,
			0.012716,
			0.620346,
			0.109804,
			0.274191,
			0.012109,
			0.622722,
			0.113725,
			0.280648,
			0.011488,
			0.625038,
			0.117647,
			0.287076,
			0.010855,
			0.627295,
			0.121569,
			0.293478,
			0.010213,
			0.62949,
			0.12549,
			0.299855,
			0.009561,
			0.631624,
			0.129412,
			0.30621,
			0.008902,
			0.633694,
			0.133333,
			0.312543,
			0.008239,
			0.6357,
			0.137255,
			0.318856,
			0.007576,
			0.63764,
			0.141176,
			0.32515,
			0.006915,
			0.639512,
			0.145098,
			0.331426,
			0.006261,
			0.641316,
			0.14902,
			0.337683,
			0.005618,
			0.643049,
			0.152941,
			0.343925,
			0.004991,
			0.64471,
			0.156863,
			0.35015,
			0.004382,
			0.646298,
			0.160784,
			0.356359,
			0.003798,
			0.64781,
			0.164706,
			0.362553,
			0.003243,
			0.649245,
			0.168627,
			0.368733,
			0.002724,
			0.650601,
			0.172549,
			0.374897,
			0.002245,
			0.651876,
			0.176471,
			0.381047,
			0.001814,
			0.653068,
			0.180392,
			0.387183,
			0.001434,
			0.654177,
			0.184314,
			0.393304,
			0.001114,
			0.655199,
			0.188235,
			0.399411,
			0.000859,
			0.656133,
			0.192157,
			0.405503,
			0.000678,
			0.656977,
			0.196078,
			0.41158,
			0.000577,
			0.65773,
			0.2,
			0.417642,
			0.000564,
			0.65839,
			0.203922,
			0.423689,
			0.000646,
			0.658956,
			0.207843,
			0.429719,
			0.000831,
			0.659425,
			0.211765,
			0.435734,
			0.001127,
			0.659797,
			0.215686,
			0.441732,
			0.00154,
			0.660069,
			0.219608,
			0.447714,
			0.00208,
			0.66024,
			0.223529,
			0.453677,
			0.002755,
			0.66031,
			0.227451,
			0.459623,
			0.003574,
			0.660277,
			0.231373,
			0.46555,
			0.004545,
			0.660139,
			0.235294,
			0.471457,
			0.005678,
			0.659897,
			0.239216,
			0.477344,
			0.00698,
			0.659549,
			0.243137,
			0.48321,
			0.00846,
			0.659095,
			0.247059,
			0.489055,
			0.010127,
			0.658534,
			0.25098,
			0.494877,
			0.01199,
			0.657865,
			0.254902,
			0.500678,
			0.014055,
			0.657088,
			0.258824,
			0.506454,
			0.016333,
			0.656202,
			0.262745,
			0.512206,
			0.018833,
			0.655209,
			0.266667,
			0.517933,
			0.021563,
			0.654109,
			0.270588,
			0.523633,
			0.024532,
			0.652901,
			0.27451,
			0.529306,
			0.027747,
			0.651586,
			0.278431,
			0.534952,
			0.031217,
			0.650165,
			0.282353,
			0.54057,
			0.03495,
			0.64864,
			0.286275,
			0.546157,
			0.038954,
			0.64701,
			0.290196,
			0.551715,
			0.043136,
			0.645277,
			0.294118,
			0.557243,
			0.047331,
			0.643443,
			0.298039,
			0.562738,
			0.051545,
			0.641509,
			0.301961,
			0.568201,
			0.055778,
			0.639477,
			0.305882,
			0.573632,
			0.060028,
			0.637349,
			0.309804,
			0.579029,
			0.064296,
			0.635126,
			0.313725,
			0.584391,
			0.068579,
			0.632812,
			0.317647,
			0.589719,
			0.072878,
			0.630408,
			0.321569,
			0.595011,
			0.07719,
			0.627917,
			0.32549,
			0.600266,
			0.081516,
			0.625342,
			0.329412,
			0.605485,
			0.085854,
			0.622686,
			0.333333,
			0.610667,
			0.090204,
			0.619951,
			0.337255,
			0.615812,
			0.094564,
			0.61714,
			0.341176,
			0.620919,
			0.098934,
			0.614257,
			0.345098,
			0.625987,
			0.103312,
			0.611305,
			0.34902,
			0.631017,
			0.107699,
			0.608287,
			0.352941,
			0.636008,
			0.112092,
			0.605205,
			0.356863,
			0.640959,
			0.116492,
			0.602065,
			0.360784,
			0.645872,
			0.120898,
			0.598867,
			0.364706,
			0.650746,
			0.125309,
			0.595617,
			0.368627,
			0.65558,
			0.129725,
			0.592317,
			0.372549,
			0.660374,
			0.134144,
			0.588971,
			0.376471,
			0.665129,
			0.138566,
			0.585582,
			0.380392,
			0.669845,
			0.142992,
			0.582154,
			0.384314,
			0.674522,
			0.147419,
			0.578688,
			0.388235,
			0.67916,
			0.151848,
			0.575189,
			0.392157,
			0.683758,
			0.156278,
			0.57166,
			0.396078,
			0.688318,
			0.160709,
			0.568103,
			0.4,
			0.69284,
			0.165141,
			0.564522,
			0.403922,
			0.697324,
			0.169573,
			0.560919,
			0.407843,
			0.701769,
			0.174005,
			0.557296,
			0.411765,
			0.706178,
			0.178437,
			0.553657,
			0.415686,
			0.710549,
			0.182868,
			0.550004,
			0.419608,
			0.714883,
			0.187299,
			0.546338,
			0.423529,
			0.719181,
			0.191729,
			0.542663,
			0.427451,
			0.723444,
			0.196158,
			0.538981,
			0.431373,
			0.72767,
			0.200586,
			0.535293,
			0.435294,
			0.731862,
			0.205013,
			0.531601,
			0.439216,
			0.736019,
			0.209439,
			0.527908,
			0.443137,
			0.740143,
			0.213864,
			0.524216,
			0.447059,
			0.744232,
			0.218288,
			0.520524,
			0.45098,
			0.748289,
			0.222711,
			0.516834,
			0.454902,
			0.752312,
			0.227133,
			0.513149,
			0.458824,
			0.756304,
			0.231555,
			0.509468,
			0.462745,
			0.760264,
			0.235976,
			0.505794,
			0.466667,
			0.764193,
			0.240396,
			0.502126,
			0.470588,
			0.76809,
			0.244817,
			0.498465,
			0.47451,
			0.771958,
			0.249237,
			0.494813,
			0.478431,
			0.775796,
			0.253658,
			0.491171,
			0.482353,
			0.779604,
			0.258078,
			0.487539,
			0.486275,
			0.783383,
			0.2625,
			0.483918,
			0.490196,
			0.787133,
			0.266922,
			0.480307,
			0.494118,
			0.790855,
			0.271345,
			0.476706,
			0.498039,
			0.794549,
			0.27577,
			0.473117,
			0.501961,
			0.798216,
			0.280197,
			0.469538,
			0.505882,
			0.801855,
			0.284626,
			0.465971,
			0.509804,
			0.805467,
			0.289057,
			0.462415,
			0.513725,
			0.809052,
			0.293491,
			0.45887,
			0.517647,
			0.812612,
			0.297928,
			0.455338,
			0.521569,
			0.816144,
			0.302368,
			0.451816,
			0.52549,
			0.819651,
			0.306812,
			0.448306,
			0.529412,
			0.823132,
			0.311261,
			0.444806,
			0.533333,
			0.826588,
			0.315714,
			0.441316,
			0.537255,
			0.830018,
			0.320172,
			0.437836,
			0.541176,
			0.833422,
			0.324635,
			0.434366,
			0.545098,
			0.836801,
			0.329105,
			0.430905,
			0.54902,
			0.840155,
			0.33358,
			0.427455,
			0.552941,
			0.843484,
			0.338062,
			0.424013,
			0.556863,
			0.846788,
			0.342551,
			0.420579,
			0.560784,
			0.850066,
			0.347048,
			0.417153,
			0.564706,
			0.853319,
			0.351553,
			0.413734,
			0.568627,
			0.856547,
			0.356066,
			0.410322,
			0.572549,
			0.85975,
			0.360588,
			0.406917,
			0.576471,
			0.862927,
			0.365119,
			0.403519,
			0.580392,
			0.866078,
			0.36966,
			0.400126,
			0.584314,
			0.869203,
			0.374212,
			0.396738,
			0.588235,
			0.872303,
			0.378774,
			0.393355,
			0.592157,
			0.875376,
			0.383347,
			0.389976,
			0.596078,
			0.878423,
			0.387932,
			0.3866,
			0.6,
			0.881443,
			0.392529,
			0.383229,
			0.603922,
			0.884436,
			0.397139,
			0.37986,
			0.607843,
			0.887402,
			0.401762,
			0.376494,
			0.611765,
			0.89034,
			0.406398,
			0.37313,
			0.615686,
			0.89325,
			0.411048,
			0.369768,
			0.619608,
			0.896131,
			0.415712,
			0.366407,
			0.623529,
			0.898984,
			0.420392,
			0.363047,
			0.627451,
			0.901807,
			0.425087,
			0.359688,
			0.631373,
			0.904601,
			0.429797,
			0.356329,
			0.635294,
			0.907365,
			0.434524,
			0.35297,
			0.639216,
			0.910098,
			0.439268,
			0.34961,
			0.643137,
			0.9128,
			0.444029,
			0.346251,
			0.647059,
			0.915471,
			0.448807,
			0.34289,
			0.65098,
			0.918109,
			0.453603,
			0.339529,
			0.654902,
			0.920714,
			0.458417,
			0.336166,
			0.658824,
			0.923287,
			0.463251,
			0.332801,
			0.662745,
			0.925825,
			0.468103,
			0.329435,
			0.666667,
			0.928329,
			0.472975,
			0.326067,
			0.670588,
			0.930798,
			0.477867,
			0.322697,
			0.67451,
			0.933232,
			0.48278,
			0.319325,
			0.678431,
			0.93563,
			0.487712,
			0.315952,
			0.682353,
			0.93799,
			0.492667,
			0.312575,
			0.686275,
			0.940313,
			0.497642,
			0.309197,
			0.690196,
			0.942598,
			0.502639,
			0.305816,
			0.694118,
			0.944844,
			0.507658,
			0.302433,
			0.698039,
			0.947051,
			0.512699,
			0.299049,
			0.701961,
			0.949217,
			0.517763,
			0.295662,
			0.705882,
			0.951344,
			0.52285,
			0.292275,
			0.709804,
			0.953428,
			0.52796,
			0.288883,
			0.713725,
			0.95547,
			0.533093,
			0.28549,
			0.717647,
			0.957469,
			0.53825,
			0.282096,
			0.721569,
			0.959424,
			0.543431,
			0.278701,
			0.72549,
			0.961336,
			0.548636,
			0.275305,
			0.729412,
			0.963203,
			0.553865,
			0.271909,
			0.733333,
			0.965024,
			0.559118,
			0.268513,
			0.737255,
			0.966798,
			0.564396,
			0.265118,
			0.741176,
			0.968526,
			0.5697,
			0.261721,
			0.745098,
			0.970205,
			0.575028,
			0.258325,
			0.74902,
			0.971835,
			0.580382,
			0.254931,
			0.752941,
			0.973416,
			0.585761,
			0.25154,
			0.756863,
			0.974947,
			0.591165,
			0.248151,
			0.760784,
			0.976428,
			0.596595,
			0.244767,
			0.764706,
			0.977856,
			0.602051,
			0.241387,
			0.768627,
			0.979233,
			0.607532,
			0.238013,
			0.772549,
			0.980556,
			0.613039,
			0.234646,
			0.776471,
			0.981826,
			0.618572,
			0.231287,
			0.780392,
			0.983041,
			0.624131,
			0.227937,
			0.784314,
			0.984199,
			0.629718,
			0.224595,
			0.788235,
			0.985301,
			0.63533,
			0.221265,
			0.792157,
			0.986345,
			0.640969,
			0.217948,
			0.796078,
			0.987332,
			0.646633,
			0.214648,
			0.8,
			0.98826,
			0.652325,
			0.211364,
			0.803922,
			0.989128,
			0.658043,
			0.2081,
			0.807843,
			0.989935,
			0.663787,
			0.204859,
			0.811765,
			0.990681,
			0.669558,
			0.201642,
			0.815686,
			0.991365,
			0.675355,
			0.198453,
			0.819608,
			0.991985,
			0.681179,
			0.195295,
			0.823529,
			0.992541,
			0.68703,
			0.19217,
			0.827451,
			0.993032,
			0.692907,
			0.189084,
			0.831373,
			0.993456,
			0.69881,
			0.186041,
			0.835294,
			0.993814,
			0.704741,
			0.183043,
			0.839216,
			0.994103,
			0.710698,
			0.180097,
			0.843137,
			0.994324,
			0.716681,
			0.177208,
			0.847059,
			0.994474,
			0.722691,
			0.174381,
			0.85098,
			0.994553,
			0.728728,
			0.171622,
			0.854902,
			0.994561,
			0.734791,
			0.168938,
			0.858824,
			0.994495,
			0.74088,
			0.166335,
			0.862745,
			0.994355,
			0.746995,
			0.163821,
			0.866667,
			0.994141,
			0.753137,
			0.161404,
			0.870588,
			0.993851,
			0.759304,
			0.159092,
			0.87451,
			0.993482,
			0.765499,
			0.156891,
			0.878431,
			0.993033,
			0.77172,
			0.154808,
			0.882353,
			0.992505,
			0.777967,
			0.152855,
			0.886275,
			0.991897,
			0.784239,
			0.151042,
			0.890196,
			0.991209,
			0.790537,
			0.149377,
			0.894118,
			0.990439,
			0.796859,
			0.14787,
			0.898039,
			0.989587,
			0.803205,
			0.146529,
			0.901961,
			0.988648,
			0.809579,
			0.145357,
			0.905882,
			0.987621,
			0.815978,
			0.144363,
			0.909804,
			0.986509,
			0.822401,
			0.143557,
			0.913725,
			0.985314,
			0.828846,
			0.142945,
			0.917647,
			0.984031,
			0.835315,
			0.142528,
			0.921569,
			0.982653,
			0.841812,
			0.142303,
			0.92549,
			0.98119,
			0.848329,
			0.142279,
			0.929412,
			0.979644,
			0.854866,
			0.142453,
			0.933333,
			0.977995,
			0.861432,
			0.142808,
			0.937255,
			0.976265,
			0.868016,
			0.143351,
			0.941176,
			0.974443,
			0.874622,
			0.144061,
			0.945098,
			0.97253,
			0.88125,
			0.144923,
			0.94902,
			0.970533,
			0.887896,
			0.145919,
			0.952941,
			0.968443,
			0.894564,
			0.147014,
			0.956863,
			0.966271,
			0.901249,
			0.14818,
			0.960784,
			0.964021,
			0.90795,
			0.14937,
			0.964706,
			0.961681,
			0.914672,
			0.15052,
			0.968627,
			0.959276,
			0.921407,
			0.151566,
			0.972549,
			0.956808,
			0.928152,
			0.152409,
			0.976471,
			0.954287,
			0.934908,
			0.152921,
			0.980392,
			0.951726,
			0.941671,
			0.152925,
			0.984314,
			0.949151,
			0.948435,
			0.152178,
			0.988235,
			0.946602,
			0.95519,
			0.150328,
			0.992157,
			0.944152,
			0.961916,
			0.146861,
			0.996078,
			0.941896,
			0.96859,
			0.140956,
			1,
			0.940015,
			0.975158,
			0.131326
		]
	},
	{
		ColorSpace: "Diverging",
		Name: "Viridis (matplotlib)",
		NanColor: [
			1,
			0,
			0
		],
		Source: "https://github.com/BIDS/colormap/blob/master/colormaps.py",
		License: "CC0",
		Creator: "Eric Firing",
		RGBPoints: [
			0,
			0.267004,
			0.004874,
			0.329415,
			0.003922,
			0.26851,
			0.009605,
			0.335427,
			0.007843,
			0.269944,
			0.014625,
			0.341379,
			0.011765,
			0.271305,
			0.019942,
			0.347269,
			0.015686,
			0.272594,
			0.025563,
			0.353093,
			0.019608,
			0.273809,
			0.031497,
			0.358853,
			0.023529,
			0.274952,
			0.037752,
			0.364543,
			0.027451,
			0.276022,
			0.044167,
			0.370164,
			0.031373,
			0.277018,
			0.050344,
			0.375715,
			0.035294,
			0.277941,
			0.056324,
			0.381191,
			0.039216,
			0.278791,
			0.062145,
			0.386592,
			0.043137,
			0.279566,
			0.067836,
			0.391917,
			0.047059,
			0.280267,
			0.073417,
			0.397163,
			0.05098,
			0.280894,
			0.078907,
			0.402329,
			0.054902,
			0.281446,
			0.08432,
			0.407414,
			0.058824,
			0.281924,
			0.089666,
			0.412415,
			0.062745,
			0.282327,
			0.094955,
			0.417331,
			0.066667,
			0.282656,
			0.100196,
			0.42216,
			0.070588,
			0.28291,
			0.105393,
			0.426902,
			0.07451,
			0.283091,
			0.110553,
			0.431554,
			0.078431,
			0.283197,
			0.11568,
			0.436115,
			0.082353,
			0.283229,
			0.120777,
			0.440584,
			0.086275,
			0.283187,
			0.125848,
			0.44496,
			0.090196,
			0.283072,
			0.130895,
			0.449241,
			0.094118,
			0.282884,
			0.13592,
			0.453427,
			0.098039,
			0.282623,
			0.140926,
			0.457517,
			0.101961,
			0.28229,
			0.145912,
			0.46151,
			0.105882,
			0.281887,
			0.150881,
			0.465405,
			0.109804,
			0.281412,
			0.155834,
			0.469201,
			0.113725,
			0.280868,
			0.160771,
			0.472899,
			0.117647,
			0.280255,
			0.165693,
			0.476498,
			0.121569,
			0.279574,
			0.170599,
			0.479997,
			0.12549,
			0.278826,
			0.17549,
			0.483397,
			0.129412,
			0.278012,
			0.180367,
			0.486697,
			0.133333,
			0.277134,
			0.185228,
			0.489898,
			0.137255,
			0.276194,
			0.190074,
			0.493001,
			0.141176,
			0.275191,
			0.194905,
			0.496005,
			0.145098,
			0.274128,
			0.199721,
			0.498911,
			0.14902,
			0.273006,
			0.20452,
			0.501721,
			0.152941,
			0.271828,
			0.209303,
			0.504434,
			0.156863,
			0.270595,
			0.214069,
			0.507052,
			0.160784,
			0.269308,
			0.218818,
			0.509577,
			0.164706,
			0.267968,
			0.223549,
			0.512008,
			0.168627,
			0.26658,
			0.228262,
			0.514349,
			0.172549,
			0.265145,
			0.232956,
			0.516599,
			0.176471,
			0.263663,
			0.237631,
			0.518762,
			0.180392,
			0.262138,
			0.242286,
			0.520837,
			0.184314,
			0.260571,
			0.246922,
			0.522828,
			0.188235,
			0.258965,
			0.251537,
			0.524736,
			0.192157,
			0.257322,
			0.25613,
			0.526563,
			0.196078,
			0.255645,
			0.260703,
			0.528312,
			0.2,
			0.253935,
			0.265254,
			0.529983,
			0.203922,
			0.252194,
			0.269783,
			0.531579,
			0.207843,
			0.250425,
			0.27429,
			0.533103,
			0.211765,
			0.248629,
			0.278775,
			0.534556,
			0.215686,
			0.246811,
			0.283237,
			0.535941,
			0.219608,
			0.244972,
			0.287675,
			0.53726,
			0.223529,
			0.243113,
			0.292092,
			0.538516,
			0.227451,
			0.241237,
			0.296485,
			0.539709,
			0.231373,
			0.239346,
			0.300855,
			0.540844,
			0.235294,
			0.237441,
			0.305202,
			0.541921,
			0.239216,
			0.235526,
			0.309527,
			0.542944,
			0.243137,
			0.233603,
			0.313828,
			0.543914,
			0.247059,
			0.231674,
			0.318106,
			0.544834,
			0.25098,
			0.229739,
			0.322361,
			0.545706,
			0.254902,
			0.227802,
			0.326594,
			0.546532,
			0.258824,
			0.225863,
			0.330805,
			0.547314,
			0.262745,
			0.223925,
			0.334994,
			0.548053,
			0.266667,
			0.221989,
			0.339161,
			0.548752,
			0.270588,
			0.220057,
			0.343307,
			0.549413,
			0.27451,
			0.21813,
			0.347432,
			0.550038,
			0.278431,
			0.21621,
			0.351535,
			0.550627,
			0.282353,
			0.214298,
			0.355619,
			0.551184,
			0.286275,
			0.212395,
			0.359683,
			0.55171,
			0.290196,
			0.210503,
			0.363727,
			0.552206,
			0.294118,
			0.208623,
			0.367752,
			0.552675,
			0.298039,
			0.206756,
			0.371758,
			0.553117,
			0.301961,
			0.204903,
			0.375746,
			0.553533,
			0.305882,
			0.203063,
			0.379716,
			0.553925,
			0.309804,
			0.201239,
			0.38367,
			0.554294,
			0.313725,
			0.19943,
			0.387607,
			0.554642,
			0.317647,
			0.197636,
			0.391528,
			0.554969,
			0.321569,
			0.19586,
			0.395433,
			0.555276,
			0.32549,
			0.1941,
			0.399323,
			0.555565,
			0.329412,
			0.192357,
			0.403199,
			0.555836,
			0.333333,
			0.190631,
			0.407061,
			0.556089,
			0.337255,
			0.188923,
			0.41091,
			0.556326,
			0.341176,
			0.187231,
			0.414746,
			0.556547,
			0.345098,
			0.185556,
			0.41857,
			0.556753,
			0.34902,
			0.183898,
			0.422383,
			0.556944,
			0.352941,
			0.182256,
			0.426184,
			0.55712,
			0.356863,
			0.180629,
			0.429975,
			0.557282,
			0.360784,
			0.179019,
			0.433756,
			0.55743,
			0.364706,
			0.177423,
			0.437527,
			0.557565,
			0.368627,
			0.175841,
			0.44129,
			0.557685,
			0.372549,
			0.174274,
			0.445044,
			0.557792,
			0.376471,
			0.172719,
			0.448791,
			0.557885,
			0.380392,
			0.171176,
			0.45253,
			0.557965,
			0.384314,
			0.169646,
			0.456262,
			0.55803,
			0.388235,
			0.168126,
			0.459988,
			0.558082,
			0.392157,
			0.166617,
			0.463708,
			0.558119,
			0.396078,
			0.165117,
			0.467423,
			0.558141,
			0.4,
			0.163625,
			0.471133,
			0.558148,
			0.403922,
			0.162142,
			0.474838,
			0.55814,
			0.407843,
			0.160665,
			0.47854,
			0.558115,
			0.411765,
			0.159194,
			0.482237,
			0.558073,
			0.415686,
			0.157729,
			0.485932,
			0.558013,
			0.419608,
			0.15627,
			0.489624,
			0.557936,
			0.423529,
			0.154815,
			0.493313,
			0.55784,
			0.427451,
			0.153364,
			0.497,
			0.557724,
			0.431373,
			0.151918,
			0.500685,
			0.557587,
			0.435294,
			0.150476,
			0.504369,
			0.55743,
			0.439216,
			0.149039,
			0.508051,
			0.55725,
			0.443137,
			0.147607,
			0.511733,
			0.557049,
			0.447059,
			0.14618,
			0.515413,
			0.556823,
			0.45098,
			0.144759,
			0.519093,
			0.556572,
			0.454902,
			0.143343,
			0.522773,
			0.556295,
			0.458824,
			0.141935,
			0.526453,
			0.555991,
			0.462745,
			0.140536,
			0.530132,
			0.555659,
			0.466667,
			0.139147,
			0.533812,
			0.555298,
			0.470588,
			0.13777,
			0.537492,
			0.554906,
			0.47451,
			0.136408,
			0.541173,
			0.554483,
			0.478431,
			0.135066,
			0.544853,
			0.554029,
			0.482353,
			0.133743,
			0.548535,
			0.553541,
			0.486275,
			0.132444,
			0.552216,
			0.553018,
			0.490196,
			0.131172,
			0.555899,
			0.552459,
			0.494118,
			0.129933,
			0.559582,
			0.551864,
			0.498039,
			0.128729,
			0.563265,
			0.551229,
			0.501961,
			0.127568,
			0.566949,
			0.550556,
			0.505882,
			0.126453,
			0.570633,
			0.549841,
			0.509804,
			0.125394,
			0.574318,
			0.549086,
			0.513725,
			0.124395,
			0.578002,
			0.548287,
			0.517647,
			0.123463,
			0.581687,
			0.547445,
			0.521569,
			0.122606,
			0.585371,
			0.546557,
			0.52549,
			0.121831,
			0.589055,
			0.545623,
			0.529412,
			0.121148,
			0.592739,
			0.544641,
			0.533333,
			0.120565,
			0.596422,
			0.543611,
			0.537255,
			0.120092,
			0.600104,
			0.54253,
			0.541176,
			0.119738,
			0.603785,
			0.5414,
			0.545098,
			0.119512,
			0.607464,
			0.540218,
			0.54902,
			0.119423,
			0.611141,
			0.538982,
			0.552941,
			0.119483,
			0.614817,
			0.537692,
			0.556863,
			0.119699,
			0.61849,
			0.536347,
			0.560784,
			0.120081,
			0.622161,
			0.534946,
			0.564706,
			0.120638,
			0.625828,
			0.533488,
			0.568627,
			0.12138,
			0.629492,
			0.531973,
			0.572549,
			0.122312,
			0.633153,
			0.530398,
			0.576471,
			0.123444,
			0.636809,
			0.528763,
			0.580392,
			0.12478,
			0.640461,
			0.527068,
			0.584314,
			0.126326,
			0.644107,
			0.525311,
			0.588235,
			0.128087,
			0.647749,
			0.523491,
			0.592157,
			0.130067,
			0.651384,
			0.521608,
			0.596078,
			0.132268,
			0.655014,
			0.519661,
			0.6,
			0.134692,
			0.658636,
			0.517649,
			0.603922,
			0.137339,
			0.662252,
			0.515571,
			0.607843,
			0.14021,
			0.665859,
			0.513427,
			0.611765,
			0.143303,
			0.669459,
			0.511215,
			0.615686,
			0.146616,
			0.67305,
			0.508936,
			0.619608,
			0.150148,
			0.676631,
			0.506589,
			0.623529,
			0.153894,
			0.680203,
			0.504172,
			0.627451,
			0.157851,
			0.683765,
			0.501686,
			0.631373,
			0.162016,
			0.687316,
			0.499129,
			0.635294,
			0.166383,
			0.690856,
			0.496502,
			0.639216,
			0.170948,
			0.694384,
			0.493803,
			0.643137,
			0.175707,
			0.6979,
			0.491033,
			0.647059,
			0.180653,
			0.701402,
			0.488189,
			0.65098,
			0.185783,
			0.704891,
			0.485273,
			0.654902,
			0.19109,
			0.708366,
			0.482284,
			0.658824,
			0.196571,
			0.711827,
			0.479221,
			0.662745,
			0.202219,
			0.715272,
			0.476084,
			0.666667,
			0.20803,
			0.718701,
			0.472873,
			0.670588,
			0.214,
			0.722114,
			0.469588,
			0.67451,
			0.220124,
			0.725509,
			0.466226,
			0.678431,
			0.226397,
			0.728888,
			0.462789,
			0.682353,
			0.232815,
			0.732247,
			0.459277,
			0.686275,
			0.239374,
			0.735588,
			0.455688,
			0.690196,
			0.24607,
			0.73891,
			0.452024,
			0.694118,
			0.252899,
			0.742211,
			0.448284,
			0.698039,
			0.259857,
			0.745492,
			0.444467,
			0.701961,
			0.266941,
			0.748751,
			0.440573,
			0.705882,
			0.274149,
			0.751988,
			0.436601,
			0.709804,
			0.281477,
			0.755203,
			0.432552,
			0.713725,
			0.288921,
			0.758394,
			0.428426,
			0.717647,
			0.296479,
			0.761561,
			0.424223,
			0.721569,
			0.304148,
			0.764704,
			0.419943,
			0.72549,
			0.311925,
			0.767822,
			0.415586,
			0.729412,
			0.319809,
			0.770914,
			0.411152,
			0.733333,
			0.327796,
			0.77398,
			0.40664,
			0.737255,
			0.335885,
			0.777018,
			0.402049,
			0.741176,
			0.344074,
			0.780029,
			0.397381,
			0.745098,
			0.35236,
			0.783011,
			0.392636,
			0.74902,
			0.360741,
			0.785964,
			0.387814,
			0.752941,
			0.369214,
			0.788888,
			0.382914,
			0.756863,
			0.377779,
			0.791781,
			0.377939,
			0.760784,
			0.386433,
			0.794644,
			0.372886,
			0.764706,
			0.395174,
			0.797475,
			0.367757,
			0.768627,
			0.404001,
			0.800275,
			0.362552,
			0.772549,
			0.412913,
			0.803041,
			0.357269,
			0.776471,
			0.421908,
			0.805774,
			0.35191,
			0.780392,
			0.430983,
			0.808473,
			0.346476,
			0.784314,
			0.440137,
			0.811138,
			0.340967,
			0.788235,
			0.449368,
			0.813768,
			0.335384,
			0.792157,
			0.458674,
			0.816363,
			0.329727,
			0.796078,
			0.468053,
			0.818921,
			0.323998,
			0.8,
			0.477504,
			0.821444,
			0.318195,
			0.803922,
			0.487026,
			0.823929,
			0.312321,
			0.807843,
			0.496615,
			0.826376,
			0.306377,
			0.811765,
			0.506271,
			0.828786,
			0.300362,
			0.815686,
			0.515992,
			0.831158,
			0.294279,
			0.819608,
			0.525776,
			0.833491,
			0.288127,
			0.823529,
			0.535621,
			0.835785,
			0.281908,
			0.827451,
			0.545524,
			0.838039,
			0.275626,
			0.831373,
			0.555484,
			0.840254,
			0.269281,
			0.835294,
			0.565498,
			0.84243,
			0.262877,
			0.839216,
			0.575563,
			0.844566,
			0.256415,
			0.843137,
			0.585678,
			0.846661,
			0.249897,
			0.847059,
			0.595839,
			0.848717,
			0.243329,
			0.85098,
			0.606045,
			0.850733,
			0.236712,
			0.854902,
			0.616293,
			0.852709,
			0.230052,
			0.858824,
			0.626579,
			0.854645,
			0.223353,
			0.862745,
			0.636902,
			0.856542,
			0.21662,
			0.866667,
			0.647257,
			0.8584,
			0.209861,
			0.870588,
			0.657642,
			0.860219,
			0.203082,
			0.87451,
			0.668054,
			0.861999,
			0.196293,
			0.878431,
			0.678489,
			0.863742,
			0.189503,
			0.882353,
			0.688944,
			0.865448,
			0.182725,
			0.886275,
			0.699415,
			0.867117,
			0.175971,
			0.890196,
			0.709898,
			0.868751,
			0.169257,
			0.894118,
			0.720391,
			0.87035,
			0.162603,
			0.898039,
			0.730889,
			0.871916,
			0.156029,
			0.901961,
			0.741388,
			0.873449,
			0.149561,
			0.905882,
			0.751884,
			0.874951,
			0.143228,
			0.909804,
			0.762373,
			0.876424,
			0.137064,
			0.913725,
			0.772852,
			0.877868,
			0.131109,
			0.917647,
			0.783315,
			0.879285,
			0.125405,
			0.921569,
			0.79376,
			0.880678,
			0.120005,
			0.92549,
			0.804182,
			0.882046,
			0.114965,
			0.929412,
			0.814576,
			0.883393,
			0.110347,
			0.933333,
			0.82494,
			0.88472,
			0.106217,
			0.937255,
			0.83527,
			0.886029,
			0.102646,
			0.941176,
			0.845561,
			0.887322,
			0.099702,
			0.945098,
			0.85581,
			0.888601,
			0.097452,
			0.94902,
			0.866013,
			0.889868,
			0.095953,
			0.952941,
			0.876168,
			0.891125,
			0.09525,
			0.956863,
			0.886271,
			0.892374,
			0.095374,
			0.960784,
			0.89632,
			0.893616,
			0.096335,
			0.964706,
			0.906311,
			0.894855,
			0.098125,
			0.968627,
			0.916242,
			0.896091,
			0.100717,
			0.972549,
			0.926106,
			0.89733,
			0.104071,
			0.976471,
			0.935904,
			0.89857,
			0.108131,
			0.980392,
			0.945636,
			0.899815,
			0.112838,
			0.984314,
			0.9553,
			0.901065,
			0.118128,
			0.988235,
			0.964894,
			0.902323,
			0.123941,
			0.992157,
			0.974417,
			0.90359,
			0.130215,
			0.996078,
			0.983868,
			0.904867,
			0.136897,
			1,
			0.993248,
			0.906157,
			0.143936
		]
	},
	{
		ShowIndexedColorActiveValues: 1,
		IndexedColors: [
			0.07,
			0.5,
			0.7,
			1,
			1,
			1,
			0.85,
			1,
			1,
			0.8,
			0.5,
			1,
			0.76,
			1,
			0,
			1,
			0.71,
			0.71,
			0.5,
			0.5,
			0.5,
			0.05,
			0.05,
			1,
			1,
			0.05,
			0.05,
			0.7,
			1,
			1,
			0.7,
			0.89,
			0.96,
			0.67,
			0.36,
			0.95,
			0.54,
			1,
			0,
			0.75,
			0.65,
			0.65,
			0.5,
			0.6,
			0.6,
			1,
			0.5,
			0,
			1,
			1,
			0.19,
			0.12,
			0.94,
			0.12,
			0.5,
			0.82,
			0.89,
			0.56,
			0.25,
			0.83,
			0.24,
			1,
			0,
			0.9,
			0.9,
			0.9,
			0.75,
			0.76,
			0.78,
			0.65,
			0.65,
			0.67,
			0.54,
			0.6,
			0.78,
			0.61,
			0.48,
			0.78,
			0.5,
			0.48,
			0.78,
			0.44,
			0.48,
			0.78,
			0.36,
			0.48,
			0.76,
			1,
			0.48,
			0.38,
			0.49,
			0.5,
			0.69,
			0.76,
			0.56,
			0.56,
			0.4,
			0.56,
			0.56,
			0.74,
			0.5,
			0.89,
			1,
			0.63,
			0,
			0.65,
			0.16,
			0.16,
			0.36,
			0.72,
			0.82,
			0.44,
			0.18,
			0.69,
			0,
			1,
			0,
			0.58,
			1,
			1,
			0.58,
			0.88,
			0.88,
			0.45,
			0.76,
			0.79,
			0.33,
			0.71,
			0.71,
			0.23,
			0.62,
			0.62,
			0.14,
			0.56,
			0.56,
			0.04,
			0.49,
			0.55,
			0,
			0.41,
			0.52,
			0.88,
			0.88,
			1,
			1,
			0.85,
			0.56,
			0.65,
			0.46,
			0.45,
			0.4,
			0.5,
			0.5,
			0.62,
			0.39,
			0.71,
			0.83,
			0.48,
			0,
			0.58,
			0,
			0.58,
			0.26,
			0.62,
			0.69,
			0.34,
			0.09,
			0.56,
			0,
			0.79,
			0,
			0.44,
			0.83,
			1,
			1,
			1,
			0.78,
			0.85,
			1,
			0.78,
			0.78,
			1,
			0.78,
			0.64,
			1,
			0.78,
			0.56,
			1,
			0.78,
			0.38,
			1,
			0.78,
			0.27,
			1,
			0.78,
			0.19,
			1,
			0.78,
			0.12,
			1,
			0.78,
			0,
			1,
			0.61,
			0,
			0.9,
			0.46,
			0,
			0.83,
			0.32,
			0,
			0.75,
			0.22,
			0,
			0.67,
			0.14,
			0.3,
			0.76,
			1,
			0.3,
			0.65,
			1,
			0.13,
			0.58,
			0.84,
			0.15,
			0.49,
			0.67,
			0.15,
			0.4,
			0.59,
			0.09,
			0.33,
			0.53,
			0.96,
			0.93,
			0.82,
			0.8,
			0.82,
			0.12,
			0.71,
			0.71,
			0.76,
			0.65,
			0.33,
			0.3,
			0.34,
			0.35,
			0.38,
			0.62,
			0.31,
			0.71,
			0.67,
			0.36,
			0,
			0.46,
			0.31,
			0.27,
			0.26,
			0.51,
			0.59,
			0.26,
			0,
			0.4,
			0,
			0.49,
			0,
			0.44,
			0.67,
			0.98,
			0,
			0.73,
			1,
			0,
			0.63,
			1,
			0,
			0.56,
			1,
			0,
			0.5,
			1,
			0,
			0.42,
			1,
			0.33,
			0.36,
			0.95,
			0.47,
			0.36,
			0.89,
			0.54,
			0.31,
			0.89,
			0.63,
			0.21,
			0.83,
			0.7,
			0.12,
			0.83,
			0.7,
			0.12,
			0.73,
			0.7,
			0.05,
			0.65,
			0.74,
			0.05,
			0.53,
			0.78,
			0,
			0.4,
			0.8,
			0,
			0.35,
			0.82,
			0,
			0.31,
			0.85,
			0,
			0.27,
			0.88,
			0,
			0.22,
			0.9,
			0,
			0.18,
			0.91,
			0,
			0.15,
			0.92,
			0,
			0.14,
			0.93,
			0,
			0.13,
			0.94,
			0,
			0.12,
			0.95,
			0,
			0.11,
			0.96,
			0,
			0.1,
			0.97,
			0,
			0.09,
			0.98,
			0,
			0.08,
			0.99,
			0,
			0.07,
			1,
			0,
			0.06
		],
		Annotations: [
			0,
			"Xx",
			1,
			"H",
			2,
			"He",
			3,
			"Li",
			4,
			"Be",
			5,
			"B",
			6,
			"C",
			7,
			"N",
			8,
			"O",
			9,
			"F",
			10,
			"Ne",
			11,
			"Na",
			12,
			"Mg",
			13,
			"Al",
			14,
			"Si",
			15,
			"P",
			16,
			"S",
			17,
			"Cl",
			18,
			"Ar",
			19,
			"K",
			20,
			"Ca",
			21,
			"Sc",
			22,
			"Ti",
			23,
			"V",
			24,
			"Cr",
			25,
			"Mn",
			26,
			"Fe",
			27,
			"Co",
			28,
			"Ni",
			29,
			"Cu",
			30,
			"Zn",
			31,
			"Ga",
			32,
			"Ge",
			33,
			"As",
			34,
			"Se",
			35,
			"Br",
			36,
			"Kr",
			37,
			"Rb",
			38,
			"Sr",
			39,
			"Y",
			40,
			"Zr",
			41,
			"Nb",
			42,
			"Mo",
			43,
			"Tc",
			44,
			"Ru",
			45,
			"Rh",
			46,
			"Pd",
			47,
			"Ag",
			48,
			"Cd",
			49,
			"In",
			50,
			"Sn",
			51,
			"Sb",
			52,
			"Te",
			53,
			"I",
			54,
			"Xe",
			55,
			"Cs",
			56,
			"Ba",
			57,
			"La",
			58,
			"Ce",
			59,
			"Pr",
			60,
			"Nd",
			61,
			"Pm",
			62,
			"Sm",
			63,
			"Eu",
			64,
			"Gd",
			65,
			"Tb",
			66,
			"Dy",
			67,
			"Ho",
			68,
			"Er",
			69,
			"Tm",
			70,
			"Yb",
			71,
			"Lu",
			72,
			"Hf",
			73,
			"Ta",
			74,
			"W",
			75,
			"Re",
			76,
			"Os",
			77,
			"Ir",
			78,
			"Pt",
			79,
			"Au",
			80,
			"Hg",
			81,
			"Tl",
			82,
			"Pb",
			83,
			"Bi",
			84,
			"Po",
			85,
			"At",
			86,
			"Rn",
			87,
			"Fr",
			88,
			"Ra",
			89,
			"Ac",
			90,
			"Th",
			91,
			"Pa",
			92,
			"U",
			93,
			"Np",
			94,
			"Pu",
			95,
			"Am",
			96,
			"Cm",
			97,
			"Bk",
			98,
			"Cf",
			99,
			"Es",
			100,
			"Fm",
			101,
			"Md",
			102,
			"No",
			103,
			"Lr",
			104,
			"Rf",
			105,
			"Db",
			106,
			"Sg",
			107,
			"Bh",
			108,
			"Hs",
			109,
			"Mt",
			110,
			"Ds",
			111,
			"Rg",
			112,
			"Cn",
			113,
			"Uut",
			114,
			"Uuq",
			115,
			"Uup",
			116,
			"Uuh",
			117,
			"Uus",
			118,
			"Uuo"
		],
		Name: "BlueObeliskElements"
	}
];

export { vtkColorMaps as v };
