import { m as macro } from '../../macros2.js';
import PropertyConst from './Property/Constants.js';

const {
  Representation,
  Interpolation
} = PropertyConst;
function notImplemented(method) {
  return () => macro.vtkErrorMacro(`vtkProperty::${method} - NOT IMPLEMENTED`);
}

// ----------------------------------------------------------------------------
// vtkProperty methods
// ----------------------------------------------------------------------------

function vtkProperty(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkProperty');
  publicAPI.setColor = (r, g, b) => {
    if (Array.isArray(r)) {
      if (model.color[0] !== r[0] || model.color[1] !== r[1] || model.color[2] !== r[2]) {
        model.color[0] = r[0];
        model.color[1] = r[1];
        model.color[2] = r[2];
        publicAPI.modified();
      }
    } else if (model.color[0] !== r || model.color[1] !== g || model.color[2] !== b) {
      model.color[0] = r;
      model.color[1] = g;
      model.color[2] = b;
      publicAPI.modified();
    }
    publicAPI.setDiffuseColor(model.color);
    publicAPI.setAmbientColor(model.color);
    publicAPI.setSpecularColor(model.color);
  };
  publicAPI.computeCompositeColor = notImplemented('ComputeCompositeColor');
  publicAPI.getColor = () => {
    // Inline computeCompositeColor
    let norm = 0.0;
    if (model.ambient + model.diffuse + model.specular > 0) {
      norm = 1.0 / (model.ambient + model.diffuse + model.specular);
    }
    for (let i = 0; i < 3; i++) {
      model.color[i] = norm * (model.ambient * model.ambientColor[i] + model.diffuse * model.diffuseColor[i] + model.specular * model.specularColor[i]);
    }
    return [].concat(model.color);
  };
  publicAPI.setSpecularPower = specularPower => {
    const roughness = 1 / Math.max(1.0, specularPower);
    if (model.roughness !== roughness || model.specularPower !== specularPower) {
      model.specularPower = specularPower; // Specular power still needs to be set as long as webgl is using it (otherwise testShaderReplacementsClear fails)
      model.roughness = roughness;
      publicAPI.modified();
    }
  };
  publicAPI.addShaderVariable = notImplemented('AddShaderVariable');
  publicAPI.setInterpolationToFlat = () => publicAPI.setInterpolation(Interpolation.FLAT);
  publicAPI.setInterpolationToGouraud = () => publicAPI.setInterpolation(Interpolation.GOURAUD);
  publicAPI.setInterpolationToPhong = () => publicAPI.setInterpolation(Interpolation.PHONG);
  publicAPI.getInterpolationAsString = () => macro.enumToString(Interpolation, model.interpolation);
  publicAPI.setRepresentationToWireframe = () => publicAPI.setRepresentation(Representation.WIREFRAME);
  publicAPI.setRepresentationToSurface = () => publicAPI.setRepresentation(Representation.SURFACE);
  publicAPI.setRepresentationToPoints = () => publicAPI.setRepresentation(Representation.POINTS);
  publicAPI.getRepresentationAsString = () => macro.enumToString(Representation, model.representation);
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  color: [1, 1, 1],
  ambientColor: [1, 1, 1],
  diffuseColor: [1, 1, 1],
  specularColor: [1, 1, 1],
  edgeColor: [0, 0, 0],
  ambient: 0,
  diffuse: 1,
  metallic: 0,
  roughness: 0.6,
  normalStrength: 1,
  emission: 1,
  baseIOR: 1.45,
  specular: 0,
  specularPower: 1,
  opacity: 1,
  interpolation: Interpolation.GOURAUD,
  representation: Representation.SURFACE,
  edgeVisibility: false,
  backfaceCulling: false,
  frontfaceCulling: false,
  pointSize: 1,
  lineWidth: 1,
  lighting: true,
  shading: false,
  materialName: null
};

// ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  let initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Build VTK API
  macro.obj(publicAPI, model);
  macro.setGet(publicAPI, model, ['lighting', 'interpolation', 'ambient', 'diffuse', 'metallic', 'roughness', 'normalStrength', 'emission', 'baseIOR', 'specular', 'specularPower', 'opacity', 'edgeVisibility', 'lineWidth', 'pointSize', 'backfaceCulling', 'frontfaceCulling', 'representation', 'diffuseTexture', 'metallicTexture', 'roughnessTexture', 'normalTexture', 'ambientOcclusionTexture', 'emissionTexture']);
  macro.setGetArray(publicAPI, model, ['ambientColor', 'specularColor', 'diffuseColor', 'edgeColor'], 3);

  // Object methods
  vtkProperty(publicAPI, model);
}

// ----------------------------------------------------------------------------

const newInstance = macro.newInstance(extend, 'vtkProperty');

// ----------------------------------------------------------------------------

var vtkProperty$1 = {
  newInstance,
  extend,
  ...PropertyConst
};

export { vtkProperty$1 as default, extend, newInstance };
